<template>
  <v-container fluid>
    <v-row v-if="$store.state.overviewMode">
      <v-badge class="pl-2 pt-2" color="green" :content="$store.getters.getNumberOfUnreadChats" :value="$store.getters.getNumberOfUnreadChats" offset-x="50" offset-y="45">
        <v-img :src="require('@/assets/chat/chat.png')" width="45"></v-img>
      </v-badge>
    </v-row>

    <v-row v-if="$store.state.overviewMode">
      <v-sheet :style="{ backgroundColor: theme.chat_bgGray }" class="overflow-hidden" max-height="500" width="100%">
        <ChatCard v-for="(chat, index) in getMessages" :key="index + chat.id" :chat="chat" />
      </v-sheet>
    </v-row>
    <v-row v-else>
      <v-sheet :style="{ height: 'calc(100vh - 300px)', backgroundColor: theme.chat_bgGray }" class="overflow-x-hidden overflow-y-auto ma-2" width="100%">
        <ChatCard v-for="(chat, index) in getMessages" :key="index + chat.id" :chat="chat" />
      </v-sheet>
    </v-row>

    <v-footer v-if="!$store.state.overviewMode" class="ma-2 px-2" color="#e5e5e5" fixed>
      <ChatMessage class="" />
    </v-footer>
  </v-container>
</template>

<script>
import Chat from "@/services/Chat.js";
import ChatMessage from "@/components/chat/ChatMessage.vue";
import ChatCard from "@/components/chat/ChatCard.vue";

export default {
  name: "Chat",

  components: {
    ChatMessage,
    ChatCard,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
    };
  },

  computed: {
    getMessages: {
      get() {
        if (!this.$store.state.chat.erviewMode) Chat.hasReadMessage();
        if (this.$store.state.farmData.data.messages) return this.$store.state.farmData.data.messages.list;
        return [];
      },
    },
  },
};
</script>

<style scoped>
.v-sheet {
  word-break: break-word;
}
</style>