<template>
  <v-form :style="{ background: '#ffffff' }" ref="form" v-model="valid" lazy-validation>
    <v-container class="ma-0 pa-5" fluid>
      <v-row class="mb-5">
        <p class="headline">{{ $t("Forgot password. Enter your e-mail address") }}</p>
      </v-row>
      <v-row>
        <v-text-field :rules="emailRules" v-model="email" dense outlined clearable />
      </v-row>
      <v-row>
        <v-col class="ma-0 pa-0 pr-1" cols="6">
          <v-btn block @click="close()">{{ $t("Close") }}</v-btn>
        </v-col>
        <v-col class="ma-0 pa-0 pl-1" cols="6">
          <v-btn block :disabled="!valid" @click="resetPassword()">{{ $t("Ok") }}</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showSentEmailInfo" persistent max-width="60%">
      <v-card>
        <v-card-title>
          <p class="ma-0 mp-0">{{ $t("Forgot password") }}</p>
        </v-card-title>
        <!-- TODO: The reset url in the mail needs some work, does not work as it is -->
        <v-card-text>{{ $t("An email has been set to to ") }}{{ email }}{{ $t(" if it exists. Please press the link in the email to reset your password.") }} </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn block @click="close()">{{ $t("Ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
const Axios = require("axios");
Axios.defaults.withCredentials = true;

export default {
  name: "RequestPwd",

  data() {
    return {
      valid: false,
      email: "",
      emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      showSentEmailInfo: false,
    };
  },

  mounted() {
    this.$refs.form.validate();
  },

  methods: {
    close() {
      console.log("close: call");
      this.showSentEmailInfo = false;
      this.$emit("closed");
    },

    resetPassword() {
      console.log("resetPassword: call");
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvUser/resetPassword";
      Axios.post(url, '"' + this.email + '"')
        .then((res) => {
          if (res.data === 1) console.log("resetPassword: success", res);
          else console.warn("resetPassword: failed", res);
        })
        .catch((err) => console.error(err));
      this.showSentEmailInfo = true;
    },
  },
};
</script>

<style scoped>
.headline {
  margin: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
</style>
