<template>
  <v-container class="card mb-2" :class="{ clickable: !getDismissed }" :style="{ background: getBackgroundColor }" fluid @click="showConfirmDialoge()">
    <v-row class="pa-1">
      <v-col cols="auto">
        <v-row>
          <img class="ma-2" :src="getImgSrc" width="50" />
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <p :style="{ color: getTextColor }" :class="{ clamp: $store.state.overviewMode }" class="device ma-0 mp-0">{{ device }}</p>
        </v-row>
        <v-row>
          <p :style="{ color: getTextColor }" class="date ma-0 mp-0">{{ date }}</p>
        </v-row>
        <v-row>
          <span :style="{ color: getTextColor }" :class="{ clamp: $store.state.overviewMode }" class="message ma-0 mp-0" v-html="getHtml()" @click="tryFindCowInfo" />
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="getDismissed">
      <v-divider />
    </v-row>
    <ConfirmAlarm v-if="showConfirmAlarm" :alarm="alarm" @closed="showConfirmAlarm = false" />
  </v-container>
</template>

<script>
import ConfirmAlarm from "@/components/alarms/ConfirmAlarm.vue";

export default {
  name: "AlarmCard",

  components: {
    ConfirmAlarm,
  },

  props: {
    alarm: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      showConfirmAlarm: false,
      icon: this.alarm.icon,
      device: this.alarm.sourceDevice,
      date: this.getTimeStamp(this.alarm.time),
      dismissDate: this.alarm.dismissDate,
      severity: this.alarm.severity,
    };
  },

  computed: {
    getBackgroundColor: {
      get() {
        if (this.dismissDate) return this.theme.alarms_bgGray;
        else if (this.alarm.severity == 4) return this.theme.alarms_bgBlue;
        else if (this.alarm.severity == 5) return this.theme.alarms_bgRed;
        else return this.theme.alarms_bgGray;
      },
    },

    getTextColor: {
      get() {
        if (this.dismissDate) return this.theme.alarms_textBlack;
        else return this.theme.alarms_textWhite;
      },
    },

    getImgSrc: {
      get() {
        if (this.dismissDate) {
          return require("@/assets/alarms/black/" + this.icon + ".png");
        } else {
          return require("@/assets/alarms/white/" + this.icon + ".png");
        }
      },
    },

    getDismissed: {
      get() {
        return this.dismissDate;
      },
    },
  },

  methods: {
    tryFindCowInfo(e) {
      //console.log("tryFindCowInfo", e.target);
      if (e.target.matches(".cow-info-link")) {
        console.log("tryFindCowInfo: cow=" + e.target.id);
        this.$router.push("/cow/" + e.target.id + "/info");
      }
    },

    getHtml() {
      let html = this.alarm.message;
      // Replace any possible cow number with a link
      this.$store.state.farmData.data.animals.forEach((animal) => {
        if (html.includes(animal.animalNr)) {
          let linkColor = this.theme.alarms_textWhite;
          if (this.dismissDate) linkColor = this.theme.alarms_textBlack;
          html = html.replace(new RegExp(animal.animalNr, "g"), "<span style='color: " + linkColor + "; text-decoration:underline; cursor:pointer;' class='cow-info-link' id='" + animal.animalNr + "'>" + animal.animalNr + "</span>");
        }
      });
      //console.log("getMessage html=" + html);
      return html;
    },

    getTimeStamp(time_ms) {
      let date = new Date(time_ms);
      if (this.isToday(date)) {
        return date.toLocaleTimeString("sv-SE", {
          timeStyle: "short",
        });
      } else {
        return date.toLocaleString("sv-SE", {
          timeStyle: "short",
          dateStyle: "short",
        });
      }
    },

    isToday(date) {
      const today = new Date();
      return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
    },

    showConfirmDialoge() {
      if (!this.$store.state.overviewMode && !this.dismissDate) {
        console.info("showConfirmDialoge: device=" + this.device);
        this.showConfirmAlarm = true;
      }
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 6px;
}
.clickable {
  cursor: pointer;
}
.device {
  font-weight: bold;
  font-size: 0.9em;
}
.date {
  font-size: 0.75em;
}
.message {
  font-size: 0.9em;
}
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>


