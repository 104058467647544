import Vue from "vue";
import Vuex from "vuex";
import saveToLocalStorage from "@/store/plugins/webStorage";
import Common from "@/services/Common.js";
import Names from "@/services/Names.js";
import { Cow } from "@/services/shared/Cow.js";

const myfarmMainServer = process.env.VUE_APP_MYFARM_URL;

const emojiList = [
  { src: require("@/assets/chat/emoji/bigsmile.png"), code: ":D", tip: "Big smile" },
  { src: require("@/assets/chat/emoji/crying.png"), code: ";'(", tip: "Crying" },
  { src: require("@/assets/chat/emoji/happy.png"), code: ":)", tip: "Happy" },
  { src: require("@/assets/chat/emoji/inlove.png"), code: ":*", tip: "Kiss" },
  { src: require("@/assets/chat/emoji/neutral.png"), code: ":|", tip: "Neutral" },
  { src: require("@/assets/chat/emoji/o.png"), code: ":o", tip: "Surprised" },
  { src: require("@/assets/chat/emoji/sad.png"), code: ":(", tip: "Crying" },
  { src: require("@/assets/chat/emoji/square-mouth.png"), code: ":[]", tip: "Square mouth" },
  { src: require("@/assets/chat/emoji/tongue.png"), code: ":p", tip: "Tongue out" },
  { src: require("@/assets/chat/emoji/wink.png"), code: ";)", tip: "Wink" },
  { src: require("@/assets/chat/emoji/x).png"), code: "x)", tip: "X smily" },
  { src: require("@/assets/chat/emoji/blue-star.png"), code: "(blue star)", tip: "Blue star" },
  { src: require("@/assets/chat/emoji/green-star.png"), code: "(green star)", tip: "Green star" },
  { src: require("@/assets/chat/emoji/red-star.png"), code: "(red star)", tip: "Red star" },
  { src: require("@/assets/chat/emoji/yellow-star.png"), code: "(yellow star)", tip: "Yellow star" },
  { src: require("@/assets/chat/emoji/cow.png"), code: "(cow)", tip: "Cow" },
  { src: require("@/assets/chat/emoji/cows.png"), code: "(cows)", tip: "Cows" },
  { src: require("@/assets/chat/emoji/calf.png"), code: "(calf)", tip: "Calf" },
  { src: require("@/assets/chat/emoji/delete-cow.png"), code: "(delete cow)", tip: "Delete cow" },
  { src: require("@/assets/chat/emoji/heat.png"), code: "(heat)", tip: "Heat" },
  { src: require("@/assets/chat/emoji/feed.png"), code: "(feed)", tip: "Feed" },
  { src: require("@/assets/chat/emoji/auto.png"), code: "(auto)", tip: "Auto" },
  { src: require("@/assets/chat/emoji/manual.png"), code: "(manual)", tip: "Manual" },
  { src: require("@/assets/chat/emoji/service.png"), code: "(service)", tip: "Service" },
  { src: require("@/assets/chat/emoji/bcs.png"), code: "bcs", tip: "Auto" },
  { src: require("@/assets/chat/emoji/dump-milk.png"), code: "(dump milk)", tip: "Dump milk" },
  { src: require("@/assets/chat/emoji/examine.png"), code: "(examine)", tip: "Examine" },
  { src: require("@/assets/chat/emoji/fan.png"), code: "(fan)", tip: "Fan" },
  { src: require("@/assets/chat/emoji/farm.png"), code: "(farm)", tip: "Farm" },
  { src: require("@/assets/chat/emoji/fire.png"), code: "(fire)", tip: "Fire" },
  { src: require("@/assets/chat/emoji/flag.png"), code: "(flag)", tip: "Flag" },
  { src: require("@/assets/chat/emoji/health.png"), code: "(health)", tip: "Health" },
  { src: require("@/assets/chat/emoji/heart.png"), code: "<3", tip: "Heart" },
  { src: require("@/assets/chat/emoji/lamp.png"), code: "(lamp)", tip: "Lamp" },
  { src: require("@/assets/chat/emoji/list.png"), code: "(list)", tip: "List" },
  { src: require("@/assets/chat/emoji/message.png"), code: "(message)", tip: "Message" },
  { src: require("@/assets/chat/emoji/milk-empty.png"), code: "()", tip: "Milk empty" },
  { src: require("@/assets/chat/emoji/milk-full.png"), code: "(milk full)", tip: "Milk full" },
  { src: require("@/assets/chat/emoji/milking.png"), code: "(milking)", tip: "Milking" },
  { src: require("@/assets/chat/emoji/moon.png"), code: "(moon)", tip: "Moon" },
  { src: require("@/assets/chat/emoji/notification.png"), code: "(notification)", tip: "Auto" },
  { src: require("@/assets/chat/emoji/picture.png"), code: "(picture)", tip: "Picture" },
  { src: require("@/assets/chat/emoji/semen.png"), code: "(semen)", tip: "Semen" },
  { src: require("@/assets/chat/emoji/sun.png"), code: "(sun)", tip: "Sun" },
  { src: require("@/assets/chat/emoji/tank.png"), code: "(tank)", tip: "Tank" },
  { src: require("@/assets/chat/emoji/tap.png"), code: "(tap)", tip: "Tap" },
  { src: require("@/assets/chat/emoji/temp.png"), code: "(temp)", tip: "Temp" },
  { src: require("@/assets/chat/emoji/time.png"), code: "(time)", tip: "Time" },
  { src: require("@/assets/chat/emoji/udder.png"), code: "(udder)", tip: "Udder" },
  { src: require("@/assets/chat/emoji/vaccine.png"), code: "(vaccine)", tip: "Vaccine" },
];

Vue.use(Vuex);

function sortAlarmList(state) {
  let list = state.farmData.data.alarms.alarms.list.sort((c1, c2) => (c1.time < c2.time ? 1 : c1.time > c2.time ? -1 : 0));
  list = list.sort((c1, c2) => (!c1.dismissDate && c2.dismissDate ? -1 : 0));
  state.farmData.data.alarms.alarms.list = list;
}

const moduleUsers = {
  namespaced: true,

  state: {
    config: {
      data: {},
      updated: null,
    },

    selectedId: null,
  },

  getters: {
    getUsers(state) {
      let list = [];
      if (state.config.data.users) {
        // Extract all users for selected farm
        state.config.data.users.forEach((user) => {
          user.roles.forEach((role) => {
            if (role.domainId === state.config.data.target) {
              // Only keep the user role for selected farm
              delete user.roles;
              user.role = role;
              // Add name for the roles
              user.role.names = [];
              user.role.roles.forEach((roleId) => {
                for (let key in state.config.data.allRoles) {
                  if (key === roleId) {
                    let name = state.config.data.allRoles[key].roleName;
                    user.role.names.push(name);
                  }
                }
              });
              list.push(user);
            }
          });
        });
      }
      // console.log("getUsers", list);
      return list;
    },

    getAccessibleRoles(state) {
      // TODO: Sort list...
      let list = [];
      for (let key in state.config.data.allRoles) {
        let role = state.config.data.allRoles[key];
        let accessible = (state.config.data.perm | role.accessRightMask) === state.config.data.perm;
        if (accessible && role.accessRightMask !== 0) list.push(role); // TODO: "Nothing" role is removed, correct?
      }
      // console.log("getAccessibleRoles", list);
      return list;
    },
  },

  mutations: {
    setConfig(state, data) {
      // console.log("Users setConfig", data);
      // Add all data
      state.config.data = Object.assign({}, data);
      // Sort order of users
      if (state.config.data.users) {
        let list = state.config.data.users.sort((p1, p2) => (p1.firstName.toUpperCase() > p2.firstName.toUpperCase() ? 1 : p1.firstName.toUpperCase() < p2.firstName.toUpperCase() ? -1 : 0));
        state.config.data.users = list;
      }
    },

    setSelectedId(state, id) {
      state.selectedId = id;
    },
  },
};

const moduleSettings = {
  namespaced: true,

  state: {
    config: {
      data: {},
      updated: null,
    },
  },

  mutations: {
    setConfig(state, data) {
      // console.log("Settings setConfig", data);
      // Add all data
      state.config.data = Object.assign({}, data);
      // Check if selected farm is in the list (if it has any settings)
      let index = -1;
      if (state.config.data.notify) {
        index = state.config.data.notify.findIndex((n) => n.domainId === state.config.data.vcId);
      }
      if (index < 0) {
        // Not in the list
        let selected = {
          domainId: state.config.data.vcId,
          domainName: state.config.data.vcName,
          notifyFarmData: {
            isChatMessageAlarm: false,
            isChatMessageAlarmQuiet: false,
            isNotificationAlarm: false,
            isNotificationAlarmQuiet: false,
            isStopAlarm: false,
            isStopAlarmQuiet: false,
            isVcCommunicationProblemNotification: false,
            isVcCommunicationProblemNotificationQuiet: false,
          },
        };
        // Add it to the list
        if (!state.config.data.notify) state.config.data.notify = [];
        state.config.data.notify.unshift(selected);
      }
      // Sort order of devices
      if (state.config.data.phones) {
        let list = state.config.data.phones.sort((p1, p2) => (p1.last < p2.last ? 1 : p1.last > p2.last ? -1 : 0));
        state.config.data.phones = list;
      }
      // Add no pwd change as default
      state.config.data.pwd = 0;
      // TODO: Add target... remove if its not needed when save settings
      state.config.data.target = state.config.data.id;
    },

    profile_FirstName(state, value) {
      state.config.data.firstName = value;
    },

    profile_LastName(state, value) {
      state.config.data.lastName = value;
    },

    profile_Email(state, value) {
      state.config.data.email = value;
    },

    profile_Language(state, data) {
      // Note: Stored outside the fetched data
      state.config.language = data;
    },

    profile_Unit(state, value) {
      state.config.data.useImperialUnits = value;
    },

    password_Hashed(state, value) {
      // Note: Added to fetched data
      state.config.data.pwd = value; // TODO: Move outside ov settings? Since its also is used at login of new users...
    },

    notifications_SendToMe(state, value) {
      state.config.data.deviceNotifications.doNotify = value;
    },

    notifications_Vibration(state, value) {
      state.config.data.deviceNotifications.isVibration = value;
    },

    notifications_QuietTime(state, value) {
      state.config.data.deviceNotifications.useQuietTime = value;
    },

    notifications_QuietFromTime(state, value) {
      state.config.data.deviceNotifications.millisecFromTime = value;
    },

    notifications_QuietToTime(state, value) {
      state.config.data.deviceNotifications.millisecToTime = value;
    },

    farms_Alarm(state, data) {
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isStopAlarm = data.value;
    },

    farms_Notification(state, data) {
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isNotificationAlarm = data.value;
    },

    farms_Message(state, data) {
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isChatMessageAlarm = data.value;
    },

    farms_Internet(state, data) {
      //console.log("farms_Internet index=" + data.index + " value=" + data.value);
      // TODO: Check why is this not reactive?
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isVcCommunicationProblemNotification = data.value;
    },

    farms_AlarmQuiet(state, data) {
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isStopAlarmQuiet = data.value;
    },

    farms_NotificationQuiet(state, data) {
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isNotificationAlarmQuiet = data.value;
    },

    farms_MessageQuiet(state, data) {
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isChatMessageAlarmQuiet = data.value;
    },

    farms_InternetQuiet(state, data) {
      state.config.data.notify[state.config.data.notify.findIndex((p) => p.domainId === data.id)].notifyFarmData.isVcCommunicationProblemNotificationQuiet = data.value;
    },

    devices_Name(state, data) {
      state.config.data.phones[state.config.data.phones.findIndex((p) => p.id === data.id)].name = data.value;
    },

    devices_Notification(state, data) {
      state.config.data.phones[state.config.data.phones.findIndex((p) => p.id === data.id)].notification = data.value;
    },

    devices_Delete(state, data) {
      state.config.data.phones[state.config.data.phones.findIndex((p) => p.id === data.id)].delete = data.value;
    },
  },
};

export default new Vuex.Store({
  plugins: [saveToLocalStorage],

  state: {
    i18n: {
      lang: "en",
      messages: {},
      updated: null,
    },
    auth: {
      id: 0,
      changePwd: false, // TODO: These don't have any mutations... still ok?
      acceptEula: false,
      wrongCreds: false,
      isAuthenticated: false,
      isAuthenticating: true,
      authenticationMessage: "",
      login: {
        email: null,
        password: null,
        updated: null,
      },
    },

    farms: {
      data: {
        farms: [],
      },
      updated: null,
    },

    servers: {
      loginList: [],
      list: [],
      updated: null,
    },

    farmData: {
      data: {},
      updated: null,
    },

    chat: {
      selectedId: null,
      emojiList: emojiList,
    },

    overviewMode: true,
    selectedFarm: null,
    loadingFarm: null,
    isLoadingInitialData: false,
    isPortraitMode: screen.availHeight > screen.availWidth,
    isLandscapeMode: !(screen.availHeight > screen.availWidth),
  },

  getters: {
    useImperialUnits(state) {
      return state.settings && state.settings.config && state.settings.config.data ? state.settings.config.data.useImperialUnits : false;
    },

    getCowQCounts(state) {
      let status = [0, 0, 0, 0, 0, 0];
      let incomplete = 0;
      let total = 0;
      if (state.farmData.data.animals) {
        total = state.farmData.data.animals.length;
        state.farmData.data.animals.forEach((a) => status[a.getCowQStatus()]++);
        state.farmData.data.animals.forEach((a) => (incomplete += a.isIncompleteWithMilkpermission() ? 1 : 0));
      }
      return { green: Cow.CowQStatus.green(status), white: Cow.CowQStatus.white(status), yellow: Cow.CowQStatus.yellow(status), red: Cow.CowQStatus.red(status), total, incomplete };
    },

    getServerForSelectedFarm(state) {
      let protocol = location.protocol + "//";
      if (!state.selectedFarm || state.selectedFarm.id == -1) {
        return protocol + myfarmMainServer;
      } else {
        if (state.servers.list[state.selectedFarm.srv]) return protocol + state.servers.list[state.selectedFarm.srv];
        else {
          console.error("Failed to resolve server, fallback to main!", myfarmMainServer);
          return protocol + myfarmMainServer;
        }
      }
    },

    getNumberOfUnreadChats(state) {
      let counter = 0;
      if (state.farmData.data.messages.list) {
        state.farmData.data.messages.list.forEach((c) => (counter += c.time > state.farmData.data.messages.lastReadTime ? 1 : 0));
      }
      return counter;
    },

    getSelectedOrDefaultProfile(state) {
      const defaultProfile = "Milking queue";
      let nameKey = state.farmData.data.profiles.deLaval.choosenProfile;
      let profile = state.farmData.data.profiles.data.find((p) => p.nameKey == nameKey);
      return profile ? profile : state.farmData.data.profiles.data.find((p) => p.nameKey == defaultProfile);
    },
  },

  mutations: {
    setOrientation(state, value) {
      if (state.isPortraitMode != value) {
        // console.log("Orientation changed to " + (value ? "Portrait" : "Landscape"));
        state.isPortraitMode = value;
        state.isLandscapeMode = !value;
      }
    },

    isLoadingInitialData(state, value) {
      state.isLoadingInitialData = value;
    },

    userId(state, id) {
      state.auth.id = id;
    },

    selectedFarm(state, farm) {
      state.selectedFarm = farm;
    },

    loadingFarm(state, farm) {
      state.loadingFarm = farm;
    },

    setFarms(state, farms) {
      // console.log("setFarms", farms);
      if (farms) state.farms.data.farms.push(...farms);
      else state.farms.data.farms.length = 0;
      state.farms.updated = new Date();
    },

    setLoginServers(state, { servers, isAuthenticated }) {
      // console.log("setLoginServers", servers);
      state.servers.loginList.length = 0;
      servers.forEach((s) => {
        let obj = {
          url: location.protocol + "//" + s,
          isAuthenticated: isAuthenticated,
        };
        state.servers.loginList.push(obj);
      });
    },

    updateLoginServer(state, server) {
      // console.log("updateLoginServer", server);
      let index = state.servers.loginList.findIndex((s) => s.url === server.url);
      state.servers.loginList[index] = server;
    },

    setServers(state, servers) {
      if (servers) {
        // console.log("setServers", servers);
        state.servers.list.length = 0;
        state.servers.list.push(...servers);
        state.servers.updated = new Date();
      } else {
        // console.log("setServers (Clearing)");
        state.servers.list.length = 0;
        state.servers.updated = new Date();
      }
    },

    setCow(state, cow) {
      // console.log("setCow " + cow.animalNr, cow);
      let indexToRemove = state.farmData.data.animals.findIndex((c) => c.animalNr == cow.animalNr);
      if (indexToRemove != -1) {
        state.farmData.data.animals.splice(indexToRemove, 1);
      }
      state.farmData.data.animals.push(cow);
      state.farmData.data.animals.sort((e1, e2) => e1.lastMilkingTime - e2.lastMilkingTime);
      state.farmData.updated = new Date();
    },

    setFarmData(state, farmData) {
      if (farmData) {
        // console.log("setFarmData", farmData);
        state.farmData.data = Object.assign({}, farmData);
        if (state.farmData.data.messages) {
          let list = state.farmData.data.messages.list.sort((c1, c2) => (c1.time < c2.time ? 1 : c1.time > c2.time ? -1 : 0));
          state.farmData.data.messages.list = list;
        }
        if (state.farmData.data.alarms.alarms) {
          sortAlarmList(state);
        }

        let groupCount = state.farmData.data.animals.reduce((groupCount, a) => (a.animalGroupId ? (groupCount[a.animalGroupId] ? groupCount[a.animalGroupId]++ : (groupCount[a.animalGroupId] = 1)) : 0, groupCount), {});
        // console.log("Animal group counting:", groupCount);
        state.farmData.data.groups.forEach((g) => (g.animalCount = groupCount[g.GroupId] ? groupCount[g.GroupId] : 0));
        state.farmData.data.groups.sort((o1, o2) => Common.sortText(o1.GroupName, o2.GroupName));

        if (farmData) state.farmData.data.profiles.deLaval.choosenProfile = localStorage.getItem("choosenProfile") || "";
        state.farmData.updated = new Date();
      } else {
        // console.log("setFarmData (Clearing)");
        state.farmData.data = Object.assign({}, []);
        state.farmData.updated = new Date();
      }
    },

    setFarmAlarm(state, alarms) {
      // console.log("setFarmAlarm", alarms);
      alarms.forEach((alarm) => {
        let index = state.farmData.data.alarms.alarms.list.findIndex((a) => a.guid === alarm.guid);
        if (index >= 0) state.farmData.data.alarms.alarms.list.splice(index, 1);
        state.farmData.data.alarms.alarms.list.unshift(alarm);
      });
      sortAlarmList(state);
    },

    setFarmChat(state, chat) {
      // console.log("setFarmChat", chat);
      let index = state.farmData.data.messages.list.findIndex((c) => c.id === chat.id);
      if (index >= 0) state.farmData.data.messages.list.splice(index, 1);
      if (!chat.isDeleted) state.farmData.data.messages.list.unshift(chat);
    },

    resetChatLastReadTime(state) {
      // console.log("resetChatLastReadTime");
      state.farmData.data.messages.lastReadTime = Date.now();
    },

    setSelectedChatId(state, id) {
      state.chat.selectedId = id;
    },

    setOverviewMode(state, value) {
      // console.log("setOverviewMode: value=" + value);
      state.overviewMode = value;
    },

    setChoosenProfile(state, profileNameKey) {
      localStorage.setItem("choosenProfile", profileNameKey);
      state.farmData.data.profiles.deLaval.choosenProfile = profileNameKey;
    },

    triggerLogin(state, login) {
      console.log("triggerLogin", login);
      state.auth.login.email = login.email;
      state.auth.login.password = login.password;
      state.auth.login.updated = new Date();
      state.auth.isAuthenticating = true;
      state.auth.authenticationMessage = "User logged in";
    },

    login(state, value) {
      console.log("login", value);
      state.auth.changePwd = false;
      state.auth.acceptEula = false;
      state.auth.wrongCreds = false;
      state.auth.isAuthenticated = value.state; //Check cookie
      state.auth.authenticationMessage = value.message;
      state.auth.isAuthenticating = false;
    },

    lastAccessedByVc(state, value) {
      state.farmData.lastAccessedByVc = value;
    },

    setTranslationMessages(state, value) {
      state.i18n.lang = value.lang || "en";
      state.i18n.messages = value.messages;
      state.i18n.updated = new Date();
    },
  },

  actions: {},

  modules: {
    users: moduleUsers,
    settings: moduleSettings,
  },
});
