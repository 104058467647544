import Names from "@/services/Names.js";
const Axios = require("axios");
Axios.defaults.withCredentials = true;
var Store;

export default {
  initialize(store) {
    Store = store;
    console.log("Chat: initialize");
  },

  addMessage(text) {
    console.log("addMessage");
    let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvChat/addMessage";
    Axios.post(url, JSON.stringify({ vcId: Store.state.selectedFarm.id, text: text }))
      .then((res) => {
        console.log("addMessage: res=", res);
      })
      .catch((err) => console.error(err));
  },

  changeMessage(text, id) {
    console.log("changeMessage");
    let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvChat/changeMessage";
    Axios.post(url, JSON.stringify({ vcId: Store.state.selectedFarm.id, text: text, id: id }))
      .then((res) => {
        console.log("changeMessage: res=", res);
      })
      .catch((err) => console.error(err));
  },

  deleteMessage(id) {
    console.log("deleteMessage");
    let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvChat/deleteMessage";
    Axios.post(url, JSON.stringify({ vcId: Store.state.selectedFarm.id, id: id }))
      .then((res) => {
        console.log("deleteMessage: res=", res);
      })
      .catch((err) => console.error(err));
  },

  hasReadMessage() {
    console.log("hasReadMessage");
    let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvMyFarm/hasReadMessages";
    let vcId = Store.state.selectedFarm.id;
    Axios.post(url, JSON.stringify({ vcId: vcId }))
      .then((res) => {
        console.log("hasReadMessage: res=", res);
      })
      .catch((err) => console.error(err));
    Store.commit(Names.resetChatLastReadTime);
  },
};
