import Parse from "@/services/shared/Parse.js"
import {ColumnIndex} from "@/services/shared/Profile.js"
import {Text} from "@/services/shared/Translate.js"

function formatDateOnly(d)
{
  if (d instanceof Date)
    return d.toISOString().slice(0, 10);
  return d;
}

export class Cow {
  static actions = {
    Milk: 0,
    Feed: 1,
    PassThrough: 2,
    Unselected: 3,
    properties:{
      names: ['Milk', 'FeedOnly', 'PassThrough', 'Unselected'],
    },
  }
  static CowQStatus = {
    PassThrough: 0,
    FeedOnly: 1,
    Milk: 2,
    MilkPermission: 3,
    MilkPermissionNeverMilked: 4,
    MilkOverdue: 5,
    properties:{
      names: ['PassThrough', 'FeedOnly', 'Milk', 'MilkPermission', 'MilkPermissionNeverMilked', 'MilkOverdue'],
    },
    green: (arr) => arr[0] + arr[1],
    white: (arr) => arr[2],
    yellow: (arr) => arr[3] + arr[4],
    red: (arr) => arr[5],
  }
  static NoteEventType = {
    General: 0,
    Reproduction: 1,
    Milking: 2,
    Feeding: 3,
    Health: 4,
    CowMonitor: 5,
    StatusBoard: 6,
    properties: ['General', 'Reproduction', 'Milking', 'Feeding', 'Health', 'CowMonitor', 'StatusBoard'],
  }
  static ReproductionStatusType = [
    'Bred',
    'Calf',
    'Culled',
    'Dry',
    'Fresh',
    'Heifer',
    'Open',
    'Pregnant',
    'Unknown',
  ]
  static ActivityType = [
    '',
    '+',
    '++',
    '+++',
    '(+)',
    '(++)',
    '(+++)',
  ]

  static activitySortOrder = [0, 4, 5, 6, 1, 2 ,3];

  static propertyNamesToConvertKg2Lb = [
    'expectedSpeed',
    'carryOver',
    'totalYield',
    'lastSevenDays',
  ]

  static propertyNamesInOrder = [
    'exists',
    'animalNr',
    'animalName',
    'animalNotesSerialized',
    'animalGroupId',
    'highActivity',
    'action',
    'markedByUser',
    'markedBySign',
    'notification',
    'timeToNextMilking',
    'lastMilkingTime',
    'overdueTime',
    'lactation',
    'lactationDay',
    'lastMilkingFlags', //was mask
    'expectedSpeed',
    'carryOver',
    'totalYield',
    'expectedYieldDiffPercent',
    'lastMilkingDevice',
    'lastSevenDays',
    'nrMilkingPerDay',
    'sumBitMask',
    'areaGUID',
    'inAreaSince',
    'expectedCalvingDate',
    'fetchCow',
    'trapMask',             // bit 0=enabled, 1=spanEnabled, bit 2-6 spanFromHour, bit 7-11 spanToHour
    'trapStartTime',
    'trapEndTime',
    'expectedBuildupDate',
    'expectedDryOff',
    'expectedHeatDate',
    'expectedPregnancyCheck',
    'lastHeatDate',
    'lastInseminationDate',
    'latestSCCDate',
    'animalMilkingDestination',
    'breed',
    'hoursSinceHighActivity',
    'latestSCC',
    'expectedInseminationDue',
    'expectedInseminationDueDate',
    'isDryingOff',
    'toBeCulled',
    'reproductionStatus',
    'relativeActivity',
    'occArray',
  ];

  static hexDateProperties = [
    'timeToNextMilking',
    'lastMilkingTime',
    'lactationDay',
    'inAreaSince',
    'expectedCalvingDate',
    'trapStartTime',
    'trapEndTime',
    'expectedBuildupDate',
    'expectedDryOff',
    'expectedHeatDate',
    'expectedPregnancyCheck',
    'lastHeatDate',
    'lastInseminationDate',
    'latestSCCDate',
    'expectedInseminationDueDate',
  ];

  static oneSecond = 1000;
  static oneMinute = Cow.oneSecond * 60;
  static oneHour = Cow.oneMinute * 60;
  static oneDay = Cow.oneHour * 24;

  constructor(dataArray, info, useImperialUnits) {
    let f = dataArray.splice(0, Cow.propertyNamesInOrder.length);
    Cow.propertyNamesInOrder.forEach((property, index) => this[property] = f[index]);
    this.highActivitySortValue = Cow.activitySortOrder[+this.highActivity];
    this.occArray = dataArray.splice(0, this.occArray);
    Cow.hexDateProperties.forEach((property) => this[property] = Parse.hexDate(this[property], property))
    this.lastMilkingFlags = Cow.parseLastMilkingFlags(this.lastMilkingFlags);
    let {GroupName, GroupNr} = this.getGroup(info.isFarm ? info.groups : info.state.farmData.data.groups);
    this.groupName = GroupName;
    this.groupNr = GroupNr;
    let {AreaName, AreaType} = this.getArea(info.isFarm ? info.areas : info.state.farmData.data.areas);
    this.areaName = AreaName;
    this.areaType = AreaType;
    this.parseNotes(this.animalNotesSerialized);
    var result = this.notification ? this.notification + ' ' : '';
    result += this.markBySign ? '(' + this.markBySign + ')' : '';
    this.notificationSigned = result.trim();
    this.parseTrapInfo(this);
    this.cowHealth = this.parseCowHealth(this.sumBitMask);
    if (useImperialUnits)
      this.convertToImperical();
  }

  convertToImperical() {
    if (this.useImperialUnits) {
      return;
    }
    Cow.propertyNamesToConvertKg2Lb.forEach( prop => (this[prop] = this[prop] ? this[prop] * Parse.lbPerKg : this[prop]))
    this.useImperialUnits = true;
  }

  convertToMetric() {
    if (!this.useImperialUnits) {
      return;
    }
    Cow.propertyNamesToConvertKg2Lb.forEach( prop => (this[prop] = this[prop] ? this[prop] / Parse.lbPerKg : this[prop]))
    this.useImperialUnits = false;
  }

  parseTrapInfo(cow) {
    let now = new Date();
    var weekDay = Text.getText('weekDay').split(',');
    let getDateString = function(d,from) {
      var dt = new Date(now.getTime()), dd = new Date(d.getTime()),s=from?'':'-';
      dt.setHours(0,0,0,0);
      dd.setHours(0,0,0,0);
      dt=(dd.getTime()-dt.getTime())/86400000;
      if (from && dt > 1)
        return'';
      if (dt === 0){
        var h = d.getHours(), m = d.getMinutes();
        return s+(h>=23?Text.getText('today'):(h<10?'0'+h.toString():h.toString())+':'+(m<10?'0'+m.toString():m.toString()));
      }
      if (dt > 0 && dt < 7) return s+weekDay[dt];
      return s+(d.getDate().toString()+'/'+(d.getMonth()+1).toString());
    };

    if (cow.trapMask & 1) {
      if (cow.trapEndTime < now) {
        cow.trapMas &= 0xfffe;
      } else {
        cow.trapString = getDateString(cow.trapStartTime,1)+getDateString(cow.trapEndTime,0);
        if(cow.trapMask & 2 && cow.trapMask & 4092)
          cow.trapString += ' ' + ((cow.trapMask>>2)&31) + '-' + ((cow.trapMask>>7) & 31);
      }
    }
  }

  parseNotes(serialData) {
    this.notes = {hasNotes: false};
    Cow.NoteEventType.properties.forEach((noteTypeName, index) => this.notes[index] = []);
    if (this.animalNotesSerialized)
    {
      this.notes.hasNotes = true;
      let fields = serialData.split("#");
      let [notesCount] = fields.splice(0, 1);
      while (notesCount--) {
        let [objectGUID, type, comment, time, user] = fields.splice(0, 5);
        this.notes[type].push({objectGUID, type, comment, time: new Date(+time), user});
      }
      Cow.NoteEventType.properties.forEach((noteTypeName, index) => this.notes[index].sort((o1,o2) => o1.time-o2.time));
    }
  }

  //Green: animals that will NEVER get milkpermission with current settings (mostly feedonly)
  //White: animals without milkpermission (that will get it...)
  //Yellow: animals with milkpermission that are not 'overdue' (see red)
  //pink-ish: animals with milkpermission thats never been milked
  //Red: animals with milkpermission that are overdue (default 12hours since last milking)
  getCowQStatus() {
    if (this.action == Cow.actions.Feed) {
      return Cow.CowQStatus.FeedOnly;
    }
    if (this.action == Cow.actions.PassThrough || this.action == Cow.actions.Unselected) {
      return Cow.CowQStatus.PassThrough;
    }
    let now = new Date().getTime();
    let nextMilking = this.timeToNextMilking.getTime();
    if (nextMilking > now) {
      return Cow.CowQStatus.Milk;
    }
    if (!this.lastMilkingTime) {
      return Cow.CowQStatus.MilkPermissionNeverMilked;
    }
    let overdueDelta = this.overdueTime*1000;
    let lastMilkingTime = this.lastMilkingTime.getTime();
    if (lastMilkingTime + overdueDelta > now) {
      return Cow.CowQStatus.MilkPermission;
    }
    return Cow.CowQStatus.MilkOverdue;
  }
  getCowQStatusName() {
    return Cow.CowQStatus.properties.names[this.getCowQStatus()];
  }
  getCowActionName() {
    return Cow.actions.properties.names[this.action];
  }

  isIncompleteWithMilkpermission() {
    let now = new Date().getTime();
    let nextMilking = this.timeToNextMilking.getTime();
    if (nextMilking > now) {
      return false;
    }
    return this.isIncomplete();
  }

  isIncompleteLast48h() {
    if (!this.lastMilkingTime)
      return false;
    let now = new Date().getTime();
    let lastMilkingTime = this.lastMilkingTime.getTime();
    if (now - lastMilkingTime > 1000*60*60*48) {
      return false;
    }
    return this.isIncomplete();
  }

  isIncomplete() {
    return (this.lastMilkingFlags.incompleteLF || this.lastMilkingFlags.incompleteRF || this.lastMilkingFlags.incompleteLR || this.lastMilkingFlags.incompleteRR);
  }

  isTrapCow() {
    return this.trapMask & 0x1;
  }

  static parseLastMilkingFlags(flags)
  {
      return {
      incompleteLF: !!(flags & 1),
      incompleteRF: !!(flags & 16),
      incompleteLR: !!(flags & 256),
      incompleteRR: !!(flags & 4096),
      kickOffLF: !!(flags & 2),
      kickOffRF: !!(flags & 32),
      kickOffLR: !!(flags & 512),
      kickOffRR: !!(flags & 8192),
      notMilkedTeatLF: !!(flags & 4),
      notMilkedTeatRF: !!(flags & 64),
      notMilkedTeatLR: !!(flags & 1024),
      notMilkedTeatRR: !!(flags & 16384),
    }
  }

  getGroup(groups)
  {
    return groups.find((g) => g.GroupId == this.animalGroupId) || {GroupName: "", GroupNr: ""};
  }

  getArea(areas)
  {
    return areas.find((a) => a.AreaGuid == this.areaGUID) || {AreaName: "", AreaType: ""};
  }

  static getMinutesSince(tNow, whenItHappened) {
    if (!whenItHappened || whenItHappened.getTime() == 0) {
      return 0;
    }
    return (tNow - whenItHappened.getTime())/(1000*60);
  }

  static getTimeSince(whenItHappened, onlyDays=false, maxOneDay = false)
  {
    if (!whenItHappened || whenItHappened.getTime() == 0) {
      return "";
    }

    const now = new Date();
    let timeSince = now.getTime() - whenItHappened.getTime();
    let sign = "";
    if (timeSince < 0) {
      sign ="-";
      timeSince = -timeSince;
    }
    let days = Math.floor(timeSince / Cow.oneDay);
    if (onlyDays)
      return days;
    if (maxOneDay && days>0)
      return "";
    timeSince -= days*Cow.oneDay;
    let hours = Math.floor(timeSince / Cow.oneHour);
    timeSince -= hours*Cow.oneHour;
    let minutes = Math.floor(timeSince / Cow.oneMinute);
    return sign + (days ? days+"d " : "") + hours.toString().padStart(2,"0") + ":" + minutes.toString().padStart(2,"0");
  }

  getTeatStatus()
  {
    return (this.lastMilkingFlags.incompleteLF ? "LF" :"") + 
    (this.lastMilkingFlags.incompleteLR ? "LR" :"") +
    (this.lastMilkingFlags.incompleteRF ? "RF" :"") +
    (this.lastMilkingFlags.incompleteRR ? "RR" :"");
  }

  getPositivValue(value)
  {
    return value > 0 ? value : "-";
  }

  getExpectedYield(forSorting = false, massUnit)
  {
    if (!this.lastMilkingTime)
      return forSorting ? 0 : "-";

    let now = new Date();
    let timeSince = now.getTime() - this.lastMilkingTime.getTime();
    if (timeSince > Cow.oneDay)
      timeSince = Cow.oneDay;
    return  forSorting ? 
            (+this.carryOver) + this.expectedSpeed * (timeSince/Cow.oneHour) : 
            (((+this.carryOver) + this.expectedSpeed * (timeSince/Cow.oneHour))/100).toPrecision(3) + ' ' + massUnit;
  }

  getAllNoteOfType(noteTypeIndex, sep = "\n")
  {
    return this.notes[noteTypeIndex].map((note) => note.comment).join(sep);
  }

  parseCowHealth(v) {
    v = parseInt(v, 16);
    const bloodPosA = 10;
    const bloodPosB = 12;
    const mdiPosA = 14;
    const mdiPosB = 16;
    const condPosA = 18;
    const condPosB = 20;
    const mask = 0x03;
    return { 
      blood: {trend: (v>>bloodPosA)&mask, color: (v>>bloodPosB)&mask},
      mdi: {trend: (v>>mdiPosA)&mask, color: (v>>mdiPosB)&mask},
      conductivity: {trend: (v>>condPosA)&mask, color: (v>>condPosB)&mask}
    };
  }

  getColumnValue(columnIndex, state, codeSet, massUnit)
  {
    switch (columnIndex) {
      case ColumnIndex.None:
        return "";
      case ColumnIndex.Teat_status:
        return this.getTeatStatus();
      case ColumnIndex.Animal_nr:
        return this.animalNr;
      case ColumnIndex.Group__no: { 
        return this.groupName + " (" + this.groupNr + ")";
      }
      case ColumnIndex.Anim_nr__group:
        return  this.animalNr + " (" + this.groupName + ")";
      case ColumnIndex.Lactation:
        return this.lactation;
      case ColumnIndex.Days_in_milk:
        return Cow.getTimeSince(this.lactationDay, true);
      case ColumnIndex.DIM__lact_:
        return Cow.getTimeSince(this.lactationDay, true) + " (" + this.lactation + ")";
      case ColumnIndex.Since_milked:
        return Cow.getTimeSince(this.lastMilkingTime);
      case ColumnIndex.Milk_perm_:
        return Cow.getTimeSince(this.timeToNextMilking);
      case ColumnIndex.OCC__last_2:
        return this.getPositivValue(this.occArray[0]) +  " (" + this.getPositivValue(this.occArray[1]) + "," + this.getPositivValue(this.occArray[2]) + ")";
      case ColumnIndex.Exp_yield:
        return this.getExpectedYield(false, massUnit);
      case ColumnIndex.SevenDays_milkings: //12
        return this.lastSevenDays/10;
      case ColumnIndex.SevenDays:          //13
        return this.lastSevenDays/10;      //Old MyFarm have code for trend arrow here as well, but it is not showing so I guess it's disabled somewhere (this.sumBitMask&3).
      case ColumnIndex.Blo_MDi_OCC:
      return this.cowHealth;
      case ColumnIndex.Incomplete:
        return this.isIncomplete();
      case ColumnIndex.Note:
        return this.notificationSigned;
      case ColumnIndex.Action:
        return this.getCowActionName();
      case ColumnIndex.Name:
        return this.animalName;
      case ColumnIndex.Anim_nr__name:
        return this.animalNr + " (" + this.animalName + ")";
      case ColumnIndex.Area:         5
        return this.areaName;
      case ColumnIndex.Time_in_area:
        return Cow.getTimeSince(this.inAreaSince);
      case ColumnIndex.Area__time: {
          return this.areaGUID ? (this.areaName + " (" + Cow.getTimeSince(this.inAreaSince, false, true) + ")") : "";
      }
      case ColumnIndex.Activity:
        return Cow.ActivityType[this.highActivity];
      case ColumnIndex.Milkings__day:
        return this.nrMilkingPerDay/10;
      case ColumnIndex.Trap_cow:
        return this.isTrapCow();
      case ColumnIndex.Trap_cow___:
        return this.trapString;
      case ColumnIndex.Exp__Calving:
        return formatDateOnly(this.expectedCalvingDate);
      case ColumnIndex.Last_Insemination:
        return formatDateOnly(this.lastInseminationDate);
      case ColumnIndex.Exp__Buildup:
        return formatDateOnly(this.expectedBuildupDate);
      case ColumnIndex.Exp__Dry_Off:
        return formatDateOnly(this.expectedDryOff);
      case ColumnIndex.Exp__Heat:
        return formatDateOnly(this.expectedHeatDate);
      case ColumnIndex.Exp__Insem_Due:
        return formatDateOnly(this.expectedInseminationDueDate);
      case ColumnIndex.Exp__Preg_Check:
        return formatDateOnly(this.expectedPregnancyCheck);
      case ColumnIndex.Last_Heat:
        return formatDateOnly(this.lastHeatDate);
      case ColumnIndex.SCC_Date:
        return formatDateOnly(this.latestSCCDate);
      case ColumnIndex.Breed:
        return codeSet["BreedTypes_" + this.breed];
      case ColumnIndex.Hours_Since_High_Activity:
        return this.hoursSinceHighActivity > 0 ? this.hoursSinceHighActivity : "";
      case ColumnIndex.Latest_SCC:
        return this.latestSCC > 0 ? this.latestSCC : "";
      case ColumnIndex.Is_Drying_Off:
        return this.isDryingOff;
      case ColumnIndex.To_Be_Culled:
        return this.toBeCulled;
      case ColumnIndex.Relative_Activity:
        return this.relativeActivity;
      case ColumnIndex.Reproduction_Status:
        return Cow.ReproductionStatusType[this.reproductionStatus];
      case ColumnIndex.General:
        return this.getAllNoteOfType(Cow.NoteEventType.General);
      case ColumnIndex.Reproduction:
        return this.getAllNoteOfType(Cow.NoteEventType.Reproduction);
      case ColumnIndex.Milking:
        return this.getAllNoteOfType(Cow.NoteEventType.Milking);
      case ColumnIndex.Feeding:
        return this.getAllNoteOfType(Cow.NoteEventType.Feeding);
      case ColumnIndex.Health:
        return this.getAllNoteOfType(Cow.NoteEventType.Health);
      case ColumnIndex.CowMonitor:
        return this.getAllNoteOfType(Cow.NoteEventType.CowMonitor);
      case ColumnIndex.Status_board:
        return this.getAllNoteOfType(Cow.NoteEventType.StatusBoard);
      case ColumnIndex.Prod_latest:
        return "50";                          //Does not return any value in 'old' MyFarm so I guess it's never used. TODO: make sure...
      case ColumnIndex.Milk_Dest:
        return this.animalMilkingDestination; //Does not return any value in 'old' MyFarm so I guess it's never used. TODO: make sure...
      case ColumnIndex.Days_to_dry_Off:
        return -Cow.getTimeSince(this.expectedDryOff, true);
      case ColumnIndex.Exp__Yield__value:
        //The 2 lines below are original MyFarm code for this column, but field totalYieldDiffPercent does not exist and it seems column is never used so just skipping it for now. TODO: make sure...
        // if (cow.totalYieldDiffPercent && cow.prev && (cow.prev > (new Date().getTime() - 129600000)))
				// 		element.text(Math.round(100+(cow.totalYieldDiffPercent / 10)));
        return "53";
      case ColumnIndex.Last_milking_Robot:
        return this.lastMilkingDevice;
      default:
        return "unknown column index";
    }
  }

  static sortCowForMilkingQ(cows, desc=true)
  {
    let sortCowQ = function(c1, c2) {
      if (!desc) [c1,c2] = [c2,c1];
      let status1 = c1.getCowQStatus();
      let status2 = c2.getCowQStatus();
      return status1 == status2 || status1>3 && status2>3 ? c2.timeToNextMilking-c1.timeToNextMilking : status1-status2; 
    }
    return cows.sort(sortCowQ);
  }

  static sortCows(columnIndex, cows, state, desc=true)
  {
    const sortNumbers = desc ? function(v1, v2) { return v2-v1; } : function (v1, v2) { return v1-v2; };
    const sortText = desc ? function(v1, v2) { v1=v1.toLowerCase(); v2=v2.toLowerCase();return v2>v1?-1:v2<v1?1:0; } : function (v1, v2) {v1=v1.toLowerCase(); v2=v2.toLowerCase();return v2<v1?-1:v2>v1?1:0; };
    switch (columnIndex) {
      case ColumnIndex.None:
        return cows;
      case ColumnIndex.Teat_status:
      return cows;
      case ColumnIndex.Animal_nr:
        return cows.sort((o1,o2) => sortNumbers(o1.animalNr, o2.animalNr));
      case ColumnIndex.Group__no:
        return cows.sort((o1,o2) => sortText(o1.groupName, o2.groupName));
      case ColumnIndex.Anim_nr__group:
        return cows.sort((o1,o2) => sortNumbers(o1.animalNr, o2.animalNr));
      case ColumnIndex.Lactation:
      return cows.sort((o1,o2) => sortNumbers(o1.lactation, o2.lactation));
      case ColumnIndex.Days_in_milk:
        return cows.sort((o1,o2) => sortNumbers(o2.lactationDay, o1.lactationDay));
      case ColumnIndex.DIM__lact_:
        return cows.sort((o1,o2) => sortNumbers(o2.lactationDay, o1.lactationDay));
      case ColumnIndex.Since_milked:
        return cows.sort((o1,o2) => sortNumbers(o1.lastMilkingTime, o2.lastMilkingTime));
      case ColumnIndex.Milk_perm_:
        return cows.sort((o1,o2) => sortNumbers(o1.timeToNextMilking, o2.timeToNextMilking));
      case ColumnIndex.OCC__last_2:
        return cows.sort((o1,o2) => sortNumbers(o1.occArray[0], o2.occArray[0]));
      case ColumnIndex.Exp_yield:
        return cows.sort((o1,o2) => sortNumbers(o1.getExpectedYield(true), o2.getExpectedYield(true)));
      case ColumnIndex.SevenDays_milkings:
        return cows.sort((o1,o2) => sortNumbers(o1.lastSevenDays, o2.lastSevenDays));
      case ColumnIndex.SevenDays:
        return cows.sort((o1,o2) => sortNumbers(o1.lastSevenDays, o2.lastSevenDays));
      case ColumnIndex.Blo_MDi_OCC:
      return cows;
      case ColumnIndex.Incomplete:
        return cows.sort((o1,o2) => sortText(o1.isIncomplete(), o2.isIncomplete()));
      case ColumnIndex.Note:
        return cows.sort((o1,o2) => sortText(o1.notificationSigned, o2.notificationSigned));
      case ColumnIndex.Action:
      return cows.sort((o1,o2) => sortNumbers(o1.action, o2.action));
      case ColumnIndex.Name:
      return cows.sort((o1,o2) => sortText(o1.animalName, o2.animalName));
      case ColumnIndex.Anim_nr__name:
        return cows.sort((o1,o2) => sortNumbers(o1.animalNr, o2.animalNr));
      case ColumnIndex.Area:
        return cows.sort((o1,o2) => sortText(o1.areaName, o2.areaName));
      case ColumnIndex.Time_in_area:
        return cows.sort((o1,o2) => sortNumbers(Cow.getTimeSince(o1.inAreaSince), Cow.getTimeSince(o2.inAreaSince)));
      case ColumnIndex.Area__time:
        return cows.sort((o1,o2) => sortText(o1.areaName, o2.areaName));
      case ColumnIndex.Activity:
        return cows.sort((o1,o2) => sortNumbers(o1.highActivitySortValue, o2.highActivitySortValue));
      case ColumnIndex.Milkings__day:
        return cows.sort((o1,o2) => sortNumbers(o1.nrMilkingPerDay, o2.nrMilkingPerDay));
      case ColumnIndex.Trap_cow:
        return cows.sort((o1,o2) => sortNumbers(o1.isTrapCow(), o2.isTrapCow()));
      case ColumnIndex.Trap_cow___:
        return cows.sort((o1,o2) => sortText(o1.trapString, o2.trapString));
      case ColumnIndex.Exp__Calving:
        return cows.sort((o1,o2) => sortNumbers(o1.expectedCalvingDate, o2.expectedCalvingDate));
      case ColumnIndex.Last_Insemination:
        return cows.sort((o1,o2) => sortNumbers(o1.lastInseminationDate, o2.lastInseminationDate));
      case ColumnIndex.Exp__Buildup:
        return cows.sort((o1,o2) => sortNumbers(o1.expectedBuildupDate, o2.expectedBuildupDate));
      case ColumnIndex.Exp__Dry_Off:
        return cows.sort((o1,o2) => sortNumbers(o1.expectedDryOff, o2.expectedDryOff));
      case ColumnIndex.Exp__Heat:
        return cows.sort((o1,o2) => sortNumbers(o1.expectedHeatDate, o2.expectedHeatDate));
      case ColumnIndex.Exp__Insem_Due:
        return cows.sort((o1,o2) => sortNumbers(o1.expectedInseminationDueDate, o2.expectedInseminationDueDate));
      case ColumnIndex.Exp__Preg_Check:
        return cows.sort((o1,o2) => sortNumbers(o1.expectedPregnancyCheck, o2.expectedPregnancyCheck));
      case ColumnIndex.Last_Heat:
        return cows.sort((o1,o2) => sortNumbers(o1.lastHeatDate, o2.lastHeatDate));
      case ColumnIndex.SCC_Date:
        return cows.sort((o1,o2) => sortNumbers(o1.latestSCCDate, o2.latestSCCDate));
      case ColumnIndex.Breed:
      return cows.sort((o1,o2) => sortNumbers(o1.breed, o2.breed));
      case ColumnIndex.Hours_Since_High_Activity:
        return cows.sort((o1,o2) => sortNumbers(o1.hoursSinceHighActivity, o2.hoursSinceHighActivity));
      case ColumnIndex.Latest_SCC:
        return cows.sort((o1,o2) => sortNumbers(o1.latestSCC, o2.latestSCC));
      case ColumnIndex.Is_Drying_Off:
        return cows.sort((o1,o2) => sortNumbers(o1.isDryingOff, o2.isDryingOff));
      case ColumnIndex.To_Be_Culled:
        return cows.sort((o1,o2) => sortNumbers(o1.toBeCulled, o2.toBeCulled));
      case ColumnIndex.Relative_Activity:
        return cows.sort((o1,o2) => sortNumbers(o1.relativeActivity, o2.relativeActivity));
      case ColumnIndex.Reproduction_Status:
        return cows.sort((o1,o2) => sortNumbers(o1.reproductionStatus, o2.reproductionStatus));
      case ColumnIndex.General:
        return cows.sort((o1,o2) => sortText(o1.getAllNoteOfType(Cow.NoteEventType.General), o2.getAllNoteOfType(Cow.NoteEventType.General)));
      case ColumnIndex.Reproduction:
        return cows.sort((o1,o2) => sortText(o1.getAllNoteOfType(Cow.NoteEventType.Reproduction), o2.getAllNoteOfType(Cow.NoteEventType.Reproduction)));
      case ColumnIndex.Milking:
        return cows.sort((o1,o2) => sortText(o1.getAllNoteOfType(Cow.NoteEventType.Milking), o2.getAllNoteOfType(Cow.NoteEventType.Milking)));
      case ColumnIndex.Feeding:
        return cows.sort((o1,o2) => sortText(o1.getAllNoteOfType(Cow.NoteEventType.Feeding), o2.getAllNoteOfType(Cow.NoteEventType.Feeding)));
      case ColumnIndex.Health:
        return cows.sort((o1,o2) => sortText(o1.getAllNoteOfType(Cow.NoteEventType.Health), o2.getAllNoteOfType(Cow.NoteEventType.Health)));
      case ColumnIndex.CowMonitor:
        return cows.sort((o1,o2) => sortText(o1.getAllNoteOfType(Cow.NoteEventType.CowMonitor), o2.getAllNoteOfType(Cow.NoteEventType.CowMonitor)));
      case ColumnIndex.Status_board:
        return cows.sort((o1,o2) => sortText(o1.getAllNoteOfType(Cow.NoteEventType.StatusBoard), o2.getAllNoteOfType(Cow.NoteEventType.StatusBoard)));
      case ColumnIndex.Prod_latest:
        return cows;
      case ColumnIndex.Milk_Dest:
        return cows.sort((o1,o2) => sortText(o1.animalMilkingDestination, o2.animalMilkingDestination));
      case ColumnIndex.Days_to_dry_Off:
        return cows.sort((o1,o2) => sortNumbers(o1.expectedDryOff, o2.expectedDryOff));
      case ColumnIndex.Exp__Yield__value:
        return cows.sort((o1,o2) => sortNumbers(o1.getExpectedYield(), o2.getExpectedYield()));
      case ColumnIndex.Last_milking_Robot:
        return cows.sort((o1,o2) => sortText(o1.lastMilkingDevice, o2.lastMilkingDevice));
      default:
        return cows;
    }
  }

}