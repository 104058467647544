<template>
  <v-card :style="{backgroundColor: '#f3f3f3'}" flat >
    <v-row no-gutters>
      <v-col class="ml-2">
        <v-btn :style="{backgroundColor: theme.button, marginTop: '-41px'}" small plain height="50px" width="40px" class="rounded-lg rounded-r-0" @click="changeMilkingIndex(-1)">
          <v-img :src="require('@/assets/arrows/left.png')"></v-img>
        </v-btn>
        <span class="fixTextLarge font-wight-bold truncate pl-1" :style="{backgroundColor: '#e5e5e5',textAlign: 'center', display: 'inline-block', width: 'calc(100% - 110px)', height: '50px', lineHeight: '49px'}"><b>{{ milkingEnd(true) }}</b> {{ milkingEnd(false) }}</span>
        <v-btn :style="{backgroundColor: theme.button, marginTop: '-41px'}" small plain height="50px" width="40px" class="rounded-lg rounded-l-0" @click="changeMilkingIndex(+1)">
          <v-img :src="require('@/assets/arrows/right.png')"></v-img>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="milking">

      <v-row no-gutters class="pl-3 pr-2">

        <v-col :cols="xs" :sm="sm" :style="{lineHeight: '16px'}">

          <span class="truncate l-info">{{$t('Latest 24h:')}}</span><span class="pr-5 truncate r-info">{{perfectPrecision(milking.prodPerDay)}}{{$uct("kg", "lb")}}</span>
          <span class="truncate l-info">{{$t('Total yield')}}</span><span class="pr-5 truncate r-info">{{perfectPrecision(milking.totalYield)}}{{$uct("kg", "lb")}}</span>
          <span class="truncate l-info">{{$t('Prod time')}}</span><span class="pr-5 truncate r-info">{{milkingInterval()}}</span>
          <span class="truncate l-info">{{$t('Percent of expected')}}</span><span class="pr-5 truncate r-info">{{perfectPrecision(100+milking.expectedYieldPercentDiff,1)}}</span>
          <span class="truncate l-info">{{$t('Robot')}}</span><span class="pr-5 truncate r-info">{{milking.robot}}</span>
          <span class="truncate l-info">{{$t('mdi')}}</span><span class="pr-5 truncate r-info">{{milking.mdi}}</span>
          <span class="truncate l-info">{{$t('Conductivity')}}</span><span class="pr-5 truncate r-info">{{conductivity()}}</span>
          <template v-if="milking.hasKickOff || milking.isIncomplete"> 
            <span class="truncate l-info">{{$t('Milking took')}}</span><span class="pr-5 truncate r-info">{{milkingTime()}}</span>          
          </template>

        </v-col>

        <v-col :cols="xs" :sm="sm" :style="{lineHeight: '16px'}">

          <template v-if="!milking.hasKickOff && !milking.isIncomplete"> 
            <span class="truncate l-info">{{$t('Milking took')}}</span><span class="pr-5 truncate r-info">{{milkingTime()}}</span>          
          </template>
          <span class="truncate l-info">{{$t('Milk dest')}}</span><span class="pr-5 truncate r-info">{{milking.milkDestination}}</span>
          <template v-if="milking.occ"> 
            <span class="truncate l-info">{{$t('OCC')}}</span><span class="pr-5 truncate r-info">{{milking.occ}}</span>
          </template>
          <template v-if="milking.isIncomplete"> 
            <span class="truncate l-info">{{$t('Incomplete')}}</span><span class="pr-5 truncate r-info">{{incompleteText()}}</span>
          </template>
          <template v-if="milking.hasKickOff"> 
            <span class="truncate l-info">{{$t('Kick off')}}</span><span class="pr-5 truncate r-info">{{kickOffText()}}</span>
          </template>
          <span class="truncate l-info">{{$t('Yield LF')}}</span><span class="pr-5 truncate r-info">{{ofExpected('LF')}}</span>
          <span class="truncate l-info">{{$t('Yield RF')}}</span><span class="pr-5 truncate r-info">{{ofExpected('RF')}}</span>
          <span class="truncate l-info">{{$t('Yield LR')}}</span><span class="pr-5 truncate r-info">{{ofExpected('LR')}}</span>
          <span class="truncate l-info">{{$t('Yield RR')}}</span><span class="pr-5 truncate r-info">{{ofExpected('RR')}}</span>
        
        </v-col>

      </v-row>
    </template>
  </v-card>

</template>

<script>
import Parse from "@/services/shared/Parse.js";

const teats = ['LF','RF','LR','RR'];

export default {
  name: "MilkingDetails",

  props: {
    milking: Object,
    prevMilking: Object,
    cow: Object,
  },
  data() {
    return {
      xs: 12,
      sm: 6,
      theme: this.$vuetify.theme.themes.light,
      portrait: this.$store.state.isPortraitMode,
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
  },
  methods: {
    incompleteText() {
      return this.incompletes.map( (k,index) => k ? teats[index] : '' ).join(' ');
    },
    kickOffText() {
      return this.kickOffs.map( (k,index) => k ? teats[index] : '' ).join(' ');
    },
    ofExpected(teat) {
      let totalYieldTeat = this.milking['totalYield' + teat]
      let expectedYieldTeat = this.milking['expectedYield' + teat]
      let unit = this.$uct("kg", "lb");
      return totalYieldTeat && expectedYieldTeat ? this.perfectPrecision(totalYieldTeat) + unit + ' (' + this.perfectPrecision((totalYieldTeat/expectedYieldTeat)*100,1) + '% of expected)' : totalYieldTeat + unit;
    },
    conductivity() {
      const conductivity = [this.milking.conductivityLF,this.milking.conductivityRF,this.milking.conductivityLR,this.milking.conductivityRR];
      let highest = 0;
      let maxIndex = conductivity.reduce((highestIndex, value, valueIndex) => value>highest? (highest = value,valueIndex) : highestIndex, -1);
      if (maxIndex > -1) {
        let text = teats[maxIndex] + ":" + conductivity[maxIndex];
        if (this.prevMilking) {
          const prevConductivity = [this.prevMilking.conductivityLF,this.prevMilking.conductivityRF,this.prevMilking.conductivityLR,this.prevMilking.conductivityRR];
          let delta = conductivity[maxIndex]-prevConductivity[maxIndex];
          if (delta != 0) {
            const prefix = delta > 0 ? "+" : "";
            text += ' (' + prefix + this.perfectPrecision(delta) + ')'
          }
        }
        return text;
      }
    },
    milkingInterval() {
      if (!this.prevMilking || !this.milking) {
        return "-";
      }
      let interval = Math.floor((this.milking.endOfMilkingTime - this.prevMilking.endOfMilkingTime)/1000);
      let h = Math.floor(interval / (60*60));
      let m = Math.floor((interval % (60*60))/60);
      return  (h ? h + "h" : "") + (m ? " " + m + "min" : "");
    },
    milkingTime() {
      let m = Math.floor(this.milking.secMilkingTime / 60);
      let s = this.milking.secMilkingTime % 60;
      return  (m ? m + "min" : "") + (s ? " " + s + "sec" : "");
    },
    perfectPrecision(value, limit=2) {
      return value && !isNaN(value) ? Parse.perfectPrecision(value, limit) : "";
    },
    changeMilkingIndex(offset) {
      this.$emit("changeMilkingIndex", offset);
    },
    milkingEnd(date = true) {
      if (!this.milking)
        return "";
      let d = new Date(this.milking.endOfMilkingTime)
      return date ? d.getFullYear() +'-'+ (d.getMonth()+1).toString().padStart(2,'0') + '-' + d.getDate().toString().padStart(2,'0')  :  d.getHours().toString().padStart(2,'0') + ':' + d.getMinutes().toString().padStart(2,'0') + ':' + d.getSeconds().toString().padStart(2,'0');
    },
  },
  computed: {
    incompletes: {
      get() {
        return [this.milking.incompleteLF,this.milking.incompleteRF,this.milking.incompleteLR,this.milking.incompleteRR];
      }
    },
    kickOffs: {
      get() {
        return [this.milking.kickOffLF,this.milking.kickOffRF,this.milking.kickOffLR,this.milking.kickOffRR];
      }
    }
  },
};
</script>
<style scoped>
.l-info {
  text-align: left;
  display: inline-block;
  width: 50%;
}
.r-info {
  text-align: left;
  display: inline-block;
  width: 50%;
  font-weight: bold;
}
</style>