<template>
  <v-card class="mt-4 pr-2 pt-2 overflow-y-auto mb-0" :style="{height: 'calc(100vh - 141px)', backgroundColor: '#ffffff'}" flat >

    <v-row class="mt-1 mb-1" v-if="teatFlags">
      <v-spacer />
      <!-- Kick off -->
      <v-col cols="auto" class="ml-2 mt-n2" v-if="(teatFlags & 2) == 2">
        <v-row no-gutters class="mb-1">
          <v-col class="mb-2" :style="{'margin-left': '-13px'}">
            <img :src="require('@/assets/cowq_top.png')" height="17px"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="1" class="ml-0 mt-n3">
            <img :src="cow.lastMilkingFlags.kickOffLF ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="22px" class="mb-0" />
            <img :src="cow.lastMilkingFlags.kickOffLR ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="22px"/>
          </v-col>
          <v-col cols="1" class="mt-n3" :style="{'margin-left': '22px'}">
            <img :src="cow.lastMilkingFlags.kickOffRF ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="22px"  class="mb-0" />
            <img :src="cow.lastMilkingFlags.kickOffRR ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="22px"/>
          </v-col>
        </v-row>
      </v-col>
      <!-- Incomplete -->
      <v-col cols="auto" class="ml-2 mt-n2" v-if="(teatFlags & 1) == 1">
        <v-row no-gutters class="mb-1">
          <v-col class="mb-2" :style="{'margin-left': '-13px'}">
            <img :src="require('@/assets/cowq_top.png')" height="17px"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="1" class="ml-0 mt-n3">
            <img :src="cow.lastMilkingFlags.incompleteLF ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="22px" class="mb-0" />
            <img :src="cow.lastMilkingFlags.incompleteLR ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="22px"/>
          </v-col>
          <v-col cols="1" class="mt-n3" :style="{'margin-left': '22px'}">
            <img :src="cow.lastMilkingFlags.incompleteRF ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="22px"  class="mb-0" />
            <img :src="cow.lastMilkingFlags.incompleteRR ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="22px"/>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <!-- Row #1 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Breed]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Breed, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Group__no]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Group__no, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>
    
    <!-- Row #2 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Lactation]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Lactation, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Days_in_milk]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Days_in_milk, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- Row #3 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.SevenDays_milkings]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.SevenDays_milkings, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Since_milked]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Since_milked, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- Row #4 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Milk_perm_]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Milk_perm_, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Exp_yield]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Exp_yield, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- Row #5 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Milkings__day]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Milkings__day, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Reproduction_Status]}}</span>
        <span class="pr-5 truncate r-info">{{$t('Reprod_' + cow.getColumnValue(column.Reproduction_Status, $store.state, codeSet, unit))}}</span>
      </v-col>
    </v-row>

    <!-- Row #6 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.To_Be_Culled]}}</span>
        <span class="pr-5 truncate r-info" :style="{marginTop: '-1px'}">
          <!-- <v-icon>mdi-check</v-icon> -->
          <v-simple-checkbox v-if="cow.getColumnValue(column.To_Be_Culled, $store.state, codeSet, unit)==1" v-model="checked" disabled />
        </span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Exp__Heat]}}</span>
        <span class="pr-5 truncate r-info" :style="{marginTop: '-1px'}">
          <v-simple-checkbox v-if="cow.getColumnValue(column.Exp__Heat, $store.state, codeSet, unit)==1" v-model="checked" disabled />
        </span>
      </v-col>
    </v-row>

    <!-- Row #7 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Last_Heat]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Last_Heat, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Last_Insemination]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Last_Insemination, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- Row #8 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Exp__Insem_Due]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Exp__Insem_Due, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Exp__Preg_Check]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Exp__Preg_Check, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- Row #9 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Exp__Dry_Off]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Exp__Dry_Off, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Is_Drying_Off]}}</span>
        <span class="pr-5 truncate r-info" :style="{marginTop: '-1px'}">
          <v-simple-checkbox v-if="cow.getColumnValue(column.Is_Drying_Off, $store.state, codeSet, unit)==1" v-model="checked" disabled />
        </span>
      </v-col>
    </v-row>

    <!-- Row #10 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Exp__Buildup]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Exp__Buildup, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Exp__Calving]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Exp__Calving, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>


    <!-- Row #11 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.OCC__last_2]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.OCC__last_2, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Blo_MDi_OCC]}}</span>
        <span class="pr-5 truncate r-info"><CowHealth :cowHealth="cow.cowHealth" /></span>
      </v-col>
    </v-row>

    <!-- Row #12 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.SCC_Date]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.SCC_Date, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Latest_SCC]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Latest_SCC, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- Row #13 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Activity]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Activity, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Hours_Since_High_Activity]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Hours_Since_High_Activity, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- Row #14 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Relative_Activity]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Relative_Activity, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Action]}}</span>
        <span class="pr-5 truncate r-info">{{actions[cow.action]}}</span>
      </v-col>
    </v-row>

    <!-- Row #15 -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" >
        <span class="truncate l-info">{{columnTitles[column.Area__time]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Area__time, $store.state, codeSet, unit)}}</span>
      </v-col>
      <v-col cols="12" sm="6">
        <span class="truncate l-info">{{columnTitles[column.Trap_cow___]}}</span>
        <span class="pr-5 truncate r-info">{{cow.getColumnValue(column.Trap_cow___, $store.state, codeSet, unit)}}</span>
      </v-col>
    </v-row>

    <!-- All notes -->
    <v-row no-gutters class="pl-2 pr-2">
      <v-col cols="12" sm="6" v-for="(note,index) in getAllNotes(cow)" :key="index + note.text" >
        <span class="mb-2" :style="{verticalAlign: 'top', textAlign: 'left', display: 'inline-block', width: '50%'}">{{note.title}}</span>
        <span class="pr-5" :style="{whiteSpace: 'normal', textAlign: 'left', display: 'inline-block', width: '50%'}" ><b>{{note.text}}</b><br>{{note.user}}, {{note.date}}</span>
      </v-col>
    </v-row>

  </v-card>

</template>

<script>
import {CodeSet} from "@/services/shared/CodeSet.js"
import {Cow} from "@/services/shared/Cow.js"
import {ColumnIndex} from "@/services/shared/Profile.js"
import CowHealth from "@/components/cowq/CowHealth.vue";

export default {
  name: "AnimalDetails",
  components: {
    CowHealth,
  },
  props: {
    cow: Object,
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      selectedAnimalNr: this.$route.params.animalNr,
      selectedAnimalIndex: -1,
      portrait: this.$store.state.isPortraitMode,
      column: ColumnIndex,
      noteColumns: [ColumnIndex.General, ColumnIndex.Reproduction, ColumnIndex.Milking, ColumnIndex.Feeding, ColumnIndex.Health, ColumnIndex.CowMonitor, ColumnIndex.Status_board],
      checked: true,
      columnTitles: this.$store.state.farmData.data.profiles.fieldNames,
      delProUsers: this.$store.state.farmData.data.cows.delProUsers,
      unit: this.$uct("kg", "lb"),
      actions: this.$t("Milk,FeedOnly,PassThrough,Unselected").split(','),
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
  },
  methods: {
    formatDateOnly(d) {
      if (d instanceof Date)
        return d.toISOString().slice(0, 10);
      return d;
    },
    getAllNotes(cow) {
      let notes = [];
      this.noteColumns.forEach( (columnIndex, noteTypeIndex) => {
        cow.notes[noteTypeIndex].forEach( note => {
          let user = this.delProUsers ? this.delProUsers[note.user].UserName :  "";
          notes.push( { title: this.columnTitles[columnIndex], text: unescape(note.comment), user, date: this.formatDateOnly(note.time) } );
        });
      });
      return notes;
    },
    changeAnimalIndex(offset) {
      if (this.selectedAnimalIndex+offset > -1 && this.selectedAnimalIndex+offset < this.$store.state.farmData.data.animals.length)
      {
        this.selectedAnimalIndex == -1 ? this.selectedAnimalIndex=1 : this.selectedAnimalIndex+= offset;
        this.$router.push('/cow/' + this.cow.animalNr + "/info");
      }
      else
        console.log("At end of animal list");
    },
    getRowClass: function () {
      let cowStatus = this.cow.getCowQStatus();
      let text = (cowStatus == Cow.CowQStatus.FeedOnly || 
                  cowStatus == Cow.CowQStatus.MilkOverdue ||
                  cowStatus == Cow.CowQStatus.MilkPermissionNeverMilked) ? " white--text" : " black--text";
      return Cow.CowQStatus.properties.names[cowStatus] + text;
    }
  },
  computed: {
    teatFlags: {
      get() {
        return (this.cow.lastMilkingFlags.incompleteLF || this.cow.lastMilkingFlags.incompleteRF || this.cow.lastMilkingFlags.incompleteLR || this.cow.lastMilkingFlags.incompleteRR ? 1 : 0) + 
               (this.cow.lastMilkingFlags.kickOffLF || this.cow.lastMilkingFlags.kickOffRF || this.cow.lastMilkingFlags.kickOffLR || this.cow.lastMilkingFlags.kickOffRR ? 2 : 0);
      } 
    },
    codeSet: {
      get() {
        return new CodeSet(this.$store.state.farmData.data.codeSetsSerialized);
      }
    },
  },
};
</script>
<style scoped>
.l-info {
  text-align: left;
  display: inline-block;
  width: 50%;
  border-bottom: solid 1px;
}
.r-info {
  text-align: left;
  display: inline-block;
  width: 50%;
  border-bottom: solid 1px;
  font-weight: bold;
}
</style>