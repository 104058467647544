<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row dense>
      <p class="category">{{ $t("from") }} {{ farmName }}</p>
    </v-row>

    <v-row dense>
      <v-divider class="mb-2" />
    </v-row>

    <v-row dense>
      <v-col cols="4">
        <v-row dense
          ><p>{{ $t("Stop alarms") }}</p></v-row
        >
        <v-row dense>
          <p>{{ $t("Notifications") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("Messages") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("Farm communication problems") }}</p>
        </v-row>
      </v-col>
      <v-col>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="alarm" hide-details /></v-row>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="notification" hide-details /></v-row>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="message" hide-details /></v-row>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="internet" hide-details /></v-row>
        <v-row dense>
          <p class="note">{{ $t("Subscribe") }}</p>
        </v-row>
      </v-col>
      <v-col v-if="quietTime">
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="alarmQuiet" hide-details /></v-row>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="notificationQuiet" hide-details /></v-row>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="messageQuiet" hide-details /></v-row>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="internetQuiet" hide-details /></v-row>
        <v-row dense>
          <p class="note">{{ $t("Not under quiet time") }}</p>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Farms",

  props: {
    farm: {
      type: Object,
      default: null,
    },
  },

  computed: {
    alarm: {
      get() {
        return this.farm.notifyFarmData.isStopAlarm;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_Alarm, { id: this.farm.domainId, value: value });
      },
    },

    notification: {
      get() {
        return this.farm.notifyFarmData.isNotificationAlarm;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_Notification, { id: this.farm.domainId, value: value });
      },
    },

    message: {
      get() {
        return this.farm.notifyFarmData.isChatMessageAlarm;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_Message, { id: this.farm.domainId, value: value });
      },
    },

    internet: {
      get() {
        return this.farm.notifyFarmData.isVcCommunicationProblemNotification;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_Internet, { id: this.farm.domainId, value: value });
      },
    },

    alarmQuiet: {
      get() {
        return this.farm.notifyFarmData.isStopAlarmQuiet;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_AlarmQuiet, { id: this.farm.domainId, value: value });
      },
    },

    notificationQuiet: {
      get() {
        return this.farm.notifyFarmData.isNotificationAlarmQuiet;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_NotificationQuiet, { id: this.farm.domainId, value: value });
      },
    },

    messageQuiet: {
      get() {
        return this.farm.notifyFarmData.isChatMessageAlarmQuiet;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_MessageQuiet, { id: this.farm.domainId, value: value });
      },
    },

    internetQuiet: {
      get() {
        return this.farm.notifyFarmData.isVcCommunicationProblemNotificationQuiet;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.farms_InternetQuiet, { id: this.farm.domainId, value: value });
      },
    },

    farmName: {
      get() {
        return this.farm.domainName;
      },
    },

    quietTime: {
      get() {
        if (this.$store.state.settings.config.data.deviceNotifications) return this.$store.state.settings.config.data.deviceNotifications.useQuietTime;
        return false;
      },
    },
  },
};
</script>

<style scoped>
.category {
  padding: 20px 0px 0px 0px;
  margin: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
.note {
  font-size: 0.8em;
}
p {
  margin: 0px;
  padding: 0px;
}
</style>