<template>
  <v-container>
    <v-row class="horizontal-line">
      <v-col class="vertical-line pr-0" cols="6">
        <v-card class="card-size overflow-hidden" flat @click="navigteTo('/production')">
          <Production :mode="'overview'" />
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="card-size overflow-hidden" flat @click="navigteTo('/cowq')">
          <CowQ :mode="'overview'" />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="vertical-line" cols="6">
        <v-card class="card-size overflow-hidden" flat @click="navigteTo('/chat')">
          <Chat />
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="card-size overflow-hidden" flat @click="navigteTo('/alarms')">
          <Alarms />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Chat from "@/views/Chat.vue";
import CowQ from "@/views/CowQ.vue";
import Alarms from "@/views/Alarms.vue";
import Production from "@/views/Production.vue";

export default {
  name: "Overview",

  components: {
    Chat,
    CowQ,
    Alarms,
    Production,
  },

  methods: {
    navigteTo: function (target) {
      this.$router.push(target);
    },
  },
};
</script>

<style scoped>
.horizontal-line {
  border-bottom-style: solid;
  border-color: #cccccc;
  border-width: 1px;
}
.vertical-line {
  border-right-style: solid;
  border-color: #cccccc;
  border-width: 1px;
}
.card-size {
  height: calc(50vh - 56px);
  background: #e5e5e5;
}
</style>