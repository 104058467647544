<template>

  <v-row class="pt-0" no-gutters :style="{backgroundColor: background}"> 
    
    <!-- col ONE udder circles to show incomplete and crosses to show kickoff -->

    <v-col v-if="isIncomplete" cols="auto" class="ml-0 mt-n1 mr-n6">
        <v-row no-gutters >
          <v-col class="mb-n3" :style="{'margin-left': '3px'}">
            <img :src="require('@/assets/cowq_top.png')" height="7px"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="1" class="ml-2 mt-n3">
            <img :src="milking.incompleteLF ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="10px" class="mb-n3" />
            <img :src="milking.incompleteLR ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="10px"/>
          </v-col>
          <v-col cols="1" class="mt-n3" :style="{'margin-left': '7px'}">
            <img :src="milking.incompleteRF ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="10px"  class="mb-n3" />
            <img :src="milking.incompleteRR ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="10px"/>
          </v-col>
        </v-row>
    </v-col>
    <v-col v-else cols="auto">
      <span :style="{display: 'inline-block', width: '31px', height: '31px'}"></span>
    </v-col>

    <v-col v-if="hasKickOff" cols="auto" class="ml-0 mt-n1 mr-n3">
        <v-row no-gutters >
          <v-col class="mb-n3" :style="{'margin-left': '3px'}">
            <img :src="require('@/assets/cowq_top.png')" height="7px"/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="1" class="ml-2 mt-n3">
            <img :src="milking.kickOffLF ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="10px" class="mb-n3" />
            <img :src="milking.kickOffLR ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="10px"/>
          </v-col>
          <v-col cols="1" class="mt-n3" :style="{'margin-left': '7px'}">
            <img :src="milking.kickOffRF ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="10px"  class="mb-n3" />
            <img :src="milking.kickOffRR ? require('@/assets/kickoff.png') : require('@/assets/cowq_white.png')" width="10px"/>
          </v-col>
        </v-row>
    </v-col>
    <v-col v-else cols="auto" class="mt-n2 mr-n3">
      <span :style="{display: 'inline-block', width: '55px'}"></span>
    </v-col>

    <!-- the SIX columns -->
    <v-col>
      <v-row no-gutters :class="{'mt-2': titleRows==1}">

        <!-- Col #1 -->
        <v-col md="2" sm="4" cols="6" class="truncate">
          {{milkingEnd(milking)}}
        </v-col>

        <!-- Col #2 -->
        <v-col md="2" sm="4" cols="6" class="truncate">
          {{milkingDuration(milking)}}
        </v-col>

        <!-- Col #3 -->
        <v-col md="2" sm="4" cols="6" class="truncate">
          {{milking.occ != -1 ? milking.occ : ""}}
        </v-col>

        <!-- Col #4 -->
        <v-col md="2" sm="4" cols="6" class="truncate">
          {{perfectPrecision(milking.totalYield)}} ({{perfectPrecision(milking.prodPerDay)}})
        </v-col>

        <!-- Col #5 -->
        <v-col md="2" sm="4" cols="6" class="truncate">
          {{perfectPrecision(milking.averageFlow/1000,1)}}
        </v-col>

        <!-- Col #6 -->
        <v-col md="2" sm="4" cols="6" class="truncate">
          {{milking.milkDestination}}
        </v-col>

      </v-row>
    </v-col>

  </v-row>

</template>

<script>
import Parse from "@/services/shared/Parse.js";

export default {
  name: "MilkingRow",

  components: {
  },
  props: {
    milking: Object,
    background: { type: String, default: "gray"},
  },
  data() {
    return {

    };
  },
  methods: {
    perfectPrecision(value, limit=2) {
      return value && !isNaN(value) ? Parse.perfectPrecision(value, limit) : "";
    },
    milkingEnd(milking) {
      let d = new Date(milking.endOfMilkingTime)
      return d.getDate() + "/" + (d.getMonth()+1) + " - " + d.getHours() + ":" + d.getMinutes();
    },
    milkingDuration(milking) {
      let m = parseInt(milking.secMilkingTime/60);
      let s = milking.secMilkingTime%60;
      return m.toString().padStart(2,"0") + ":" + s.toString().padStart(2,"0");
    }
  },
  computed: {
    titleRows: {
      get() {
        let size = this.$vuetify.breakpoint.name;
        return size=='sm' ? 2 : size=='xs' ? 3 : 1;
      }
    },
    isIncomplete: {
      get() {
        return this.milking.flags & 0x5555;
      }
    },
    hasKickOff: {
      get() {
        return this.milking.flags & 0x2222;
      }
    },
  },
};
</script>
<style scoped>
</style>