<template>
  <v-dialog v-model="show" persistent max-width="80%">
    <Loader v-if="showLoader" />
    <v-card v-else>
      <v-card-title>
        <p class="ma-0 mp-0">{{ $t("Delete") }}</p>
      </v-card-title>
      <v-card-text>
        <p class="ma-0 mp-0">{{ $t("Are you sure you want to delete user: ") }}</p>
        <p class="ma-0 mt-2 mp-0">{{ name }}</p>
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-1">
          <v-col class="ma-0 pa-0 pr-1" cols="6">
            <v-btn block @click="no()">{{ $t("No") }}</v-btn>
          </v-col>
          <v-col class="ma-0 pa-0 pl-1" cols="6">
            <v-btn block @click="yes()">{{ $t("Yes") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataSvc from "@/services/DataService.js";
import LoginSvc from "@/services/LoginService.js";
import Loader from "@/views/Loader.vue";
const Axios = require("axios");
Axios.defaults.withCredentials = true;

export default {
  name: "DeleteUser",

  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  components: {
    Loader,
  },

  data() {
    return {
      show: true,
      showLoader: false,
    };
  },

  methods: {
    no() {
      console.log("deleteUser: no");
      this.$emit("closed");
      if (this.user.id === this.$store.state.settings.config.data.id) {
        console.warn("deleteUser: Current user deleted, will logout");
        LoginSvc.logout();
      }
    },

    yes() {
      console.log("deleteUser: yes");
      // TODO: Seems like deleted user still is at server, as inactive. Is this correct?
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/Users/delete";
      Axios.post(url, '"' + this.$store.state.selectedFarm.id + "," + this.user.id + '"')
        .then((res) => {
          console.log("deleteUser: res=", res);
          if (this.user.id === this.$store.state.settings.config.data.id) {
            console.warn("deleteUser: Current user deleted, will logout");
            LoginSvc.logout();
          } else {
            this.showLoader = true;
            setTimeout(this.reloadUserSettings, 4000); // TODO: Need to wait before ask for all settings... sometimes 4s is not enough!
          }
        })
        .catch((err) => console.error(err));
    },

    reloadUserSettings() {
      console.log("deleteUser: reloadUserSettings");
      DataSvc.reloadUserSettings();
      this.showLoader = false;
      this.$emit("closed");
    },
  },

  computed: {
    name: {
      get() {
        return this.user.firstName + " " + this.user.lastName;
      },
    },
  },
};
</script>

<style scoped>
</style>