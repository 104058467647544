<template>
  <v-container fluid class="pl-2 pt-2 mb-0 pb-0">

      <v-btn :style="{backgroundColor: theme.button, marginTop: '-41px'}" small plain height="50px" width="40px" class="rounded-lg rounded-r-0" @click="changeAnimalIndex(-1)">
        <v-img :src="require('@/assets/arrows/left.png')"></v-img>
      </v-btn>
      <span class="fixTextLarge font-wight-bold truncate pl-1" :class="getRowClass()" :style="{textAlign: 'center', display: 'inline-block', width: 'calc(100% - 160px)', height: '50px', lineHeight: '49px'}"><b>{{ animalDisplay }}</b></span>
      <v-btn :style="{backgroundColor: theme.button, marginTop: '-41px'}" small plain height="50px" width="40px" class="rounded-lg rounded-l-0" @click="changeAnimalIndex(+1)">
        <v-img :src="require('@/assets/arrows/right.png')"></v-img>
      </v-btn>

      <v-btn v-if="component == 'info'" :style="{backgroundColor: theme.button, marginTop: '-41px'}" small plain height="50px" width="40px" class="rounded-lg ml-2" @click="showComponent('milkings')">
        <v-img :src="require('@/assets/cow-milkings.png')"></v-img>
      </v-btn>
      <v-btn v-if="component == 'milkings'" :style="{backgroundColor: theme.button, marginTop: '-41px'}" small plain height="50px" width="40px" class="rounded-lg ml-2" @click="showComponent('graph')">
        <v-img :src="require('@/assets/cow-graph.png')"></v-img>
      </v-btn>
      <v-btn v-if="component == 'graph'" :style="{backgroundColor: theme.button, marginTop: '-41px'}" small plain height="50px" width="40px" class="rounded-lg ml-2" @click="showComponent('info')">
        <CowHeadIcon height="40px" :fill="'white'"></CowHeadIcon>
      </v-btn>

      <AnimalDetails v-if="component == 'info' && cow" :cow="cow"></AnimalDetails>
      <MilkingList v-else-if="component == 'milkings' && !isLoading && cow" :cow="cow" :animalAndMilkData="animalAndMilkData"></MilkingList>
      <MilkingGraph v-else-if="component == 'graph' && !isLoading && cow" :cow="cow" :animalAndMilkData="animalAndMilkData"></MilkingGraph>
      <Loader v-else-if="isLoading && cow" />
      
  </v-container>
</template>

<script>
import {Cow} from "@/services/shared/Cow.js"
import MilkData from "@/services/shared/MilkData.js"
import AnimalDetails from "@/components/milkings/AnimalDetails.vue";
import MilkingList from "@/components/milkings/MilkingList.vue";
import MilkingGraph from "@/components/milkings/MilkingGraph.vue";
import Loader from "@/views/Loader.vue";
import CowHeadIcon from "@/assets/cowhead.svg";
import {ColumnIndex} from "@/services/shared/Profile.js"

export default {
  name: "Milkings",

  components: {
    AnimalDetails,
    MilkingList,
    MilkingGraph,
    Loader,
    CowHeadIcon,
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      selectedAnimalNr: this.$route.params.animalNr,
      selectedAnimalIndex: -1,
      portrait: this.$store.state.isPortraitMode,
      milkData: {},
      isLoading: true,
      component: this.$route.params.component,
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
  },
  beforeRouteUpdate (to, from, next) {
    this.selectedAnimalNr = to.params.animalNr,
    this.selectedAnimalIndex = -1,
    this.component = to.params.component,
  next();
},
  methods: {
    showComponent(name) {
      if (this.component != name) {
        this.component = name;
        this.$router.push('/cow/' + this.$route.params.animalNr + '/' + this.component);
      }
    },
    initMilkingData()
    {
      MilkData.getCowDataNew(this.$store, this.$store.state.selectedFarm.id, this.$store.getters.useImperialUnits).then(data => {
        this.milkData = data;
        this.isLoading = false;
      });
    },
    changeAnimalIndex(offset)
    {
      if (this.selectedAnimalIndex == -1) {
        console.log("changeAnimalIndex NOT SET")
        let index = this.sortedCows.findIndex(a => a.animalNr == this.selectedAnimalNr);
        this.selectedAnimalIndex = index > -1 ? index : 0;
        console.log("changeAnimalIndex SET to " + index)
      }
      let newIndex = this.selectedAnimalIndex + offset < 0 ? 0 : this.selectedAnimalIndex + offset;
      let animal = this.sortedCows[newIndex];
      if (animal) {
        this.selectedAnimalIndex = newIndex;
      } else {
        return;
      }
      if (this.$route.params.animalNr != animal.animalNr) {
        this.$router.push('/cow/' + animal.animalNr + "/" + this.component);
        this.selectedAnimalNr = animal.animalNr;
      }
    },
    getRowClass: function () {
      let cowStatus = this.cow ? this.cow.getCowQStatus() : Cow.CowQStatus.MilkPermissionNeverMilked;
      let text = (cowStatus == Cow.CowQStatus.FeedOnly || 
                  cowStatus == Cow.CowQStatus.MilkOverdue ||
                  cowStatus == Cow.CowQStatus.MilkPermissionNeverMilked) ? " white--text" : " black--text";
      return Cow.CowQStatus.properties.names[cowStatus] + text;
    },
  },
  computed: {
    animalDisplay: {
      get() {
        return this.cow ? this.cow.animalNr + ' - ' + this.cow.animalName : ' - ';
      }
    },
    cow: {
      get() {
        return this.$store.state.farmData.data.animals.find(a => a.animalNr == this.selectedAnimalNr);
      }
    },
    animalAndMilkData: {
      get() {
        if (!this.milkData || !this.milkData.animalsFromNumber || !this.milkData.animalsFromNumber[this.cow.animalNr])
        {
          return null;
        }
        return this.milkData.animalsFromNumber[this.cow.animalNr];
      }
    },

    sortedCows: {
      get() {
        let profile = this.$store.getters.getSelectedOrDefaultProfile;
        let sortDesc = !!+localStorage.getItem("cowq.sort." + profile.name + ".sortDesc");
        let sortCol = localStorage.getItem("cowq.sort." + profile.name + ".sortCol") || 0;
        let selectedGroup = localStorage.getItem("cowq.selectedGroup") || -1;
        let cowsFiltered = profile.filterCows(this.$store.state.farmData.data.animals, selectedGroup);
        if (sortCol == -ColumnIndex.Since_milked) { //Special milking que sorting 
          return Cow.sortCowForMilkingQ(cowsFiltered, sortDesc);
        }
        return Cow.sortCows(this.sortCol, cowsFiltered, this.$store.state, sortDesc);
      }
    },
  },
  mounted() {
    this.initMilkingData();
  },
};
</script>
<style scoped>

</style>