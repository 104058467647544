<template>
  <div>Version</div>
</template>

<script>
// TODO: Do we need a version page?
export default {};
</script>

<style scoped>
</style>