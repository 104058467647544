<template>
  <v-form :style="{ background: '#ffffff' }" ref="form" v-model="valid" lazy-validation>
    <Loader v-if="showLoader" />
    <v-container v-else class="ma-0 pa-5" fluid>
      <v-row>
        <p class="headline mb-4">{{ $t("End User License Agreement (EULA) for the service DeLaval MyFarm has been updated") }}</p>
        {{ $t("Accept updated End User License Agreement for user") }} {{ user }} {{ $t("to start/continue using the service") }}
      </v-row>
      <v-row class="mt-6"> {{ $t("Check") }} {{ $t("below to accept") }} </v-row>
      <v-row> <v-checkbox class="ma-0 pa-0" :rules="requiredRules" hide-details /><a href="https://myfarm.delaval.com/Delaval/info/Legal/delaval-software-and-online-service-data-and-privacy-statement.htm">DeLaval Software and Online Service Terms of Use</a> </v-row>
      <v-row> <v-checkbox class="ma-0 pa-0" :rules="requiredRules" hide-details /><a href="https://myfarm.delaval.com/Delaval/info/Legal/delaval-software-and-online-service-terms-of-use.htm">DeLaval Software and Online Service Data and Privacy Statement</a> </v-row>
      <v-row class="mt-10 mb-2">
        <v-btn :disabled="!(valid && show)" block @click="accept()">
          <!-- TODO: Fix word wrap in button... -->
          <p class="eula">{{ $t("I have read and accepted the end user license agreement (EULA)") }}</p>
        </v-btn>
      </v-row>
      <v-row>
        <v-btn :disabled="!show" block @click="logout()">{{ $t("Logout") }}</v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
const Axios = require("axios");
Axios.defaults.withCredentials = true;
import Loader from "@/views/Loader.vue";
import Names from "@/services/Names";
import LoginSvc from "@/services/LoginService.js";

export default {
  name: "ApproveEula",

  props: {
    email: {
      type: String,
      default: "",
    },

    password: {
      type: String,
      default: "",
    },
  },

  components: {
    Loader,
  },

  data() {
    return {
      valid: false,
      show: true,
      requiredRules: [(v) => !!v || false],
      user: this.$store.state.auth.login.email,
      showLoader: false,
    };
  },

  mounted() {
    this.$refs.form.validate();
    this.show = true;
  },

  methods: {
    accept() {
      console.log("EULA: accept");
      this.show = false;
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvUser/acceptUserEula";
      Axios.post(url, '"' + this.$store.state.settings.config.data.id + '"')
        .then((res) => {
          console.log("EULA: will try login res=", res);
          this.showLoader = true;
          setTimeout(this.login, 4000); // TODO: Need to wait before login... is 4s always enough?
        })
        .catch((err) => console.error(err));
    },

    login() {
      console.log("EULA: try login");
      this.$store.commit(Names.triggerLogin, { email: this.email, password: this.password });
      this.$store.state.auth.acceptEula = false;
      this.showLoader = false;
      this.$refs.form.reset();
    },

    logout() {
      console.warn("EULA: will logout user");
      this.$store.state.auth.acceptEula = false;
      LoginSvc.logout();
    },
  },
};
</script>

<style scoped>
.headline {
  margin: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
.eula {
  margin: 0px;
  font-weight: bold;
  font-size: 0.8em;
}
</style>