<template>
  <v-container class="mt-0 pa-0" fluid>
    <v-row dense>
      <v-col cols="4">
        <v-row dense class="pt-2">
          <p :style="text">{{ $t("Phone") }}</p>
        </v-row>
      </v-col>
      <v-col>
        <v-row dense class="mb-1">
          <v-text-field v-model="device" :rules="requiredRules" :disabled="deleteDevice" outlined dense hide-details :background-color="theme.settings_bgWhite" />
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mb-5" dense>
      <v-col cols="4">
        <v-row dense>
          <p :style="text">{{ $t("Recieve notifications") }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ $t("Type") }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ $t("Created") }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ $t("Last contact") }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ $t("App version") }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ $t("OS version") }}</p>
        </v-row>
      </v-col>
      <v-col>
        <v-row dense><v-checkbox class="ma-0 pa-0" v-model="notification" :disabled="deleteDevice" hide-details /></v-row>
        <v-row dense>
          <p :style="text">{{ phone.type }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ getTimeStamp(phone.cre) }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ getTimeStamp(phone.last) }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ phone.appVer }}</p>
        </v-row>
        <v-row dense>
          <p :style="text">{{ phone.osVer }}</p>
        </v-row>
      </v-col>
      <v-col cols="auto">
        <v-row dense class="mb-2">
          <!-- TODO: Fix disabled style! -->
          <v-btn :disabled="deleteDevice" color="button" dark width="200" @click="testSend()">{{ $t("Test") }}</v-btn>
        </v-row>
        <v-row dense>
          <v-switch v-model="deleteDevice" :label="$t('Delete')" />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const Axios = require("axios");
Axios.defaults.withCredentials = true;

export default {
  name: "Devices",

  props: {
    phone: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      requiredRules: [(v) => !!v || false],
    };
  },

  computed: {
    device: {
      get() {
        return this.phone.name;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.devices_Name, { id: this.phone.id, value: value });
      },
    },

    notification: {
      get() {
        return this.phone.notification;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.devices_Notification, { id: this.phone.id, value: value });
      },
    },

    deleteDevice: {
      get() {
        return this.phone.delete;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.devices_Delete, { id: this.phone.id, value: value });
      },
    },

    text: {
      get() {
        // TODO: How to do this using ccs variables?
        if (this.deleteDevice) return { color: this.theme.chat_textGray };
        else return { color: this.theme.chat_textBlack };
      },
    },
  },

  methods: {
    testSend() {
      console.log("testSend: call");
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvUser/testSend";
      Axios.post(url, JSON.stringify(this.phone.id))
        .then((res) => {
          console.log("testSend: res=", res);
          if (res.data == true) {
            console.log("testSend: success");
          }
        })
        .catch((err) => console.error(err));
    },

    getTimeStamp(time_ms) {
      let date = new Date(time_ms);
      return date.toLocaleString("sv-SE", {
        timeStyle: "short",
        dateStyle: "short",
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0px;
  padding: 0px;
}
</style>