import Store from "@/store/index";
import Names from "@/services/Names";

// Like: s=d43fc52f-1cbc-40d4-b5f6-883d923ff38aGurkfisk22 => h=-212297996
function getPwdHash(s) {
  let h = 0;
  let i = -1;
  while (++i < s.length) {
    h = (h << 5) - h + s.charCodeAt(i);
    h = h & h;
  }
  return h;
}

export default {
  sortText(v1, v2) {
    v1 = v1.toLowerCase();
    v2 = v2.toLowerCase();
    return v2 > v1 ? -1 : v2 < v1 ? 1 : 0;
  },

  getPwdId(seed, pwd) {
    return getPwdHash(seed + pwd);
  },
};
