<template>
  <v-card class="mt-4 pr-2 pt-2 overflow-y-auto mb-0" :style="{height: 'calc(100vh - 141px)', backgroundColor: '#f3f3f3'}" flat >
    
      <v-row no-gutters :style="chartStyle()">
      <v-col>
        <canvas id="theChartElementId" />
      </v-col>
    </v-row>

    <MilkingDetails :cow="cow" :animalAndMilkData="animalAndMilkData" :prevMilking="prevMilking" :milking="currentMilking" @changeMilkingIndex="changeMilkingIndex"/>

  </v-card>
</template>

<script>
import Chart from 'chart.js';
import MilkingDetails from "@/components/milkings/MilkingDetails.vue";
import Parse from "@/services/shared/Parse.js"

export default {
  name: "MilkingGraph",

  components: {
    MilkingDetails,
  },
  props: {
    animalAndMilkData: Object,
    cow: Object,
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      portrait: this.$store.state.isPortraitMode,
      ctx: {},
      chart: false,
      config: {},
      selectedMilkingIndex: -1,
      ProdsPerDayDataSetIndex: -1,
      DailyProdDataSetIndex: -1,
      OccDataSetIndex: -1,
      color: {incomplete: '#f60007ff' , kickoff: '#f6ca07ff', blue: '#3333aaaa' },
      lastChartHeight: 0,
      monthNames: this.$t("January February March April May June July August September October November December").split(" "),
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
    "animalAndMilkData": function(currentValue, previousValue) {
      this.selectedMilkingIndex = this.animalAndMilkData && this.animalAndMilkData.milkings ? this.animalAndMilkData.milkings.length-1 : -1;
      this.renderChart();
    },
    "selectedMilkingIndex": function(currentValue, previousValue) {
      if (currentValue == -1)
        return;
      this.setFocusChart(currentValue);
    },
  },
  methods: {
    chartStyle() {
        let chartHeight =  this.$vuetify.breakpoint.name == 'xs' ?  500 : 400;
        if (this.lastChartHeight && chartHeight != this.lastChartHeight) {
          setTimeout(this.renderChart, 250);
        }
        this.lastChartHeight = chartHeight;
        return {height: 'calc(100vh - ' + chartHeight + 'px)', overflow: 'hidden'};
    },
    changeMilkingIndex(offset) {
      this.selectedMilkingIndex = this.selectedMilkingIndex + offset < 0 ? 0 : this.selectedMilkingIndex + offset < this.milkings.length ? this.selectedMilkingIndex + offset : this.milkings.length-1;
    },
    chartClicked(e) {
      let dse = this.chart.getDatasetAtEvent(e);
      if (!dse.length) { 
        return; 
      }

      if (dse[0]._datasetIndex < this.ProdsPerDayDataSetIndex) {          //Milking bar was clicked
        this.selectedMilkingIndex = dse[0]._datasetIndex;
      } else if (dse[0]._datasetIndex == this.ProdsPerDayDataSetIndex) {  //Daily prod was clicked
        this.chart.getElementsAtEvent(e).find( v => v._datasetIndex == this.ProdsPerDayDataSetIndex ? (this.selectedMilkingIndex=v._index, true) : false);
      }
    },
    setFocusChart(index) {
      this.config.data.datasets.forEach( (ds,dsIndex) => dsIndex == index && dsIndex < this.ProdsPerDayDataSetIndex ? (ds.backgroundColor = this.getMilkingColor(index),ds.hoverBackgroundColor = this.getMilkingColor(index)) : (ds.backgroundColor = "#00000055",ds.hoverBackgroundColor = "#3333ffaa") );
      this.config.data.datasets[this.ProdsPerDayDataSetIndex].pointRadius = this.config.data.datasets[this.ProdsPerDayDataSetIndex].pointRadius.map( (pr, prIndex) => index == prIndex ? 4 : 2);
      this.config.data.datasets[this.ProdsPerDayDataSetIndex].hoverRadius = this.config.data.datasets[this.ProdsPerDayDataSetIndex].hoverRadius.map( (pr, prIndex) => index == prIndex ? 5 : 2);
      this.chart.update();
    },
    getMilkingColor(index) {
      return this.milkings[index].isIncomplete ? this.color.incomplete : this.milkings[index].hasKickOff ? this.color.kickoff : this.color.blue;
    },
    renderChart() {
      if (!this.animalAndMilkData)
      {
        if (this.chart)
        this.chart.destroy();
        return;
      }
      let oneDay = 1000*60*60*24;
      let startTime = this.animalAndMilkData.production.startTime;
      let endTime = this.animalAndMilkData.production.endTime + oneDay;
      let prevTime = startTime;
      let allDatasets = this.milkings.map( (m, index) => {
        if (index == 0 || m.endOfMilkingTime - prevTime > oneDay)
          prevTime = m.endOfMilkingTime - oneDay;
        let ds = {
          type: "line",
          borderColor: '#000000ff',
          borderWidth: 1,
          backgroundColor: '#00000055',
          hoverBackgroundColor: '#3333ffaa',
          hitRadius: 10,
          steppedLine: true,
          // pointRadius: [0,0,0,3,0],
          pointRadius: 0,
          hoverRadius: 0,
          // pointStyle: 'rectRot',
          order: 4,
          data : [{x: prevTime, y: 0},{x: prevTime, y: m.totalYield},{x: (m.endOfMilkingTime + prevTime)/2, y: m.totalYield},{x: m.endOfMilkingTime, y: m.totalYield},{x: m.endOfMilkingTime, y: 0}],
        }

        prevTime = m.endOfMilkingTime;
        return ds;
      });

      let dsProdsPerDay = {
        type: "line",
        borderColor: '#3333aaaa',
        hoverBorderColor: '#3333aaaa',
        borderWidth: 1,
        hoverBorderWidth: 1,
        backgroundColor: '#ffffff00',
        hoverBackgroundColor: this.milkings.map( m => m.isIncomplete ? '#f60007ff' : m.hasKickOff ? '#f6ca07ff' : '#3333aaaa'),
        pointBackgroundColor: this.milkings.map( m => m.isIncomplete ? '#f60007ff' : m.hasKickOff ? '#f6ca07ff' : '#3333aaaa'),
        hitRadius: 10,
        steppedLine: false,
        pointRadius: this.milkings.map( m => 2),
        hoverRadius: this.milkings.map( m => 2),
        tension:0,
        order: 2,
        fill: false,
        data: this.milkings.map( m => ({x:m.endOfMilkingTime, y:m.prodPerDay}))
      }
      allDatasets.push(dsProdsPerDay);
      this.ProdsPerDayDataSetIndex = allDatasets.length - 1;

      let dsLastDays = {
          type: "line",
          borderColor: '#000000f0',
          hoverBorderColor: '#000000f0',
          borderWidth: 4,
          hoverBorderWidth: 4,
          hitRadius: 0,
          steppedLine: false,
          pointRadius: 0,
          hoverRadius: 0,
          tension:0,
          order: 3,
          fill: false,
          data: [{x: startTime, y: this.animalAndMilkData.production.prod7d}, {x: endTime, y: this.animalAndMilkData.production.prod7d}]
        }
      allDatasets.push(dsLastDays);
      this.LastDaysDataSetIndex = allDatasets.length-1;

      let occMilkings = this.milkings.filter(m => m.occ && m.occ > 0);
      let occWarning = this.$store.state.farmData.data.cows.cellsWarning
      let occAlarm = this.$store.state.farmData.data.cows.cellsAlarm
      let occMax = 1;
      let occColorArray = occMilkings.map( m => ( (m.occ > occMax ? occMax=m.occ :''), (m.occ >= occAlarm ? '#f00' : m.occ >= occWarning ? '#ff0' : '#0f0')));
      let occFactor = ((occMax / 20)) / this.$ucmi(1 , Parse.lbPerKg)
      let dsOcc = {
          type: "line",
          borderColor: '#ee3333f0',
          hoverBorderColor: '#ee3333f0',
          pointBackgroundColor: occColorArray,
          pointHoverBackgroundColor: occColorArray,
          pointBorderColor: '#000',
          pointHoverBorderColor: '#000',
          borderWidth: 1,
          hoverBorderWidth: 1,
          hitRadius: 0,
          steppedLine: false,
          pointRadius: 4,
          hoverRadius: 4,
          tension:0,
          order: 1,
          fill: false,
          data: occMilkings.map( m => ({x:m.endOfMilkingTime, y:m.occ/occFactor}))
        }
      allDatasets.push(dsOcc);
      this.OccDataSetIndex = allDatasets.length-1;

      let dsDailyProd = {
        type: "line",
        borderColor: '#099FF4aa',
        hoverBorderColor: '#099FF4aa',
        borderWidth: 4,
        hoverBorderWidth: 4,
        backgroundColor: '#ffffff00',
        hoverBackgroundColor: '#3333aaaa',
        pointBackgroundColor: '#3333aaaa',
        hitRadius: 10,
        steppedLine: false,
        pointRadius: 0,
        hoverRadius: 0,
        tension: 0,
        order: 2,
        fill: false,
        data: this.animalAndMilkData.production.days.map( (d, index) => ({x: startTime + index*oneDay + oneDay/2, y: d.prodPerDay})),
      }
      allDatasets.push(dsDailyProd);
      this.DailyProdDataSetIndex = allDatasets.length-1;

      let monthAxesInfo = {months: this.monthNames, done: false};
      let options = {
        responsive: true,
        maintainAspectRatio: false,
        hover: { mode: 'dataset' },
        interaction: { mode: 'nearest' },
        tooltips: { enabled: false },
        onClick: this.chartClicked,
        legend: { display: false },
        scales: {
          yAxes: [{
            id: 'y',
            scaleId: 'y',
            type: 'linear',
            position: 'left',
            ticks: { max: this.$ucmi(70,140), min: 0 }
          }],
          xAxes: [{
            id: 'x',
            type: 'linear',
            position: 'bottom',
            ticks: {
              max: endTime,
              min: startTime,
              autoSkip: true,
              stepSize: oneDay,
              callback: (value, index, values) => new Date(value).getDate()
              }
          },
          {
            id: 'C',
            type: 'linear',
            position: 'top',
            
            grid: {
              display: false,
            },
            ticks: {
              fontSize: 18,
              max: endTime,
              min: startTime,
              autoSkip: false,
              stepSize: oneDay*1,
              maxRotation: 0,
              minRotation: 0,
              padding: -3,
              labelOffset: 22,
              callback: (value, index, values) => {
                  let monthIndex = new Date(value).getMonth();
                  if (index == 0) {
                    monthAxesInfo.firstMonth =monthIndex;
                    monthAxesInfo.done = false;
                    return this.monthNames[monthIndex];
                  } else if (monthIndex != monthAxesInfo.firstMonth && index > 3 && !monthAxesInfo.done) {
                    monthAxesInfo.done = true;
                    return this.monthNames[monthIndex];
                  }
                }
              }
          }
          ],
        }
      }

      this.config = {
        type: 'scatter',
        options,
        data: {
          datasets: allDatasets
        }
      }
      if (this.chart)
        this.chart.destroy();
      this.chart = new Chart('theChartElementId', this.config);
    }
  },
  computed: {
    prevMilking:{
      get() {
        if (!this.animalAndMilkData || !this.animalAndMilkData.milkings.length>1 || this.selectedMilkingIndex < 1 ) {
          return;
        }
        
        return this.animalAndMilkData.milkings[this.selectedMilkingIndex-1];
      }
    },
    currentMilking: {
      get() {
        if (!this.animalAndMilkData || !this.animalAndMilkData.milkings.length) {
          return;
        }
        
        return this.animalAndMilkData.milkings[this.selectedMilkingIndex == -1 ? this.animalAndMilkData.milkings.length-1 : this.selectedMilkingIndex];
      }
    },
    milkings: {
      get() {
        if (!this.animalAndMilkData) {
          return [];
        }

        return this.animalAndMilkData.milkings;
      }
    },
  },
  mounted() {
    this.renderChart()
  },
};
</script>