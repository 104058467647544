<script>
import { VTextField } from "vuetify/lib";

export default {
  extends: VTextField,

  props: {
    items: {
      type: Array,
      required: true,
    },
    appendOuterIcon: {
      type: String,
      default: "$dropdown",
    },
    dense: {
      type: Boolean,
      default: true,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemXpr: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: "value",
    },
  },

  methods: {
    genInput() {
      const listeners = Object.assign({}, this.listeners$);
      delete listeners["change"]; // Change should not be bound externally

      return this.$createElement(
        "select",
        {
          style: {},
          domProps: {
            value: this.lazyValue,
          },
          attrs: {
            ...this.attrs$,
            autofocus: this.autofocus,
            disabled: this.disabled,
            id: this.computedId,
            readonly: this.readonly,
          },
          on: Object.assign(listeners, {
            blur: this.onBlur,
            input: this.onInput,
            focus: this.onFocus,
            keydown: this.onKeyDown,
          }),
          ref: "input",
        },
        this.genOptions()
      );
    },

    genOptions() {
      let xprEval = function (str, o) {
        const regexp = /{([^{]+)}/g;
        return str.replace(regexp, function (ignore, key) {
          return (key = o[key]) == null ? "" : key;
        });
      };
      return this.items.map((item) => {
        let text = typeof item === "object" ? item[this.itemText] : item;
        let value = typeof item === "object" ? item[this.itemValue] : item;
        return this.$createElement("option", {
          domProps: {
            innerHTML: this.itemXpr ? xprEval(this.itemXpr, item) : text,
            value,
            style: "color: #000000",
          },
        });
      });
    },
  },
};
</script>

<style scoped>
select {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  color: #ffffff;
  cursor: pointer;
}

select:focus {
  outline: none;
}

.v-input__append-outer {
  position: absolute;
  right: 11px;
}

.v-input__append-outer .v-icon {
  color: #ffffff !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: var(--v-button-base);
}
</style>