<template>
  <v-dialog v-model="picker" max-width="80%">
    <v-card>
      <v-toolbar color="#dcdcdc" height="30">{{ $t("Select emoji") }}</v-toolbar>
      <v-card-text class="pa-0 pt-2">
        <v-tooltip v-for="item in $store.state.chat.emojiList" :key="item.name" right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-2" v-bind="attrs" v-on="on" text small @click="use(item.code)">
              <v-img :contain="true" :src="item.src" width="20" />
            </v-btn>
          </template>
          <span>{{ item.tip }}</span>
        </v-tooltip>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn @click="picker = false">{{ $t("Close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      picker: false,
    };
  },

  watch: {
    dialog: function (newVal, oldVal) {
      this.picker = true;
    },
  },

  methods: {
    use(code) {
      this.$emit("click", code);
      this.picker = false;
    },
  },
};
</script>

