<template>
  <v-container fluid class="mr-0 pr-0">

    <v-sheet :style="{ height: 'calc(100vh - 80px)', backgroundColor: theme.backgroundGray}" class="overflow-y-auto mr-1 pr-2">

      <v-row no-gutters class="mb-n4">
        <v-col>
          <NativeSelect
            :backgroundColor="theme.button"
            class="rounded-lg"
            v-model="selectedProfile"
            :items="profilesSorted"
            itemText="name"
            itemValue="nameKey"
          />
        </v-col>
        <v-col cols="auto">
          <v-btn :style="{backgroundColor: theme.button}" height="40px" width="40px" class="ml-2 rounded-lg" @click="restoreSort()">
            <v-img :src="require('@/assets/sort_colored.png')" height="40" width="40" :class="{up:sortDesc, down:!sortDesc}" />
          </v-btn>

          <v-dialog
            v-model="searchDialog"
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn :style="{backgroundColor: theme.button}" height="40px" width="40px" class="ml-2 rounded-lg" v-bind="attrs" v-on="on">
                <v-img :src="require('@/assets/search50.png')" height="40" width="40" />
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <v-row class="mb-3" >
                  <v-spacer />
                  <span class="text-h5">{{$t("Animal number?")}}</span>
                  <v-spacer />
                </v-row>
              </v-card-title>
              <v-card-text>
                <v-row class="mb-n5">
                  <v-text-field autofocus v-model="inputCowNumber" clearable outlined small ref="cowSearchInput" @keydown.enter="findCow()" @keydown.tab="findCow()" />
                </v-row>
                <v-row>
                  <v-spacer />
                  <v-btn class="mr-1 rounded-lg" dark @click="searchDialog=false">Close</v-btn>
                  <v-btn class="ml-1 rounded-lg" dark @click="findCow()" >Ok</v-btn>
                  <v-spacer />
                </v-row>
              </v-card-text>
            </v-card>
          </v-dialog>

        </v-col>
      </v-row>

      <v-sheet rounded class="mb-2 rounded-lg">

        <v-row v-if="!searchCowNumber && !animalGroups" no-gutters class="mb2">
          <!-- Placeholder for when farm only has one group with animals -->
        </v-row>

        <v-row v-if="!searchCowNumber && animalGroups" no-gutters class="mb-n7">
          <v-col class="pa-2">
          <NativeSelect
            :backgroundColor="theme.button"
            class="rounded-lg"
            v-model="selectedGroup"
            :items="animalGroups"
            itemXpr="{GroupName} ({animalCount})"
            itemValue="GroupId"
          />
          </v-col>
        </v-row>

        <v-row v-if="searchCowNumber" no-gutters class="mb-1">
          <v-col class="pa-2">
            <v-btn block depressed color="error" class="rounded-lg" @click="searchCowNumber = 0">
              {{$t("Stop show")}} "{{searchCowNumber}}"
            </v-btn>
          </v-col>
        </v-row>

        <!-- column headers -->
        <v-row no-gutters>
          <v-col cols="auto" class="ml-1">
            <CowHeadIcon height="22px" :fill="'#00000000'"/>
          </v-col>
          
          <v-col>
            <v-row no-gutters class="pt-2 pb-2">
              <v-col v-for="profileIndex, index in columnIndexes" :key="index"  @click="selectSort(profileIndex)" class="truncate ml-n4 mr-4 pointer" :style="{zIndex: 1, color: getTitleSortColor(profileIndex)}"  md="2" sm="4" cols="6">
                {{columnTitle(profileIndex)}}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

      </v-sheet>
      
      <template v-for="cow in sortedCows">
        <CowQRow :key="cow.animalNr" :cow="cow" :selectedProfileObject="selectedProfileObject" :codeSet="codeSet"/>
      </template>

    </v-sheet>
    
  </v-container>
</template>

<script>
import CowHeadIcon from "@/assets/cowhead.svg";
import Names from "@/services/Names";
import {Cow} from "@/services/shared/Cow.js"
import {ColumnIndex} from "@/services/shared/Profile.js"
import {CodeSet} from "@/services/shared/CodeSet.js"
import CowQRow from "@/components/cowq/CowQRow.vue";
import NativeSelect from "@/components/NativeSelect.vue";

export default {
  
  name: "CowQList",

  components: {
    CowHeadIcon,
    CowQRow,
    NativeSelect,
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      checked: true,
      sortDesc: false,
      selectedSortCol: 0,
      selectedGroup: -1,
      searchDialog: false,
      inputCowNumber: '',
      searchCowNumber: 0,
      unit: this.$uct("kg", "lb"),
    };
  },
  watch: {
    "sortDesc": function (currentValue, previousValue) {
      let profile = this.$store.getters.getSelectedOrDefaultProfile;
      localStorage.setItem("cowq.sort." + profile.name + ".sortDesc", +currentValue);
    },
    "sortCol": function (currentValue, previousValue) {
      console.log("sortCol change", currentValue, this.$store.state.farmData.data.profiles.deLaval.choosenProfile);
      let profile = this.$store.getters.getSelectedOrDefaultProfile;
      localStorage.setItem("cowq.sort." + profile.name + ".sortCol", currentValue);
    },
    "selectedGroup": function (currentValue, previousValue) {
      console.log("selectedGroup change", currentValue, this.$store.state.farmData.data.profiles.deLaval.choosenProfile);
      let profile = this.$store.getters.getSelectedOrDefaultProfile;
      localStorage.setItem("cowq.selectedGroup", currentValue);
    },
  },
  
  methods: {
    restoreSort() {
      this.sortDesc = this.selectedSortCol == -ColumnIndex.Since_milked ? !this.sortDesc : this.sortDesc;
      this.selectedSortCol = -ColumnIndex.Since_milked;
    },
    findCow() {
      let numberToFind = parseInt(this.inputCowNumber);
      if (!isNaN(numberToFind) && numberToFind>0)
      {
        this.searchCowNumber = numberToFind;
      }
      else
      {
        this.searchCowNumber = 0;
      }
      setTimeout(() => this.searchDialog = false,100);
      this.inputCowNumber = '';
    },
    selectSort(profileIndex) {
      this.sortDesc = this.selectedSortCol == profileIndex ? !this.sortDesc : this.sortDesc;
      this.selectedSortCol = profileIndex;
    },

    logCow: function (cow) {
      console.log("Cow:", cow)
    },
    columnTitle: function(col) {
      let extraInfo = "";
      if (col == 2) {
        extraInfo = this.cowCount;
      } else if (col == 6 || col == 7) {
        extraInfo = this.avgDIM;
      } else if (col == 12 || col == 13) {
        extraInfo = this.avg7days;
      }
       else if (col == 24) {
        extraInfo = this.avgMilkingsPerDay;
      }
      return this.$store.state.farmData.data.profiles.fieldNames[col] + extraInfo;
    },
    getTitleSortColor(profileIndex) {
      if (profileIndex == this.sortCol)
        return  !this.sortDesc ? this.theme.sortDescTitle : this.theme.sortAscTitle;
      return '#000';
    },
  },
  computed: {
    profilesSorted: {
      get() {
        return this.$store.state.farmData.data.profiles.data;
      }
    },
    animalGroups: {
      get() {
        if (this.$store.state.farmData.data.groups.filter( g => g.animalCount > 0).length < 2)
          return false;
        let allGroups = {
          GroupId: -1,
          GroupName: this.$t("All groups"),
          GroupNr: -1,
          animalCount: this.$store.state.farmData.data.animals.length
          }
        return [allGroups, ...this.$store.state.farmData.data.groups.filter( g => g.animalCount > 0)];
      }
    },
    codeSet: {
      get() {
        return new CodeSet(this.$store.state.farmData.data.codeSetsSerialized);
      }
    },

    sortCol: {
      get() {
        return this.selectedSortCol ? this.selectedSortCol : this.selectedProfileObject.sortColumnIndex;
      }
    },

    columnIndexes: {
      get() {
        return this.selectedProfileObject.columns;
      }
    },

    cowCount: {
      get() {
        return this.sortedCows.length == 1 ? this.sortedCows[0].message ? "" : "=1" : "="+this.sortedCows.length;
      }
    },

    avgDIM: {
      get() {
        let cowCount = 0;
        let tNow = new Date().getTime();
        let sum = this.sortedCows.reduce((result, cow) =>  {
          if (cow.lactationDay && cow.lactationDay.getTime() > 0) {
            cowCount++;
            return result+Math.floor((tNow-cow.lactationDay.getTime())/(1000*60*60*24));
          }
          return result;
        },0);
        return cowCount==0 ? "" : "~" + Math.floor((sum/cowCount));
      }
    },

    avg7days: {
      get() {
        let cowCount = 0;
        let sum = this.sortedCows.reduce((result, cow) =>  {
          let lastSevenDays = +cow.lastSevenDays;
          if (isNaN(lastSevenDays) || lastSevenDays==0) {
            return result;
          }
          cowCount++;          
          return result + lastSevenDays;
        },0);
        return cowCount==0 ? "" : "~" + Math.round((sum/cowCount))/10;
      }
    },

    avgMilkingsPerDay: {
      get() {
        let cowCount = 0;
        let sum = this.sortedCows.reduce((result, cow) =>  {
          let nrMilkingPerDay = +cow.nrMilkingPerDay;
          if (isNaN(nrMilkingPerDay) || nrMilkingPerDay==0) {
            console.log("Skipping", cow)
            return result;
          }
          cowCount++;          
          return result + nrMilkingPerDay;
        },0);
        return cowCount==0 ? "" : "~" + Math.round((sum/cowCount))/10;
      }
    },

    sortedCows: {
      get() {
        if (this.searchCowNumber)
        {
          let searchResult = this.$store.state.farmData.data.animals.find((c) => c.animalNr == this.searchCowNumber);
          if (searchResult)
            return [searchResult];
          return [{message: this.$t("No cows to display"), getCowQStatusName: () => "X"}];
        }
        let cowsFiltered = this.selectedProfileObject.filterCows(this.$store.state.farmData.data.animals, this.selectedGroup);
        if (this.sortCol == -ColumnIndex.Since_milked)  //Special milking que sorting
          return Cow.sortCowForMilkingQ(cowsFiltered, this.sortDesc);
        return Cow.sortCows(this.sortCol, cowsFiltered, this.$store.state, this.sortDesc);
      }
    },

    selectedProfileObject: {
      get() {
        return this.$store.getters.getSelectedOrDefaultProfile;
      }
    },

    selectedProfile: {
      get() {
        return this.$store.getters.getSelectedOrDefaultProfile.nameKey;
      },
      set(profileNameKey) {
        this.selectedSortCol = 0;
        this.$store.commit(Names.setChoosenProfile, profileNameKey);
        this.sortDesc = !!+localStorage.getItem("cowq.sort." + profileNameKey + ".sortDesc");
        this.selectedSortCol = localStorage.getItem("cowq.sort." + profileNameKey + ".sortCol") || 0;
        this.selectedGroup = localStorage.getItem("cowq.selectedGroup") || this.selectedGroup;
      }
    },

  },
  mounted() {
    this.sortDesc = !!+localStorage.getItem("cowq.sort." + this.selectedProfile + ".sortDesc");
    this.selectedSortCol = localStorage.getItem("cowq.sort." + this.selectedProfile + ".sortCol") || 0;
    this.selectedGroup = localStorage.getItem("cowq.selectedGroup") || -1;
  }
};
</script>
<style scoped>
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.v-text-field--outlined >>> fieldset {
  border-color: var(--v-button-base)
}
.up {
  transition-duration: 0.5s;
  transform: scaleY(-1);
}
.down {
  transition-duration: 0.5s;
  transform: scaleY(1);
}
</style>