'use-strict';
import Parse from "@/services/shared/Parse.js" 
const Axios = require("axios");
Axios.defaults.withCredentials = true;

function getCowDataNew(Store, vcId, useImperialUnits) {
		let oneWeek = 604800000;
		let november7_2014 = 1415318400000
		var getFileIndexFromTime = function(time) {
			return Math.floor((time - november7_2014) / oneWeek) - 60;
		}
		let now = new Date().getTime()
		let indexNow = getFileIndexFromTime(now)
		let i = indexNow
		let ii = 1;
		let weeksToFetch=[];
		weeksToFetch.push(i);
		weeksToFetch.push(--i);
		while (i > 0 && --ii >= 0)
			weeksToFetch.push(--i);

		weeksToFetch.sort(function(a, b) {
			return a - b;
		});

		let promises = weeksToFetch.map(function(week) {
			return getWeekMilkings(Store, vcId, week);
		});

		let done = Promise.all(promises).then(function(datas) {
			return datas
			.map( data => new MilkData(data) )
			.reduce(function(previous, current) {
				if (previous == null) { return current; }
				if (previous.movementTime) {
					current.movements=previous;
					return current;
				}
				else if (current.movements)
					previous.movements=current;
				else
					previous.mergeWithLaterWeek(current);
				return previous;
			}, null);
		});

		return done.then(function(data) {
			var minTime = new Date().getTime(), maxTime = 0, maxProd = 0;
			var cc = 2.204622621;
			data.animals.forEach(function(a) {
				if (useImperialUnits) {
					a.milkings.forEach(m => m.convertToImperical());
				};
				var p = a.production = new AnimalData(a);
				if (p.startDate < minTime)
						minTime = p.startDate;
				if (p.endDate > maxTime)
						maxTime = p.endDate;
				if (p.maxProd > maxProd)
						maxProd = p.maxProd;
			});
			data.animalsFromNumber={};
			data.animals.map(function(a) {
				data.animalsFromNumber[a.animalNr]=a;
			});
			if (data.movements) {
				data.movements.animals.map(function(a){
					var an = data.animalsFromNumber[a.animalNr];
					if (an){
						an.movements = a.movements;
						an.lastHour = data.movements.movementTime;
					}
				});
			}
			data.maxProd = maxProd;
			data.minTime = minTime;
			data.maxTime = maxTime;

			// data.cellsWarning = allData.cellsWarning;
			// data.cellsAlarm = allData.cellsAlarm;

			return data;
		});
	};

function getWeekMilkings(Store, vcId, week) {
  let url = Store.getters.getServerForSelectedFarm + "/GetWeekMilkings.vcx?vcId=" + vcId + "&index=" + week + "&time=" + new Date().getTime();
  return Axios.request({responseType: 'arraybuffer', url, method: 'get'})
  .then((res) => {
    return new Uint8Array(res.data);
  });
}

function AnimalData(animal) {
		if (!animal.milkings.length) {
			return;
		}
			
		let oneSecond = 1000;
		let oneMinute = oneSecond*60;
		let oneHour = oneMinute*60;
		let oneDay = oneHour*24;
		let hours36 = oneHour*36;
		this.days = [];
		let milkings = animal.milkings;
		this.startTime = new Date(milkings[0].endOfMilkingTime);
		this.endTime = new Date(milkings[milkings.length - 1].endOfMilkingTime);
		this.startTime.setHours(0,0,0,0);
		this.endTime.setHours(0,0,0,0);
		this.startTime = this.startTime.getTime();
		this.endTime = this.endTime.getTime();
		var nrDays = (this.endTime - this.startTime) / oneDay + 1;
		this.days.length = Math.round(nrDays);

		let prevEndOfMilkingTime = 0, prodTime=0;
		milkings.forEach( m => {
			if (prevEndOfMilkingTime && 
				 (prodTime = m.endOfMilkingTime - prevEndOfMilkingTime) < hours36) {
				m.prodHours = prodTime / oneHour;
				m.prodPerDay = m.totalYield / m.prodHours * 24;
				var dayInd = Math.floor((m.endOfMilkingTime - this.startTime) / oneDay), d;
				if (!this.days[dayInd])
					this.days[dayInd] = { mlk:[], kg:0, time:0} ;
				this.days[dayInd].mlk.push(m);
			}
			prevEndOfMilkingTime = m.endOfMilkingTime;
		});

		this.days.forEach( (day, index) => {
			let prevDay = this.days[index - 1];
			let firstMilking = day.mlk[0];
			let prodHoursToday = (firstMilking.endOfMilkingTime - this.startTime - index * oneDay) / oneHour
			let f = Math.min(prodHoursToday / firstMilking.prodHours, 1);
			day.kg = f * firstMilking.totalYield;
			day.time = f * firstMilking.prodHours;
			if (prevDay) {
				f = 1 - f;
				prevDay.kg += f * firstMilking.totalYield;
				prevDay.time += f * firstMilking.prodHours;
				prevDay.prodPerDay = prevDay.kg / (prevDay.time / 24);
			}
			day.mlk.forEach( (m, index) => {
				if (index) {
					day.kg += m.totalYield;
					day.time += m.prodHours;
				}
			});
			day.prodPerDay = day.kg / (day.time / 24);

		});

		this.maxProd = 0;
		this.days.forEach ( (d,index) => {
				if (index && ( d.prodPerDay = d.kg / (d.time / 24)) > this.maxProd) this.maxProd = d.prodPerDay
		});

		let i = this.days.length;
		let ii = 8;
		var sum = 0, cnt = 0;
		while (--ii >=0 && --i >= 0)
			if (this.days[i]) {
				sum += this.days[i].prodPerDay;
				cnt++;
			}
		this.prod7d = cnt ? sum / cnt : 0;
		this.startDate = this.startTime;
		this.endDate = this.endTime + oneDay - 1;
};

function MilkData(data) {
	var 
	buf,
	bufInd,
	bufLen,
	Milking = function(fixLen) {
		const propertyNamesToConvertKg2Lb = [
			'totalYield',
			'totalYieldLF',
			'totalYieldRF',
			'totalYieldLR',
			'totalYieldRR',
			'averageFlow',
			'averageFlowLF',
			'averageFlowRF',
			'averageFlowLR',
			'averageFlowRR',
			'expectedYieldLF',
			'expectedYieldRF',
			'expectedYieldLR',
			'expectedYieldRR',
		];
		this.convertToImperical = function() {
			if (this.useImperialUnits) {
				return;
			}
			propertyNamesToConvertKg2Lb.forEach( prop => (this[prop] = this[prop] ? this[prop] * Parse.lbPerKg : this[prop])) 
			this.useImperialUnits = true;
		};
		this.convertToMetric = function() {
			if (!this.useImperialUnits) {
				return;
			}
			propertyNamesToConvertKg2Lb.forEach( prop => (this[prop] = this[prop] ? this[prop] / Parse.lbPerKg : this[prop])) 
			this.useImperialUnits = false;
		};
		let indBefore = bufInd
		let len = 							getBufShort();
		this.endOfMilkingTime =	getBufLong();
		this.guidHash =					getBufInt();
		this.animalNumber =			getBufInt();
		let version =						getBufByte();
		if (version === 1) {
			let combinedMap0 =		getBufInt();
			this.activity =				getBufByte();	
			if (this.activity === 255) this.activity = -1;
			this.lactation =			getBufByte();
			let combinedMap1h =		getBufInt();
			let combinedMap1l =		getBufInt();
			let combinedMap2h =		getBufInt();
			let combinedMap2l =		getBufInt();
			let combinedMap3 =		getBufInt();
			let combinedMap4 =		getBufInt();
			let combinedMap5 =		getBufShort(),
			aver = function(arr) {
				let sum = 0, count = 0, i = -1;
				while (++i < arr.length)
					if (arr[i] > 0) {
						sum += arr[i];
						count++;
					}
				return count ? Math.round(sum / count * 100) / 100 : 0;
			};
			this.expectedYieldPercentDiff =	(((combinedMap1h & 0x7FF00000) >> 20) - 1200) / 20;
			this.averageFlow =							(((combinedMap1h & 0x7F) << 2) + (combinedMap1l >> 30)) * 10;
			this.averageConductivity =			((combinedMap1l & 0xFFE00) >> 9) / 100;
			if (combinedMap0 !== -1) {
				this.conductivityLF=		this.averageConductivity;
				this.conductivityRF=		(combinedMap0 & 0x000003ff) / 100;
				this.conductivityLR=		((combinedMap0 & 0x000FFC00) >> 10) / 100;
				this.conductivityRR=		((combinedMap0 & 0x3FF00000) >> 20) / 100;
				this.averageConductivity =	aver([this.conductivityLF, this.conductivityRF, this.conductivityLR, this.conductivityRR]);
				if (combinedMap3 !== -1) {
					this.averageFlowLF =	this.averageFlow;
					this.averageFlowRF =	(combinedMap3 & 0x000003ff) * 10;
					this.averageFlowLR =	((combinedMap3 >> 10) & 0x000003ff) * 10;
					this.averageFlowRR =	((combinedMap3 >> 20) & 0x000003ff) * 10;
					this.averageFlow =		aver([this.averageFlowLF, this.averageFlowRF, this.averageFlowLR, this.averageFlowRR]);
				}
			}
			let value =								(combinedMap1h & 0xFFF80) >> 7;
			this.occ =								value === 0x1fff ? -1 : value;
			value =										(combinedMap1l & 0x3FF00000) >> 20;
			this.mdi =								value === 0x3FF ? -1 : (value / 100);
			value =										(combinedMap1l & 0x1FF);
			this.performance =				value === 0x1FF ? -1 : value;
			this.totalYield =					(combinedMap2l &		0x1FFF) * 0.01;
			this.expectedSpeed =			((combinedMap2l &		0x3FFE000) >> 13) / 1000;
			this.carryOver =					(((combinedMap2h &		0x3F) << 6) + (combinedMap2l >> 26)) / 100;
			this.pulsationRatio =			((combinedMap2h &		0x1FC0) >> 6);
			this.cleaningProgramNr =	(combinedMap2h &		0x1E000000) >> 25;
			value =										(combinedMap2h &		0x1FFE000) >> 13;
			this.blood =							value === 0xFFF? -1 : (value * 4);
			this.lactationDay =				getBufLong();
			this.secMilkingTime =			getBufShort();
			value =										getBufByte();
			this.breed =							value === 255 ? -1 : value;
			this.flags =							getBufShort();
      this.incompleteLF = 			this.flags & 0x5;
      this.incompleteRF = 			this.flags & 0x50;
      this.incompleteLR = 			this.flags & 0x500;
      this.incompleteRR = 			this.flags & 0x5000;
      this.isIncomplete = 			!!(this.flags & 0x5555);
      this.kickOffLF = 					this.flags & 0x2;
      this.kickOffRF = 					this.flags & 0x20;
      this.kickOffLR = 					this.flags & 0x200;
      this.kickOffRR = 					this.flags & 0x2000;
			this.hasKickOff = 				!!(this.flags & 0x2222);
			this.totalYieldLF =				getBufFloat100();
			this.totalYieldRF =				getBufFloat100();
			this.totalYieldLR =				getBufFloat100();
			this.totalYieldRR =				getBufFloat100();
			this.expectedYieldLF =		getBufFloat100();
			this.expectedYieldRF =		getBufFloat100();
			this.expectedYieldLR =		getBufFloat100();
			this.expectedYieldRR =		getBufFloat100();
			this.carryoverYieldLF =		getBufFloat100();
			this.carryoverYieldRF =		getBufFloat100();
			this.carryoverYieldLR =		getBufFloat100();
			this.carryoverYieldRR =		getBufFloat100();
			this.expectedSpeedLF =		getBufFloat1000();
			this.expectedSpeedRF =		getBufFloat1000();
			this.expectedSpeedLR =		getBufFloat1000();
			this.expectedSpeedRR =		getBufFloat1000();
			this.bloodLF =						getBufShort0();
			this.bloodRF =						getBufShort0();
			this.bloodLR =						getBufShort0();
			this.bloodRR =						getBufShort0();
			let s='', i=len-(bufInd-indBefore), ch;
			while(--i>=0 && (ch=getBufByte()))
				s += String.fromCharCode(ch);
			this.robot = s;
			s = '';
			while(--i>=0 && (ch=getBufByte()))
				s += String.fromCharCode(ch);
			this.milkDestination = s;
			s = '';
			while(--i>=0 && (ch=getBufByte()))
				s += String.fromCharCode(ch);
			this.events=s;
		}
		bufInd = indBefore + (fixLen ? fixLen : len);
	},
	Animal = function(animalNr,nrMilkings) {
		this.animalNr =	animalNr;
		this.nrMilkings = nrMilkings;
		this.milkings =	[];
		this.addMilkingsAtEnd = function(other) {
			let nrNewMilkings = other.nrMilkings, i = -1;
			while (++i < nrNewMilkings)
				this.milkings.push(other.milkings[i]);
			this.nrMilkings += nrNewMilkings;
		};
	},
	setBuf=function(bytearr) {
		buf = bytearr;
		bufInd = 0;
		bufLen = bytearr.byteLength;
	},
	getBufByte=function() {
		return buf[bufInd++];
	},
	getBufShort=function() {
		bufInd += 2;
		return	  ((buf[bufInd-2] & 0xFF) << 8)
				| ((buf[bufInd-1] & 0xFF));
	},
	getBufShort0=function() {
		bufInd += 2;
		var val = ((buf[bufInd-2] & 0xFF) << 8)
				| ((buf[bufInd-1] & 0xFF));
		return val === 65535 ? -1 : val;
	},
	getBufFloat100=function() {
		bufInd += 2;
		var val = ((buf[bufInd-2] & 0xFF) << 8)
				| ((buf[bufInd-1] & 0xFF));
		return val === 65535 ? -1 : val / 100;
	},
	getBufFloat1000=function() {
		bufInd += 2;
		var val = ((buf[bufInd-2] & 0xFF) << 8)
				| ((buf[bufInd-1] & 0xFF));
		return val === 65535 ? -1 : val / 1000;
	},
	getBufInt=function() {
		bufInd += 4;
		return	  ((buf[bufInd-4] & 0xFF) << 24)
				| ((buf[bufInd-3] & 0xFF) << 16)
				| ((buf[bufInd-2] & 0xFF) << 8)
				| ((buf[bufInd-1] & 0xFF));
	},
	getBufLong=function() {
		var result, i=8;
		while(--i >= 0) {
			var b=buf[bufInd++];
			if(result)
				result+=b?(b>=16?'':'0')+b.toString(16):'00';
			else if (b)
				result=b.toString(16);
		}
		return result?parseInt(result,16):0;
	};

		this.getNrMilkings = function() {
			var sum=0, i=-1, len=this.nrAnimals;
			while (++i < len)
				sum += this.animals[i].nrMilkings;
			return sum;
		},
		this.getAnimal = function(animalNr) {
			if (this.nrAnimals) {
				var iLeft = 0, iRight = this.nrAnimals - 1, i = Math.floor(this.nrAnimals / 2);
				for (;;) {
					var an = this.animals[i];
					if (an.animalNr === animalNr)
						return an;
					if (animalNr < an.animalNr)
						iRight = i - 1;
					else
						iLeft = i + 1;
					if (iRight < iLeft)
						return null;
					else if (iRight === iLeft)
						i = iLeft;
					else
						i = iLeft +  Math.floor((iRight - iLeft) / 2) + 1;
				}
			}
			return null;
		},
		this.mergeWithLaterWeek = function(laterWeek) {
			var i = -1;
			while (++i < laterWeek.nrAnimals) {
				var newAnimal = laterWeek.animals[i];
				var myAnimal = this.getAnimal(newAnimal.animalNr);
				if (myAnimal)
					myAnimal.addMilkingsAtEnd(newAnimal);
				else {
					this.animals.push(newAnimal);
					this.nrAnimals++;
					this.animals.sort(function(a, b){return a.animalNr-b.animalNr;});
				}
			}
		};
		setBuf(data);
		this.totalFixSize =		getBufInt();
		this.milkingFixSize =	getBufInt();
		this.nrAnimals =		getBufInt();
		this.animals =			[];
		var i=-1,animal;
		if (this.totalFixSize === -1) {
			this.movementTime =		this.milkingFixSize*3600000;
			var nrDays	=		getBufShort();
			while (++i < this.nrAnimals) {
				this.animals.push(animal={animalNr: getBufInt(), movements:[]})
				var ii = nrDays;
				while (--ii >= 0)
					animal.movements.push(getBufShort());
			}
		}
		else {
			while (++i < this.nrAnimals) {
				var animalNr = getBufInt();
				var nrMilkings = getBufShort();
				this.animals.push(new Animal(animalNr, nrMilkings));
			}
			i = -1;
			while (++i < this.nrAnimals) {
				let ii = -1, a = this.animals[i];
				while (++ii < a.nrMilkings)
					a.milkings.push(new Milking(this.milkingFixSize));
			}
			while (bufInd < bufLen) {
				var extraMilking = new Milking();
				var newAnimal = this.getAnimal(extraMilking.animalNumber);
				if (newAnimal) {
					i = newAnimal.nrMilkings;
					while (--i >= 0 && newAnimal.milkings[i].endOfMilkingTime > extraMilking.endOfMilkingTime); //{}
					if (i >= 0 && (newAnimal.milkings[i].endOfMilkingTime === extraMilking.endOfMilkingTime || newAnimal.milkings[i].guidHash === extraMilking.guidHash))
						newAnimal.milkings[i] = extraMilking;
					else {
						newAnimal.milkings.splice(i + 1, 0, extraMilking);
						newAnimal.nrMilkings++;
					}
				}
				else {
					newAnimal = new Animal(extraMilking.animalNumber, 1);
					newAnimal.milkings.push(extraMilking);
					this.animals.push(newAnimal);
					this.nrAnimals++;
					this.animals.sort(function(a, b){return a.animalNr-b.animalNr;});
				}
			}
		}
};

export default {
  getCowDataNew: function(Store, vcId, useImperialUnits)
  {
    return getCowDataNew(Store, vcId, useImperialUnits);
  }
};