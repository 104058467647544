<template>
  <v-container class="ma-0 pa-4" fluid>
    <template v-if="gotPermission()">
      <v-row dense>
        <v-btn color="button" dark block @click="newUser()">{{ $t("New user") }}</v-btn>
      </v-row>
      <v-row class="mt-4" dense>
        <p class="category">{{ $t("Users") }}</p>
      </v-row>
      <v-row dense>
        <v-divider class="mb-2" />
      </v-row>
      <UserCard v-for="user in $store.getters['users/getUsers']" :key="user.id" :user="user" />
      <SaveUser v-if="showSaveUser" @closed="showSaveUser = false" />
    </template>
    <template v-else>
      <v-row class="my-4" dense>
        <p class="category">Users</p>
      </v-row>
      <v-row dense>
        <p>You don't have permission to add or edit users on this farm.</p>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import UserCard from "@/components/users/UserCard.vue";
import SaveUser from "@/components/users/SaveUser.vue";

export default {
  name: "Users",

  components: {
    UserCard,
    SaveUser,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      showSaveUser: false,
    };
  },

  methods: {
    gotPermission() {
      return !!this.$store.getters["users/getUsers"].length;
    },

    newUser() {
      console.log("newUser: call");
      this.showSaveUser = true;
    },
  },
};
</script>

<style scoped>
.category {
  margin: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
</style>