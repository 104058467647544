import Parse from "@/services/shared/Parse.js"
export class AnimalGroup {
  static propertyNamesInOrder = [
    'GroupNr',
    'GroupName',
    'GroupId',
  ];

  constructor(dataArray) {
    let f = dataArray.splice(0, AnimalGroup.propertyNamesInOrder.length);
    AnimalGroup.propertyNamesInOrder.forEach((property, index) => this[property] = f[index]);
  }

}