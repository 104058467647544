<template>
  <CowQOverview v-if="mode == 'overview'"/>
  <CowQList v-else/>
</template>

<script>
import CowQList from "@/components/cowq/CowQList.vue"
import CowQOverview from "@/components/cowq/CowQOverview.vue"

export default {
  
  name: "CowQ",

  components: {
    CowQList,
    CowQOverview,
  },
  props: {
    mode: {
      type: String,
    },
  },
};
</script>