<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row dense>
      <p class="category">{{ $t("My phone notifications") }}</p>
    </v-row>

    <v-row dense>
      <v-divider class="mb-2" />
    </v-row>

    <v-row dense>
      <v-col cols="4">
        <v-row dense>
          <p>{{ $t("Subscribe") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("Vibration") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("Quiet time") }}</p>
        </v-row>
      </v-col>
      <v-col>
        <v-row dense>
          <v-checkbox class="ma-0 pa-0" v-model="sendToMe" hide-details />
        </v-row>
        <v-row dense>
          <v-checkbox class="ma-0 pa-0" v-model="vibration" hide-details />
        </v-row>
        <v-row dense>
          <v-checkbox class="ma-0 pa-0" v-model="quietTime" hide-details />
          <template v-if="quietTime">
            <v-col>
              <v-row dense>
                <p>{{ $t("from") }}</p>
              </v-row>
              <v-row dense>
                <NativeSelect class="mr-1" v-model="quietFromTimeHours" :items="hours" dense hide-details :background-color="theme.common_bgButton" />
                <NativeSelect class="ml-1" v-model="quietFromTimeMinutes" :items="minutes" dense hide-details :background-color="theme.common_bgButton" />
              </v-row>
              <v-row dense>
                <p class="ma-0 pa-0">{{ $t("to") }}</p>
              </v-row>
              <v-row dense>
                <NativeSelect class="mr-1" v-model="quietToTimeHours" :items="hours" dense hide-details :background-color="theme.common_bgButton" />
                <NativeSelect class="ml-1" v-model="quietToTimeMinutes" :items="minutes" dense hide-details :background-color="theme.common_bgButton" />
              </v-row>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import NativeSelect from "@/components/NativeSelect.vue";

export default {
  name: "Notifications",

  components: {
    NativeSelect,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      hours: [],
      minutes: [],
    };
  },

  computed: {
    sendToMe: {
      get() {
        if (this.exists) return this.data.doNotify;
        else return false;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.notifications_SendToMe, value);
      },
    },

    vibration: {
      get() {
        if (this.exists) return this.data.isVibration;
        else return false;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.notifications_Vibration, value);
      },
    },

    quietTime: {
      get() {
        if (this.exists) return this.data.useQuietTime;
        return false;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.notifications_QuietTime, value);
      },
    },

    quietFromTimeHours: {
      get() {
        if (this.exists) return this.getHours(this.data.millisecFromTime);
        return "00";
      },
      set(hours) {
        let date = this.getDate(hours, this.quietFromTimeMinutes);
        this.$store.commit(this.$mutations.settings.notifications_QuietFromTime, date);
      },
    },

    quietFromTimeMinutes: {
      get() {
        if (this.exists) return this.getMinutes(this.data.millisecFromTime);
        return "00";
      },
      set(minutes) {
        let date = this.getDate(this.quietFromTimeHours, minutes);
        this.$store.commit(this.$mutations.settings.notifications_QuietFromTime, date);
      },
    },

    quietToTimeHours: {
      get() {
        if (this.exists) return this.getHours(this.data.millisecToTime);
        return "00";
      },
      set(hours) {
        let date = this.getDate(hours, this.quietToTimeMinutes);
        this.$store.commit(this.$mutations.settings.notifications_QuietToTime, date);
      },
    },

    quietToTimeMinutes: {
      get() {
        if (this.exists) return this.getMinutes(this.data.millisecToTime);
        return "00";
      },
      set(minutes) {
        let date = this.getDate(this.quietToTimeHours, minutes);
        this.$store.commit(this.$mutations.settings.notifications_QuietToTime, date);
      },
    },

    // ------- Helpers -------

    data: {
      get() {
        return this.$store.state.settings.config.data.deviceNotifications;
      },
    },

    exists: {
      get() {
        return this.$store.state.settings.config.data.deviceNotifications;
      },
    },
  },

  methods: {
    getHours(t) {
      return ("0" + Math.floor(t / 60000 / 60)).slice(-2);
    },

    getMinutes(t) {
      return ("0" + ((t / 60000) % 60)).slice(-2);
    },

    getDate(hours, minutes) {
      return hours * 60 * 60 * 1000 + minutes * 60 * 1000;
    },

    initHours() {
      for (var i = 0; i < 24; i++) {
        this.hours.push(("0" + i).slice(-2));
      }
    },

    initMinutes() {
      for (var i = 0; i < 60; i++) {
        this.minutes.push(("0" + i).slice(-2));
      }
    },
  },

  mounted() {
    this.initHours();
    this.initMinutes();
  },
};
</script>

<style scoped>
.category {
  padding: 20px 0px 0px 0px;
  margin: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
p {
  margin: 0px;
  padding: 0px;
}
.v-select {
  width: min-content;
}
</style>