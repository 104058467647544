<template>

  <v-row @click="logCow(cow)" class="pt-3 pb-2 mb-2 rounded-lg" no-gutters :class="getRowClass()"> 
     <!-- :style="getRowStyle(cow)"> -->
    <template v-if="!cow.message">
    
      <!-- col ONE  Cow head OR udder circles to show incomplete -->
      <v-col v-if="!cow.isIncomplete()" cols="auto" class="mt-1 ml-n3">
        <CowHeadIcon height="20px"/>
      </v-col>
      <v-col v-else cols="auto" class="ml-2 mt-n2 mr-4">
          <v-row no-gutters >
            <v-col class="mb-n3" :style="{'margin-left': '4px'}">
              <img :src="require('@/assets/cowq_top.png')" height="5px"/>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="1" class="ml-2 mt-n5">
              <img :src="cow.lastMilkingFlags.incompleteLF ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="7px" class="mb-n4" />
              <img :src="cow.lastMilkingFlags.incompleteLR ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="7px"/>
            </v-col>
            <v-col cols="1" class="mt-n5" :style="{'margin-left': '5px'}">
              <img :src="cow.lastMilkingFlags.incompleteRF ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="7px"  class="mb-n4" />
              <img :src="cow.lastMilkingFlags.incompleteRR ? require('@/assets/cowq_red.png') : require('@/assets/cowq_white.png')" width="7px"/>
            </v-col>
          </v-row>
      </v-col>

      <!-- the SIX columns -->
      <v-col>
        <v-row no-gutters class="pt-1">

          <v-col v-for="profileIndex, index in selectedProfileObject.columns" :key="index" md="2" sm="4" cols="6" class="truncate">
            <template v-if="profileIndex == ColumnIndex.Is_Drying_Off || 
                            profileIndex == ColumnIndex.To_Be_Culled || 
                            profileIndex == ColumnIndex.Trap_cow">
              <v-simple-checkbox v-if="cow.getColumnValue(profileIndex, $store.state, codeSet)==1" v-model="checked" disabled />
            </template>
            <template v-else-if="profileIndex == ColumnIndex.Animal_nr">
              <b>{{cow.animalNr}}</b>
            </template>
            <template v-else-if="profileIndex == ColumnIndex.Anim_nr__group">
              <b>{{cow.animalNr}}</b>
              ({{cow.groupName}})
            </template>
            <CowHealth v-else-if="profileIndex == ColumnIndex.Blo_MDi_OCC" :cowHealth="cow.cowHealth" />
            <template v-else>
              {{columnValue(profileIndex, cow)}}
            </template>
          </v-col>

        </v-row>
      </v-col>

    </template>

    <template v-else>
      <v-spacer />
        <v-col>
          {{cow.message}}  
        </v-col>
      <v-spacer />
    </template>
  </v-row>

</template>

<script>
import CowHeadIcon from "@/assets/cowhead.svg";
import {ColumnIndex} from "@/services/shared/Profile.js"
import {Cow} from "@/services/shared/Cow.js"
import CowHealth from "@/components/cowq/CowHealth.vue";

export default {
  
  name: "CowQRow",

  components: {
    CowHeadIcon,
    CowHealth,
  },
  props: {
    cow: Object,
    selectedProfileObject: Object,
    codeSet: Object,
  },
  data() {
    return {
      checked: true,
      ColumnIndex: ColumnIndex,
      unit: this.$uct("kg", "lb"),
    };
  },
  methods: {
    trendImage(trend, color) {
      switch (color) {
        case 1:
          return require('@/assets/arrows/green' + trend + '.png');
        case 2:
          return require('@/assets/arrows/yellow' + trend + '.png');
        case 3:
          return require('@/assets/arrows/red' + trend + '.png');
      }
    },
    columnValue: function(col, cow) {
      return cow.getColumnValue(col, this.$store.state, this.codeSet, this.unit);
    },
    logCow: function (cow) {
      this.$router.push('/cow/' + cow.animalNr + "/info" );
    },
    getRowClass: function () {
      let cowStatus = this.cow.getCowQStatus();
      let text = (cowStatus == Cow.CowQStatus.FeedOnly || 
                  cowStatus == Cow.CowQStatus.MilkOverdue ||
                  cowStatus == Cow.CowQStatus.MilkPermissionNeverMilked) ? " white--text" : " black--text";
      return Cow.CowQStatus.properties.names[cowStatus] + text;
    }
  },
};
</script>
<style scoped>
</style>