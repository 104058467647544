<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: ['chartdata', 'options'],
  mounted () {
    this.renderChart(this.chartdata, this.optionsOrg)
  },
  data() {
    return {
      optionsOrg: {
        tooltips: {enabled: false},
        legend:{display: false},
        cutoutPercentage: 70,
        maintainAspectRatio: false,
        animation:{animateRotate: false},
      }
    };
  },
  watch: {
    chartdata: function(current, old)
    {
      console.log("Chartdata changed:", current);
      this.renderChart(current, this.optionsOrg)
    },
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.renderChart(this.chartdata, this.optionsOrg)
    },
  },
}
</script>