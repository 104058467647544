export class Text {
 static texts = {
		today:		    'Today',
		weekDay:	    'Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday',
		blood_:	    	'B:',
		mdi_:   			'M:',
		cells_:		    'C:',
		actions:		  'Milk,FeedOnly,PassThrough,Unselected',
		sureOpenWait:	'Are you sure you want to open to wait area?',
		cancel:		    'Cancel',
		OpenWaitArea:	'OpenWaitArea',
		search:		    'Animal number?',
		yes:			    'Yes',
		no: 			    'No',
		save: 			  'Save',
		remove: 		  'Remove',
		djur: 			  'Animal',
		confirmDelete:'Delete mark for animal',
		markComment: 	'Mark animal with optional comment',
		markAnimal: 	'Mark animal',
		questionMark: '?',
		yesterday: 	  'yesterday',
		minmin: 		  'min_min',
		minsec: 		  'min_sec',
		OpenWaitWrea: 'Open to wait area',
		SureOpenWait: 'Are you sure you want to open to wait area?',
		group: 		    'Group',
		by: 			    'by'
	}

  static getText(key) {
    return Text.texts[key] || "{" + key + "}";
  }

  constructor(texts = Text.texts) {

  }
  


}