<template>
  <div>
    <v-overlay :value="overlay"><v-progress-circular indeterminate size="100" /></v-overlay>
  </div>
</template>

<script>

export default {
  name: "Loader",
  data() {
    return {
      overlay: true
    };
  },
};
</script>