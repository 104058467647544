<template>
  <v-dialog v-model="show" persistent max-width="80%">
    <v-card v-if="hasConfirmPermission">
      <v-card-title>{{ $t("Do you want to confirm this alarm?") }}</v-card-title>
      <v-card-text>
        <p :style="{ color: theme.alarms_textBlack }" class="ma-0 mp-0">{{ device }}</p>
        <p :style="{ color: theme.alarms_textBlack }" class="ma-0 mp-0">{{ date }}</p>
        <p :style="{ color: theme.alarms_textBlack }" class="ma-0 mp-0">{{ message }}</p>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-row class="ma-2">
          <v-col class="ma-0 pa-0 pr-1" cols="6">
            <v-btn block @click="cancel()">{{ $t("No") }}</v-btn>
          </v-col>
          <v-col class="ma-0 pa-0 pl-1" cols="6">
            <v-btn block @click="confirm()">{{ $t("Yes") }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <v-card v-else>
      <v-card-title> No permission for this operation! </v-card-title>
      <v-card-actions class="justify-end">
        <v-btn color="button" dark @click="cancel()">{{ $t("Ok") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const Axios = require("axios");
Axios.defaults.withCredentials = true;

export default {
  name: "ConfirmAlarm",

  props: {
    alarm: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      show: true, // Seems like this is needed ...
      device: this.alarm.sourceDevice,
      date: this.getTimeStamp(this.alarm.time),
      message: this.alarm.message,
      hasConfirmPermission: this.$store.state.farmData.data.alarms.alarms.perm & 2, // Note! Unsafe, not checked on server side
    };
  },

  methods: {
    cancel() {
      console.log("confirmAlarm: cancel");
      this.$emit("closed");
    },

    confirm() {
      console.log("confirmAlarm: confirm");
      this.$emit("closed");
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvAlarms/confirm";
      Axios.post(url, JSON.stringify({ id: this.$store.state.selectedFarm.id, guid: this.alarm.guid }))
        .then((res) => {
          console.log("confirmAlarm: res=", res);
        })
        .catch((err) => console.error(err));
    },

    getTimeStamp(time_ms) {
      let date = new Date(time_ms);
      return date.toLocaleString("sv-SE", {
        timeStyle: "short",
        dateStyle: "short",
      });
    },
  },
};
</script>

<style scoped>
</style>