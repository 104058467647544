<template>

  <span>
    <span>B:
      <img  v-if="cowHealth.blood.trend" :src="trendImage(cowHealth.blood.trend, cowHealth.blood.color)" width="18px" class="ml-n1 mb-n1" />
    </span>
    <span>M:
      <img  v-if="cowHealth.mdi.trend" :src="trendImage(cowHealth.mdi.trend, cowHealth.mdi.color)" width="18px" class="ml-n1 mb-n1" />
    </span>
    <span>C:
      <img  v-if="cowHealth.conductivity.trend" :src="trendImage(cowHealth.conductivity.trend, cowHealth.conductivity.color)" width="18px" class="ml-n1 mb-n1" />
    </span>
  </span>

</template>

<script>
export default {
  name: "CowHealth",

  props: {
    cowHealth: Object,
  },
  methods: {
    trendImage(trend, color) {
      switch (color) {
        case 1:
          return require('@/assets/arrows/green' + trend + '.png');
        case 2:
          return require('@/assets/arrows/yellow' + trend + '.png');
        case 3:
          return require('@/assets/arrows/red' + trend + '.png');
      }
    },
  },
};
</script>
<style scoped>
</style>