import Vue from "vue";
import VueI18n from 'vue-i18n'

const messageCache = {
  onFly: {},
  fallback: {
    "Delete": "Delete",
    "January February March April May June July August September October November December": "January February March April May June July August September October November December",
    "Not under quiet time": "Not under quiet time",
    "Wait area open": "Wait area open",
    "Blo MDi Cel": "Blo MDi OCC",
    "Edit": "Edit",
    "Stop alarms": "Stop alarms",
    "MyFarm": "DeLaval MyFarm",
    "Success": "Success",
    "New user": "New user",
    "to start/continue using the service": "to start/continue using the service",
    "Reload": "Reload",
    "Profiles": "Profiles",
    "Status": "Status",
    "No messages": "No messages",
    "Roles": "Roles",
    "Open to wait area": "Open to wait area",
    "use upper/lower/numbers/others": "use upper/lower/numbers/others",
    "last visited": "last visited",
    "${applicationScope}": "${applicationScope}",
    "B:": "B:",
    ":": ":",
    "Close": "Close",
    "Retry": "Retry",
    "?": "?",
    "Reprod_Bred": "Bred",
    "Group": "Group",
    "I have read and accepted the end user license agreement (EULA)": "I have read and accepted the end user license agreement (EULA)",
    "Cows": "Cows",
    "Last name": "Last name",
    "Notification": "Note",
    "My phones": "My devices",
    "Created": "Created",
    "Milking time": "Time (date)",
    "min": "min",
    "Last seven days": "Last seven days",
    "C:": "C:",
    "by": "by",
    "Login failed": "Login failed",
    "Logout": "Logout",
    "24h": "24h",
    "Animal number?": "Animal number?",
    "My settings": "My settings",
    "Conductivity": "Conductivity",
    "Are you sure you want to delete user: ": "Are you sure you want to delete user: ",
    "blood": "blood",
    "Create new password": "Create new password",
    "Red": "Red",
    "No farm data has yet been collected, please wait": "No farm data has yet been collected, please wait",
    "Forgot password. Enter your e-mail address": "Forgot password. Enter your e-mail address",
    "Farm": "Farm",
    "Last call": "Last call",
    "to": "to",
    "Add": "Add",
    "Old password": "Current password",
    "Flow kg/m": "Flow kg/m",
    "Percent of expected": "Percent of expected",
    "Farm communication problems": "Internet problem at farm",
    "App version": "App version",
    "No alarms": "No alarms",
    "Select server": "Select server",
    "Adjust volume": "Adjust volume",
    "Note General": "General",
    "Test": "Test server",
    "Version": "Version",
    "Milking production compared to 24h": "Milking production compared to 24h",
    "OpenWaitArea": "Farm - open to wait area",
    "test": "test",
    "No cows": "No animals",
    "Latest 24h:": "Latest 24h:",
    "Milking yield": "Milking yield",
    "VC": "VMS Controller",
    "New password": "New password",
    "Wrong credentials": "Wrong credentials",
    "Dismiss": "Dismiss",
    "Note CowMonitor": "CowMonitor",
    "Vc version": "Vc version",
    "publish_xlat_nothing": "_",
    "Confirmed": "Confirmed",
    "Edit user": "Edit user",
    "Yel": "Yel",
    "Reprod_Fresh": "Fresh",
    "Use imperial units": "Use imperial units",
    "Yes": "Yes",
    "Legal": "Legal",
    "yesterday": "yesterday",
    "No cows to display": "No cows to display",
    "Language": "Language",
    "Farms": "Farms",
    "All groups": "All groups",
    "All Cows": "All Cows",
    "Password": "Password",
    "Reprod_Pregnant": "Pregnant",
    "Vibration": "Vibration",
    "Do you want to confirm this alarm?": "Do you want to confirm this alarm?",
    "Overview": "Overview",
    "OCC": "OCC",
    "Accept updated End User License Agreement for user": "Accept updated End User License Agreement for user",
    "Yield lb (~/24h)": "Yield lb (~/24h)",
    "Include in FetchCows": "Include in FetchCows",
    "Last contact": "Last contact",
    "Got problem sending e-mail with new password!": "Problem sending e-mail with new password!",
    "Milk dest": "Milk dest",
    "Robot": "Milk station",
    "Kick off": "Kick off",
    "Sharp": "Sharp",
    "No milkings": "No milkings",
    "Check": "Check",
    "Users": "Users",
    "Subscribe": "Send to me",
    "Remove": "Remove",
    "7d": "7d",
    "occ": "occ",
    "Delete mark for animal": "Delete comment for animal",
    "Sorry, you have no permission for this...": "No permission for this operation",
    "Reprod_Open": "Open",
    " if it exists. Please press the link in the email to reset your password.": " if it exists. Please press the link in the email to reset your password.",
    "Stop show": "Stop show",
    "e-mail": "E-mail",
    "Verify password": "Verify password",
    "Reprod_undefined": "Reprod_undefined",
    "Settings": "Settings",
    "Messages": "Messages",
    "Missing DelPro user $email": "Missing DelPro user $email",
    "Flow lb/m": "Flow lb/m",
    "Yield LR": "Yield LR",
    "Login": "Log in",
    "Grazing": "Grazing",
    "Recieve notifications": "Recieve notifications",
    "Try to open wait area...": "Opening wait area...",
    "Animal": "Animal",
    "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday": "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
    "Legal End Customer Link": "http://www.delavalcorporate.com/globalassets/legal/delaval-software-and-online-service-terms-of-use.pdf",
    "Reset password successfull": "Reset password successfull",
    "Yield LF": "Yield LF",
    "Cancel": "Cancel",
    "Change server": "Change server",
    "Duration mm:ss": "Duration mm:ss",
    "Note StatusBoard": "Status board",
    "Profile": "Profile",
    "Refresh": "Refresh",
    "Reprod_Dry": "Dry",
    "Legal Software Link": "http://www.delavalcorporate.com/globalassets/legal/delaval-software-and-online-service-data-and-privacy-statement.pdf",
    "End User License Agreement (EULA) for the service DeLaval MyFarm has been updated": "End User License Agreement (EULA) for the service DeLaval MyFarm has been updated",
    "The server could not be reached, do you wish to reload?": "The server could not be reached, do you wish to reload?",
    "Total yield": "Total yield",
    "Reprod_Heifer": "Heifer",
    "kg": "kg",
    "Forgot password": "Forgot password",
    "My phone notifications": "Notifications to my devices",
    "Custom server": "Custom server",
    "Alarms": "Alarms",
    "OS version": "OS version",
    "Daily milk production (24h)": "Daily milk production (24h)",
    "Red/Yel/Whi percent": "Red/Yel/Whi percent",
    "Type": "Type",
    "Note Reproduction": "Reproduction",
    "lb": "lb",
    "of expected": "of expected",
    "Try to close wait area...": "Closing wait area...",
    "-Now-": "-Now-",
    "e-mail address": "E-mail address",
    "Reset password for": "Reset password for",
    "Mark animal with optional comment": "Mark animal, comment is optional",
    "New": "New",
    "codes": "codes",
    "Milking took": "Milking took",
    "Note Health": "Health",
    "Milk,FeedOnly,PassThrough,Unselected": "Milk,FeedOnly,PassThrough,Unselected",
    "min_min": "m",
    "min_sec": "s",
    "M:": "M:",
    "Send": "Send",
    "Legal End Customer": "DeLaval Software and Online Service Terms of Use",
    "Note Feeding": "Feeding",
    "Whi": "Whi",
    "from": "from",
    "Reset": "Reset",
    "Are you sure you want to send a new automatically generated password to user by e-mail?": "Are you sure you want to send a new automatically generated password to user by e-mail?",
    "localhost": "localhost",
    "App server": "App server",
    "must not contain spaces": "must not contain spaces",
    "OCC (tendency, not scaled)": "OCC (tendency, not scaled)",
    "at least 8 char": "at least 8 characters",
    "No milk data": "No milk data",
    "Active": "Active",
    "mdi": "mdi",
    "Reset password failed": "Reset password failed",
    "Save": "Save",
    "Role_Nothing": "Nothing",
    "Notifications": "Notifications",
    "below to accept": "below to accept",
    "Quiet time": "Quiet time",
    "Prod time": "Milking interval",
    "Are you sure you want to open to wait area?": "Open to wait area? Max number of animals in wait area can be exceeded.",
    "Phone": "Device",
    "Inactive": "Inactive",
    "First name": "First name",
    "Weight unit": "Weight unit",
    "Legal Software": "DeLaval Software and Online Service Data and Privacy Statement",
    "Incomplete": "Incomplete",
    "No": "No",
    "No milkings to display": "No milkings to display",
    "Yield RR": "Yield RR",
    "Error": "Error",
    "Cleaning": "Cleaning",
    "Yield kg (~/24h)": "Yield kg (~/24h)",
    "Change": "Change",
    "Date": "Date",
    "Today": "Today",
    "Accept user EULA": "Accept user EULA",
    "An email has been set to to ": "An email has been set to to ",
    "Yield RF": "Yield RF",
    "Ok": "Ok",
    "Note Milking": "Milking"
  }
};
const PRODUCTION = (process.env.NODE_ENV != 'development');

Vue.use(VueI18n);

const Axios = require("axios");
Axios.defaults.withCredentials = true;
const myfarm2Server = location.protocol + "//myfarm2.vms.delaval.com";
export default new VueI18n({
  locale: 'en',
  // fallbackLocale: 'fallbackMessages',
  // silentFallbackWarn: true,
  // messages,
  missing: function(lang, key, vue, values) {

    if (messageCache.onFly[key]) {
      // PRODUCTION || console.log("i18n returning on fly cached message:" + messageCache.onFly[key] + " for key: " + key);
      return messageCache.onFly[key];
    }

    let fallbackText = messageCache.fallback[key] || key;
    messageCache.onFly[key] = fallbackText;

    const url = myfarm2Server + "/Delaval/mvc/SrvLanguage/getMyPageText";
    const textReq = JSON.stringify({ page: "/myfarmview.html", text: key });
    Axios.post(url, textReq).then((res) => {
      PRODUCTION || console.warn("Got on fly translation " + res.data + " for key: " + key);
      messageCache.onFly[key] = res.data;
      if (vue)
        vue.$forceUpdate();
    }).catch((err) => console.error(err));
    
    return fallbackText;
  },
})