<template>
  <v-container fluid>
    <v-row>
      <v-textarea v-model="text" ref="textarea" :label="$t('Write something ...')" :background-color="theme.chat_bgWhite" no-resize outlined hide-details @keydown="setChanged()"></v-textarea>
    </v-row>
    <v-row>
      <v-col>
        <v-btn color="button" dark @click="emojiPicker = !emojiPicker">
          <v-icon>mdi-emoticon-happy-outline</v-icon>
        </v-btn>
      </v-col>
      <v-spacer></v-spacer>
      <v-col v-if="chat" cols="auto">
        <v-btn class="mr-2" color="button" dark :disabled="disabled" @click="changeMessage()">{{ $t("Change") }}</v-btn>
        <v-btn color="button" dark @click="deleteMessage()">{{ $t("Delete") }}</v-btn>
      </v-col>
      <v-col v-else cols="auto">
        <v-btn color="button" dark :disabled="disabled" @click="addMessage()">{{ $t("Send") }}</v-btn>
      </v-col>
    </v-row>
    <EmojiPicker :dialog="emojiPicker" @click="useEmoji" />
  </v-container>
</template>

<script>
import Names from "@/services/Names.js";
import Chat from "@/services/Chat.js";
import EmojiPicker from "@/components/chat/EmojiPicker.vue";

export default {
  components: {
    EmojiPicker,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      text: "",
      chat: null,
      emojiPicker: false,
      changed: false,
    };
  },

  watch: {
    "$store.state.chat.selectedId": function (currentId, previousId) {
      if (currentId && this.$store.state.farmData.data.messages) {
        this.chat = this.$store.state.farmData.data.messages.list.find((chat) => chat.id === currentId);
        this.text = this.chat.text;
      } else {
        this.chat = null;
        this.text = "";
        this.changed = false;
      }
    },
  },

  computed: {
    disabled: {
      get() {
        return !this.changed || this.text.length == 0;
      },
    },
  },

  methods: {
    setChanged() {
      this.changed = true;
    },

    useEmoji(code) {
      console.log("useEmoji: code=" + code);
      const textarea = this.$refs.textarea.$refs.input;
      const sentence = textarea.value;
      const len = sentence.length;
      //console.log("useEmoji: len=" + len);
      let pos = textarea.selectionStart;
      //console.log("useEmoji: selectionStart_1=" + pos);
      if (pos === undefined) pos = 0;
      const before = sentence.substr(0, pos);
      const after = sentence.substr(pos, len);
      this.text = before + code + after;
    },

    changeMessage() {
      Chat.changeMessage(this.text, this.chat.id);
      this.$store.commit(Names.setSelectedChatId, null);
      this.chat = null;
      this.text = "";
    },

    deleteMessage() {
      Chat.deleteMessage(this.chat.id);
      this.chat = null;
      this.text = "";
    },

    addMessage() {
      Chat.addMessage(this.text);
      this.text = "";
    },
  },
};
</script>

