import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Names from "@/services/Names.js";
import i18n from "./plugins/i18n.js";
import uc from "./plugins/UnitConverter.js";

Vue.config.productionTip = false;
Vue.prototype.$mutations = Names;
Vue.use(uc);

function getPersistent(key) {
  try {
    return JSON.parse(localStorage.getItem(Names.storePrefix + key));
  } catch (e) {
    return null;
  }
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created() {
    Names.localStorageParams.forEach(function (e) {
      let value = getPersistent(e.key);
      if (value) store.commit(e.key, value);
      else store.commit(e.key, e.def);
    });
  },
  render: (h) => h(App),
}).$mount("#app");
