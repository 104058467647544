<template>
  <v-navigation-drawer v-model="menu" app right temporary>
    <v-list nav dense>
      <v-list-item-group>
        <v-list-item @click="$router.push('/settings')">
          <v-list-item-content>
            <v-list-item-title>My settings</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <!-- TODO: Do we need a version page? -->
        <!-- <v-list-item @click="$router.push('/version')">
          <v-list-item-content>
            <v-list-item-title>Version</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider /> -->

        <v-list-item @click="$router.push('/grazing')">
          <v-list-item-content>
            <v-list-item-title>Grazing</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item @click="$router.push('/overview')">
          <v-list-item-content>
            <v-list-item-title>Overview</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item @click="$router.push('/users')">
          <v-list-item-content>
            <v-list-item-title>Users</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item @click="$router.push('/profiles')">
          <v-list-item-content>
            <v-list-item-title>Profiles</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />

        <v-list-item link>
          <v-list-item-content class="tile" @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="pb-2" />

        <v-list-item>
          <v-list-item-content>
            <v-text-field label="Farm:" placeholder="type to search" v-model="farmSearch" clearable />
          </v-list-item-content>
        </v-list-item>

        <v-divider />

        <v-list-item-group v-model="selectedFarm" return-object active-class="deep-purple--text text--accent-4">
          <v-list-item v-for="farm in getFarms" :key="farm.vcGUID" :value="farm">
            <v-list-item-content>
              <v-list-item-title @click="closeMenu()">{{ farm.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Names from "@/services/Names.js";
import LoginSvc from "@/services/LoginService.js";

export default {
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      farmSearch: "",
    };
  },

  watch: {
    drawer: function (newVal, oldVal) {
      this.menu = true;
    },
  },

  computed: {
    getFarms: {
      get() {
        return this.$store.state.farms.data.farms.filter((f) => !this.farmSearch || f.name.toLowerCase().includes(this.farmSearch.toLowerCase())).slice(0, 20);
      },
    },
    selectedFarm: {
      get() {
        if (this.$store.state.selectedFarm) {
          console.log("getSelectedFarm ", this.$store.state.selectedFarm);
          return this.$store.state.selectedFarm;
        } else {
          return "";
        }
      },
      set(value) {
        console.log("setSelectedFarm ", value);
        if (value) {
          this.$store.commit(Names.selectedFarm, value);
        } else {
          //This is when same farm is selected again... The Object.assign triggers reload of farmdata
          this.$store.commit(Names.selectedFarm, Object.assign({}, this.selectedFarm));
        }
      },
    },
  },

  methods: {
    logout() {
      LoginSvc.logout();
      this.menu = false;
    },

    closeMenu() {
      this.menu = false;
    },
  },
};
</script>


