<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row dense>
      <p class="category">{{ $t("Profile") }}</p>
    </v-row>
    <v-row dense>
      <v-divider class="mb-2" />
    </v-row>

    <v-row dense>
      <v-col cols="4">
        <!-- TODO: Fix prettier auto format! Don't think its possibel to keep tags on same line in this case... -->
        <v-row dense>
          <p>{{ $t("First name") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("Last name") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("e-mail") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("Language") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("Weight unit") }}</p>
        </v-row>
        <v-row dense v-if="cognitoToken">
          <p>Link with Delpro+</p>
        </v-row>
      </v-col>
      <v-col>
        <v-row dense class="mb-1"><v-text-field v-model="firstName" :rules="requiredRules" outlined dense hide-details :background-color="theme.settings_bgWhite" /></v-row>
        <v-row dense class="mb-1"><v-text-field v-model="lastName" :rules="requiredRules" outlined dense hide-details :background-color="theme.settings_bgWhite" /></v-row>
        <v-row dense class="mb-1"><v-text-field v-model="email" :rules="emailRules" outlined dense hide-details :background-color="theme.settings_bgWhite" /></v-row>
        <v-row dense><NativeSelect v-model="language" :items="languages" item-value="languageCode" item-text="languageLabel" dense single-line hide-details outlined :background-color="theme.common_bgButton" /></v-row>
        <v-row dense>
          <v-radio-group class="ma-0 mt-2 pa-0" v-model="unit" row hide-details>
            <v-radio :label="$t('kg')" :value="false" />
            <v-radio :label="$t('lb')" :value="true" />
          </v-radio-group>
        </v-row>
        <v-row dense class="mt-5" v-if="cognitoToken">
          <v-btn class="mr-2" @click="link()" color="button" dark>Link</v-btn>
          <v-btn color="button" @click="unlink()" dark>Unlink</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Names from "@/services/Names.js";
import NativeSelect from "@/components/NativeSelect.vue";
import Cognito from "@/services/shared/Cognito.js";

const Axios = require("axios");
Axios.defaults.withCredentials = true;

export default {
  name: "Profile",

  components: {
    NativeSelect,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      // TODO: Perhaps show hint (remove hide-detals). But it breaks the layout...
      requiredRules: [(v) => !!v || false],
      emailRules: [(v) => !!v || "E-mail is required", (v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
    };
  },

  methods: {
    link: function () {
      let jwt = Cognito.getCognitoToken();
      Cognito.link(jwt);
    },

    unlink: function () {
      let jwt = Cognito.getCognitoToken();
      Cognito.unlink(jwt);
    },
  },

  computed: {
    cognitoToken: {
      get() {
        return Cognito.getCognitoToken();
      },
    },

    firstName: {
      get() {
        return this.data.firstName;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.profile_FirstName, value);
      },
    },

    lastName: {
      get() {
        return this.data.lastName;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.profile_LastName, value);
      },
    },

    email: {
      get() {
        return this.data.email;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.profile_Email, value);
      },
    },

    languages: {
      get() {
        if (this.data.languages) return this.data.languages;
        else return [];
      },
    },

    language: {
      get() {
        if (this.$store.state.settings.config.language) return this.$store.state.settings.config.language;
        else return "en-us";
      },
      set(languageCode) {
        this.$store.commit(this.$mutations.settings.profile_Language, languageCode);
        const url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvUser/saveUserLanguage";
        Axios.post(url, JSON.stringify(languageCode))
          .then((res) => {
            const url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvLanguage/newPageTranslation";
            Axios.post(url, JSON.stringify({ page: "/myfarmview.html", text: languageCode }))
              .then((res) => {
                this.$store.commit(Names.setTranslationMessages, { messages: res.data });
              })
              .catch((err) => console.error(err));
          })
          .catch((err) => console.error(err));
      },
    },

    unit: {
      get() {
        return this.data.useImperialUnits;
      },
      set(value) {
        this.$store.commit(this.$mutations.settings.profile_Unit, value);
      },
    },

    data: {
      get() {
        return this.$store.state.settings.config.data;
      },
    },
  },
};
</script>

<style scoped>
.category {
  margin: 0px;
  padding: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
p {
  margin: 0px;
  padding: 5px 0px 16px 0px;
}
</style>