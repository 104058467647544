import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

const rgb = {
  red: "#ff3200",
  green: "#85b430",
  yellow: "#f7b320",
  blue: "#103d82",
  blue_2: "#00a6ff",
  blue_3: "#1e88e5",
  white: "#ffffff",
  gray: "#7f7f7f",
  gray_1: "#cccccc",
  gray_2: "#e5e5e5",
  gray_3: "#575757",
  black: "#000000",
};

const common = {
  common_bgBlue: rgb.blue,
  common_bgGray: rgb.gray_2,
  common_bgButton: rgb.gray_3,
  common_linkBlue: rgb.blue_3,
};

const settings = {
  settings_bgWhite: rgb.white,
};

const chat = {
  chat_bgGray: rgb.gray_2,
  chat_bgWhite: rgb.white,
  chat_bgSelected: rgb.gray_1,
  chat_textBlue: rgb.blue,
  chat_textGray: rgb.gray,
  chat_textBlack: rgb.black,
};

const alarms = {
  alarms_bgGray: rgb.gray_2,
  alarms_bgBlue: rgb.blue_2,
  alarms_bgRed: rgb.red,
  alarms_textWhite: rgb.white,
  alarms_textBlack: rgb.black,
};

Vue.use(Vuetify, {
  options: {
    customProperties: true,
  },
});

function getTheme() {
  let theme = {
    theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          //primary: '#FFFFFF',
          // primary: '#1976D2',
          // secondary: '#424242',
          // accent: '#82B1FF',
          error: "#ff3300", //'#FF5252',
          button: "#575757", //"#878787",
          // info: '#2196F3',
          // success: '#4CAF50',
          // warning: '#FFC107',

          PassThrough: "#bee646",
          FeedOnly: "#77ad1c",
          Milk: "#d8d8d8",
          MilkPermission: "#ffd300",
          MilkPermissionNeverMilked: "#fcb577",
          MilkOverdue: "#ff3300",

          backgroundGray: rgb.gray_2,

          sortAscTitle: "#77ad1c",
          sortDescTitle: "#00a6ff",
        },
      },
    },
  };
  Object.assign(theme.theme.themes.light, rgb);
  Object.assign(theme.theme.themes.light, common);
  Object.assign(theme.theme.themes.light, chat);
  Object.assign(theme.theme.themes.light, alarms);
  Object.assign(theme.theme.themes.light, settings);
  console.log("THEME:", theme);
  return theme;
}

export default new Vuetify(getTheme());
