export class CodeSet {
  static propertyNamesInOrder = [
    'key',
    'name',
  ];
  
  constructor (serialData) {
    let fields = serialData.split("$");
    let [CodeCount] = fields.splice(0, 1);
    while (CodeCount--) {
      let [key, name] = fields.splice(0, 2);
      this[key] = name.trim();
    }
  }
}