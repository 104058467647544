import Common from "@/services/Common.js";
import Names from "@/services/Names.js";
import DataSvc from "@/services/DataService.js";
import Cognito from "@/services/shared/Cognito.js";
const Axios = require("axios");
Axios.defaults.withCredentials = true;
var Store;
var loginTimeout;

const myfarmMainServer = location.protocol + "//myfarm.delaval.com";
const myfarm2Server = location.protocol + "//myfarm2.vms.delaval.com";

// Cookie contents:
// 1. sessionId: If exists this is used as identifier in all rest calls (max 5 min between calls)
// 2. languageCode
// 3. emailAddress
// 4. pwd (id)
// 5. isApp
// 6. farm (vcGUID, farmId)
// 7. profile

function startLogin(user, pwd) {
  clearTimeout(loginTimeout);
  loginTimeout = setTimeout(() => failLogin("Login timed out"), 60000);
  Store.state.servers.loginList.forEach((server) => {
    new Login(server, user, pwd);
  });
}

function startLogout() {
  clearTimeout(loginTimeout);
  loginTimeout = setTimeout(() => failLogout("Logout timed out"), 30000);
  Store.state.servers.loginList.forEach((server) => {
    console.log("Starting logout for " + server);
    new Logout(server);
  });
}

class Login {
  constructor(server, user, pwd) {
    this.server = server;
    this.getSeed(user, pwd);
    this.isSelectedServer = this.server.url === Store.getters.getServerForSelectedFarm;
  }

  // Login step 1/3
  getSeed(user, pwd) {
    let url = this.server.url + "/seed.vcx?ver=0"; // TODO: Use ver=28 ?
    console.log("getSeed: user=" + user, url);
    return Axios.post(url, JSON.stringify({ usr: user }))
      .then((res) => {
        console.log("getSeed res=", res);
        this.getId(res.data, user, pwd);
      })
      .catch((err) => failLogin(err.message));
  }

  // Login step 2/3
  getId(seed, user, pwd) {
    console.log("getId: seed=" + seed + " user=" + user + " pwd=" + pwd);
    Store.commit(Names.userId, Common.getPwdId(seed, pwd));
    localStorage.setItem(Names.storePrefix + Names.userId, JSON.stringify(Store.state.auth.id)); // TODO: Need to store id to be able to change password after page is refreshed (when already logged in)
    let url = this.server.url + "/seed.vcx?ver=0"; // TODO: Use ver=28 ?
    let payload = {
      id: Store.state.auth.id,
      phoneRegisterData: null, // TODO: Check...
      url: null,
      usr: user,
    };
    return Axios.post(url, JSON.stringify(payload))
      .then((res) => {
        console.log("getId: res=", res);
        if (res.data === "Ok") {
          this.getCookie(Store.state.auth.id, user);
        } else {
          console.error("getId: Wrong credentials");
          Store.state.auth.wrongCreds = true;
        }
      })
      .catch((err) => failLogin(err.message));
  }

  // Login step 3/3
  getCookie(id, user) {
    console.log("getCookie: id=" + id + " user=" + user);
    let url = this.server.url + "/seed.vcx?ver=0"; // TODO: Use ver=28 ?
    let payload = {
      id: id,
      passa: 1, // TODO: Check...
      url: true,
      usr: user,
    };
    return Axios.post(url, JSON.stringify(payload))
      .then((res) => {
        console.log("getCookie: res=", res);
        if (res.data.success === true) {
          console.info("getCookie: You are now successfully logged in at " + this.server.url);
          this.server.isAuthenticated = true;
          Store.commit(Names.updateLoginServer, this.server);
          let isAuthenticatedOnAllServers = true;
          Store.state.servers.loginList.forEach((server) => {
            if (!server.isAuthenticated) isAuthenticatedOnAllServers = false;
          });
          if (isAuthenticatedOnAllServers) {
            console.error("You are now successfully logged in at all servers");
            getNewSession();
          }
        } else if (res.data.reason === "changePwd") {
          if (this.isSelectedServer) {
            console.log("getCookie: force user to change password");
            Store.state.auth.changePwd = true;
            getNewSession();
          }
        } else if (res.data.reason === "eula_user") {
          if (this.isSelectedServer) {
            console.log("getCookie: get new user to accept eula");
            Store.state.auth.acceptEula = true;
            getNewSession();
          }
        } else {
          failLogin("Wrong credentials");
        }
      })
      .catch((err) => failLogin(err.message));
  }
}
class Logout {
  constructor(server) {
    this.server = server;
    this.logout();
  }

  logout() {
    let url = this.server.url + "/logout.vcx?ver=28";
    console.log("logout: url=" + url);
    return Axios.post(url)
      .then((res) => {
        console.log("logout res=", res);
        if (res.data === "Ok") {
          console.info("logout: You are now successfully logged out from " + this.server.url);
          this.server.isAuthenticated = false;
          Store.commit(Names.updateLoginServer, this.server);
          let isAuthenticatedOnAllServers = false;
          Store.state.servers.loginList.forEach((server) => {
            if (server.isAuthenticated) isAuthenticatedOnAllServers = true;
          });
          if (!isAuthenticatedOnAllServers) {
            console.error("You are now successfully logged out from all servers");
            localStorage.removeItem(Names.storePrefix + Names.userId);
            setAuthorized(false);
          }
        }
      })
      .catch((err) => console.error("Logout failed on " + this.server.url, err.message));
  }
}

// Init step 1/3
function getNewSession() {
  console.log("getNewSession");
  let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvLanguage/initPageData";
  return Axios.post(url, '"/myfarmview.html"')
    .then((res) => {
      console.info("getNewSession res=", res);
      let language = JSON.parse(localStorage.getItem(Names.storePrefix + Names.settings.profile_Language));
      setLanguage(language);
    })
    .catch((err) => console.error(err));
}

// Init step 2/3
function setLanguage(language) {
  console.log("setLanguage: language=", language);
  const url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvUser/saveUserLanguage";
  let languageCode = language && language.languageCode ? language.languageCode : "en-us";
  Axios.post(url, '"' + languageCode + '"')
    .then((res) => {
      console.info("setLanguage res=", res);
      getLanguage(languageCode);
    })
    .catch((err) => console.error(err));
}

// Init step 3/3
function getLanguage(languageCode) {
  console.log("getLanguage: language=", languageCode);
  const url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvLanguage/newPageTranslation";
  Axios.post(url, JSON.stringify({ page: "/myfarmview.html", text: languageCode }))
    .then((res) => {
      console.info("getLanguage res=", res);
      if (Store.state.auth.changePwd || Store.state.auth.acceptEula) {
        DataSvc.preloadMyUserSettings();
        Store.commit(Names.setTranslationMessages, { messages: res.data });
      } else {
        setAuthorized(true);
        Store.commit(Names.setTranslationMessages, { messages: res.data });
      }
    })
    .catch((err) => console.error(err));
}

function getLoginServersAndTranslation(isAuthenticated) {
  let url = myfarm2Server + "/Delaval/mvc/SrvLanguage/initPageData";
  console.log("getLoginServersAndTranslation: url=" + url);
  return Axios.post(url, '"/myfarmview.html"')
    .then((res) => {
      console.info("getLoginServersAndTranslation isAuthenticated=" + isAuthenticated + " res=", res);
      let servers = res.data.serverUrlList;
      Store.commit(Names.setLoginServers, { servers, isAuthenticated });
      Store.commit(Names.setTranslationMessages, { messages: res.data.pageText });
    })
    .catch((err) => console.error(err));
}

function tryCognitoLogin() {
  clearTimeout(loginTimeout);
  let jwt = Cognito.getCognitoToken();
  if (!jwt) {
    failLogin("No Cognito Token");
    return;
  }
  loginTimeout = setTimeout(() => failLogin("Cognito login timed out"), 30000);
  let promises = Store.state.servers.loginList.map((server) => Cognito.login(jwt, server));
  Promise.all(promises).then((servers) => {
    let totalSuccess = servers.every((server) => server.isAuthenticated);
    servers.forEach((server) => {
      console.log("Login " + server.url + " result:" + server.isAuthenticated);
      Store.commit(Names.updateLoginServer, server);
    });
    if (totalSuccess) {
      console.error("You are now successfully Cognito logged in at all servers");
      getNewSession();
    } else {
      failLogin("Cognito login failed");
    }
  });
}

function setAuthorized(state, message) {
  clearTimeout(loginTimeout);
  Store.commit("login", { state: state, message });
}

function failLogin(err) {
  console.warn("Login failed", err);
  startLogout();
}

function failLogout(err) {
  console.warn("Logout failed", err);
  setAuthorized();
}

function initialize() {
  let url = myfarmMainServer + "/Delaval/mvc/SrvUser/getMe";
  Axios.post(url, "")
    .then((res) => {
      console.log("Got ME, we have cookie!", res);
      getLoginServersAndTranslation(true).then(() => getNewSession());
    })
    .catch((err) => {
      console.warn("Me call failed == No cookie...", err);
      getLoginServersAndTranslation(false).then(() => tryCognitoLogin());
    });
}

export default {
  initialize(store) {
    Store = store;
    return initialize();
  },

  login({ email, password }) {
    console.log("login: email=" + email + " password=" + password);
    startLogin(email, password);
  },

  logout() {
    console.log("logout: called");
    startLogout();
  },
};
