<template>
  <v-container class="pt-2 pl-2">
    
      <template v-if="mode == 'overview'">
        <v-row class="">
          <v-col :cols="portrait ? 12 : 5" class="pt-2">
            <VcProduction :ms="+ms" />
          </v-col>
          <v-col :cols="portrait ? 12 : 'auto'" class="pa-0">
            <v-divider :vertical="!portrait"/>
          </v-col>
          <v-col :cols="portrait ? 12 : 5" class="pt-1">
            <AnimalProduction :ms="+ms" />
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row class="pt-n2">
          <v-col cols="12" class="pb-0 mt-2">
            <NativeSelect
              :backgroundColor="theme.button"
              class="rounded-lg"
              v-model="ms"
              :items="dataOptions"
              itemText="ms"
              itemValue="index"
            />
          </v-col>
          <v-col cols="12" sm="5" class="pt-0 ml-2">
            <VcProduction :ms="+ms" />
          </v-col>
          <v-col cols="12" sm="auto pt-0">
            <v-divider :vertical="!singleRow" />
          </v-col>
          <v-col cols="12" sm="5" class="pt-0 ml-3">
            <AnimalProduction :ms="+ms" />
          </v-col>
        </v-row>
      </template>
    
  </v-container>
</template>

<script>
import VcProduction from "@/components/production/VcProduction.vue";
import AnimalProduction from "@/components/production/AnimalProduction.vue";
import NativeSelect from "@/components/NativeSelect.vue";
import Common from "@/services/Common.js";

export default {
  name: "Production",

  components: {
    VcProduction,
    AnimalProduction,
    NativeSelect,
  },
  props: {
    mode: {
      type: String,
    },
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      ms: -1,
      portrait: this.$store.state.isPortraitMode,
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
  },
  methods: {
    
  },
  computed: {
    singleRow: {
      get() {
        let size = this.$vuetify.breakpoint.name;
        return size=='xs';// ||  size=='sm';
      }
    },
    dataOptions: {
      get() {
        if (!this.$store.state.farmData.data.sevenDays)
          return [];
        return [{index:-1,ms: this.$store.state.selectedFarm.name}, ...this.$store.state.farmData.data.sevenDays.jsRobots.map( (ms,index) => ({index, ms:ms.name})).sort((o1, o2) => Common.sortText(o1.ms, o2.ms))];
      }
    }
  },
};
</script>
<style scoped>

</style>