import Parse from "@/services/shared/Parse.js"
export class Area {
  static propertyNamesInOrder = [
    'AreaGuid',
    'AreaType',
    'AreaName',
  ];

  constructor(dataArray) {
    let f = dataArray.splice(0, Area.propertyNamesInOrder.length);
    Area.propertyNamesInOrder.forEach((property, index) => this[property] = f[index]);
  }

}