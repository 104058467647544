<template>
  <v-container fluid class="my-12 px-12 pt-12">
    <v-row><v-spacer /><v-img max-width="213" :src="require('@/assets/delaval_high_res.png')" /><v-spacer /></v-row>
    <v-row><v-spacer /><span class="logo-text">MyFarm</span><v-spacer /></v-row>
    <v-row>
      <v-col>
        <v-card :style="{ background: '#e5e5e5' }" class="px-3 py-2 mt-1">
          <v-card-text class="px-3 pb-0 pt-1 pl-6">
            <p class="text ma-0 mt-5">{{ $t("E-mail address") }}</p>
            <v-text-field v-model="email" clearable outlined dense hide-details :background-color="theme.settings_bgWhite" />
            <p class="text ma-0 mt-2">{{ $t("Password") }}</p>
            <v-text-field class="mb-5" v-model="password" type="password" clearable outlined dense hide-details :background-color="theme.settings_bgWhite" />
            <span :style="{ color: theme.common_linkBlue }" class="link" @click="showRequestPwd = true">{{ $t("Forgot password") }}</span>
            <br />
            <a href="https://login.delaval.com/?redirect_uri=https://myfarm2.delaval.com/">{{ $t("Use DeLaval plus login") }}</a>
            <p class="ma-0 mt-4">{{ $t("Language") }}</p>
            <NativeSelect class="mb-6" v-model="language" :items="languages" item-value="languageCode" item-text="languageLabel" dense hide-details :background-color="theme.common_bgButton" />
            <v-btn block @click="$store.commit($mutations.triggerLogin, { email, password })" :disabled="$store.state.isAuthenticated">{{ $t("Login") }}</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="$store.state.auth.wrongCreds" persistent max-width="80%">
      <v-card>
        <v-card-title>
          <p class="ma-0 mp-0">{{ $t("Wrong credentials") }}</p>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn color="button" dark block @click="$store.state.auth.wrongCreds = false">{{ $t("Ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showRequestPwd" persistent max-width="80%">
      <RequestPwd @closed="showRequestPwd = false" />
    </v-dialog>

    <v-dialog v-model="$store.state.auth.changePwd" persistent max-width="80%">
      <ChangePwd :email="email" @pwd="password = $event" />
    </v-dialog>

    <v-dialog v-model="$store.state.auth.acceptEula" persistent max-width="80%">
      <ApproveEula :email="email" :password="password" />
    </v-dialog>
  </v-container>
</template>

<script>
const Axios = require("axios");
Axios.defaults.withCredentials = true;
import Names from "@/services/Names.js";
import RequestPwd from "@/components/RequestPwd.vue";
import NativeSelect from "@/components/NativeSelect.vue";
import ChangePwd from "@/components/ChangePwd.vue";
import ApproveEula from "@/components/ApproveEula.vue";

export default {
  name: "Login",

  components: {
    NativeSelect,
    RequestPwd,
    ChangePwd,
    ApproveEula,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      email: "",
      password: "",
      languages: [],
      showRequestPwd: false,
      currentLanguage: "en-us",
    };
  },

  computed: {
    language: {
      get() {
        return this.currentLanguage;
      },
      set(languageCode) {
        console.log("Login set language", languageCode);
        this.$store.commit(this.$mutations.settings.profile_Language, languageCode);
        // TODO: Add translation on the login page from start, if possible
      },
    },
  },

  mounted() {
    if (this.$store.state.auth.isAuthenticated) this.$router.push("/");
    Axios.post(location.protocol + "//myfarm.delaval.com/Delaval/mvc/SrvLanguage/getLanguages")
      .then((res) => {
        this.languages = res.data.langs;
        this.currentLanguage = JSON.parse(localStorage.getItem(Names.storePrefix + Names.settings.profile_Language));
      })
      .catch((err) => console.error(err));
  },
};
</script>

<style>
.text {
  margin: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
.logo-text {
  color: #fff;
  font-size: 24px;
  margin-left: -12px;
  font-weight: 700;
  text-align: center;
}
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>