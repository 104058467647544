<template>
  <v-container fluid class="mr-0 pr-0">
    <v-overlay :value="overlay"><v-progress-circular indeterminate size="100" /></v-overlay>
    <v-sheet :style="{ height: 'calc(100vh - 80px)', backgroundColor: theme.backgroundGray}" class=" overflow-y-auto overflow-x-hidden mr-1 pr-2">
      
      <v-row>
        <v-col class="text-h3" >
          {{$t('Profile editor')}}
        </v-col>
      </v-row>
      
      <v-row>
        <v-col class="title" >
          {{$t('My profiles')}}
        </v-col>
        <v-col :align-self="'end'" cols="auto">
          <v-btn icon class="mr-2" @click="saveProfiles" :disabled="disableSaveButton()">
            <v-icon>
              mdi-content-save
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <template v-for="(profile, index) in profilesSorted">
        <ProfileRow v-if="!profile.isPredefined"
          :isExpanded="expandedProfile == profile.nameKey"
          :profile="profile" 
          :index="index" 
          :key="profile.nameKey" 
          @expand="expandProfile" 
          @collapse="collapseProfile"
          @undo="revertChanges"
          @clone="copyProfile"
          @delete="deleteProfile"
          />
      </template>
      <v-row>
        <v-col class="title" >
          {{$t('System profiles')}}
        </v-col>
      </v-row>
      <template v-for="(profile, index) in profilesSorted">
        <ProfileRow v-if="profile.isPredefined"
          :isExpanded="expandedProfile == profile.nameKey"
          :profile="profile" 
          :index="index" 
          :key="profile.nameKey" 
          @expand="expandProfile" 
          @collapse="collapseProfile"
          @clone="copyProfile"
          />
      </template>

    </v-sheet>
  </v-container>
</template>

<script>
import ProfileRow from "@/components/profiles/profileRow.vue";
import {parseOwnProfiles} from "@/services/shared/Profile.js";
import {Profile} from "@/services/shared/Profile.js";
const Axios = require("axios");
Axios.defaults.withCredentials = true;

export default {
  name: "Profiles",

  components: {
    ProfileRow
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      expandedProfile: '',
      selectedSortColumn: '',
      overlay: false,
    };
  },
  methods: {
    disableSaveButton() {
      return !!this.profilesSorted.find( p => !this.checkProfileName(p.name)) ||
             !this.profilesSorted.find( p => (p.isDirty() || !p.pristineData));
    },
    expandProfile(nameKey) {
      this.expandedProfile = nameKey;
    },
    collapseProfile(nameKey) {
      this.expandedProfile = '';
    },
    checkProfileName(name, limit = 1)
    {
      return (this.profilesSorted.filter( p => p.name == name).length==limit);
    },
    copyProfile(profile, index) {
      this.overlay = true;
      let profileCopy = profile.getClone();
      profileCopy.isPredefined = false;
      let prefix = "#1 - ";
      let i = 1;
      while (!this.checkProfileName(prefix + profile.name, 0) && i<100) {
        prefix = "#"+(i++)+" - ";
      }
      if (i==100)
      {
        console.error("Failed to generate unique profile name")
        return
      }
      profileCopy.name = prefix + profile.name;
      profileCopy.nameKey = prefix + profile.nameKey;
      profileCopy.pristineData = null;
      profileCopy.timeWritten = Math.floor(new Date().getTime()/1000);
      this.profilesSorted.splice(index+1, 0, profileCopy);
      console.log(profileCopy);
      this.overlay = false;
    },
    deleteProfile(profile, index) {
      if (profile.pristineData) {
        profile.isDeleted = true;
      } else {
        this.profilesSorted.splice(index, 1);
      }
    },
    saveProfiles() {
      this.overlay = true;
      let serialData = this.profilesSorted.reduce((sd, p) => p.isPredefined || p.isDeleted ? sd : sd + p.toSerialData(), '');
      let profileObject = {"vcGUID": this.$store.state.selectedFarm.id,"serializedProfiles": serialData}
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvMyFarm/saveProfiles?i=" + new Date().getTime();
      return Axios.post(url, JSON.stringify(profileObject))
        .then((res) => {
          let savedProfiles = parseOwnProfiles(res.data);
          savedProfiles.forEach( serverProfile => {
            let savedProfile = new Profile(serverProfile);
            let storeProfile = this.profilesSorted.find( p => p.name == savedProfile.name)
            if (storeProfile) {
              storeProfile.pristineData = savedProfile.pristineData;
              storeProfile.restoreToPristine();
            } else {
              console.error("Received "+savedProfile.name+" from server but have no such profile locally", savedProfile)
            }
          });
          
          //Delete local profiles that no longer exists on server
          this.profilesSorted.filter( p => p.isDeleted).forEach( dp => this.profilesSorted.splice(this.profilesSorted.findIndex( p=> p.nameKey == dp.nameKey),1));
          
          this.overlay = false;
        })
        .catch((err) => (this.overlay = false, console.log(err)));
    },
    revertChanges(profile, index) {
      if (profile.pristineData) {
        profile.restoreToPristine();
      } else {
        this.profilesSorted.splice(index, 1);
      }
    },
    niceDate(time) {
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      let nice = new Date(+time*1000 - offset).toISOString().substring(0,10)
      return nice;
    },
  },
  computed: {
    profilesSorted: {
      get() {
        return this.$store.state.farmData.data.profiles.data;
      }
    },
  },
};
</script>