<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container fluid>
      <v-row class="mb-2" dense>
        <v-col cols="4" />
        <v-col>
          <!-- TODO: Fix disabled -->
          <v-btn color="button" dark block @click="resetSettings()">{{ $t("Reset") }}</v-btn>
        </v-col>
        <v-col>
          <!-- TODO: Fix disabled + color + theme -->
          <v-btn :disabled="!valid" block @click="saveSettings()">{{ $t("Save") }}</v-btn>
        </v-col>
      </v-row>
      <Profile />
      <Password :reset="resetComponents" @validate="$refs.form.validate()" />
      <Notifications />
      <Farms v-for="farm in getFarms" :key="farm.domainId" :farm="farm" />
      <v-row dense>
        <p class="category">{{ $t("My phones") }}</p>
      </v-row>
      <v-row dense>
        <v-divider class="mb-3" />
      </v-row>
      <Devices v-for="phone in getPhones" :key="phone.id" :phone="phone" />
    </v-container>
  </v-form>
</template>

<script>
import Profile from "@/components/settings/Profile.vue";
import Password from "@/components/settings/Password.vue";
import Notifications from "@/components/settings/Notifications.vue";
import Farms from "@/components/settings/Farms.vue";
import Devices from "@/components/settings/Devices.vue";
import DataService from "@/services/DataService.js";
const Axios = require("axios");
Axios.defaults.withCredentials = true;

export default {
  name: "Settings",

  components: {
    Profile,
    Password,
    Notifications,
    Farms,
    Devices,
  },

  data() {
    return {
      valid: false,
      resetComponents: false,
    };
  },

  mounted() {
    this.$refs.form.validate();
  },

  computed: {
    getFarms: {
      get() {
        if (this.data.notify) return this.data.notify;
        return [];
      },
    },

    getPhones: {
      get() {
        if (this.data.phones) return this.data.phones;
        return [];
      },
    },

    data: {
      get() {
        return this.$store.state.settings.config.data;
      },
    },
  },

  methods: {
    resetSettings() {
      console.log("resetSettings: call");
      DataService.reloadUserSettings();
      this.resetComponents = !this.resetComponents;
    },

    saveSettings() {
      const obj = this.$store.state.settings.config.data;
      const { languages, vcId, vcName, server, notifyMask, volume, backgroundColor, ...data } = obj;
      delete data.deviceNotifications.notifyMask;
      delete data.deviceNotifications.volume;
      data.notify.forEach((o) => {
        delete o.notifyMask;
        delete o.notifyFarmData.notifyMask;
      });
      if (data.phones) {
        data.phones.forEach((o) => {
          delete o.appVer;
          delete o.cre;
          delete o.last;
          delete o.osVer;
          delete o.type;
        });
      }
      // Payload needs to be sent as string, why? Perhaps worth changing on serverside (SrvUser.java in saveUserSettings)
      const stringData = '"' + JSON.stringify(data).replaceAll('"', '\\"') + '"';
      console.log("saveSettings data=", stringData);
      const url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvUser/saveUserSettings";
      Axios.post(url, stringData)
        .then((res) => {
          console.log("saveSettings: res=", res);
          this.$store.commit(this.$mutations.settings.setConfig, res.data);
          this.$store.commit(this.$mutations.selectedFarm, Object.assign({}, this.$store.state.selectedFarm)); //Set farm again (Object.assign creates new object that triggers update watch in app.vue)
        })
        .catch((err) => console.error(err));
    },
  },
};
</script>

<style scoped>
.category {
  margin: 0px;
  font-weight: bold;
  font-size: 1.4em;
}
</style>