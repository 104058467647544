<template>
  <v-dialog v-model="show" persistent max-width="80%">
    <Loader v-if="showLoader" />
    <v-form v-else ref="form" v-model="valid" lazy-validation>
      <v-card>
        <v-card-title>
          <p v-if="user === null" class="ma-0 mp-0">{{ $t("New user") }}</p>
          <p v-else class="ma-0 mp-0">{{ $t("Edit user") }}</p>
        </v-card-title>
        <v-card-text>
          <p class="ma-0 mp-0">{{ $t("First name") }}</p>
          <v-text-field v-model="firstName" :rules="requiredRules" outlined dense :background-color="theme.settings_bgWhite" />
          <p class="ma-0 mp-0">{{ $t("Last name") }}</p>
          <v-text-field v-model="lastName" :rules="requiredRules" outlined dense :background-color="theme.settings_bgWhite" />
          <p class="ma-0 mp-0">{{ $t("e-mail") }}</p>
          <v-text-field v-model="email" :rules="emailRules" outlined dense :background-color="theme.settings_bgWhite" />
          <p class="ma-0 mp-0">{{ $t("Roles") }}</p>
          <!-- TODO: No roles is also a bug in current MyFarm: The New user page does not enable the Add button for "super users", perhaps this is how it should be? -->
          <v-checkbox v-for="(role, index) in roles" :key="role.id" class="ma-0 pa-0" :rules="roleRequiredRules" v-model="selectedRoles" :value="role.id" :label="role.roleName" :hide-details="index !== roles.length - 1" />
          <v-btn v-if="user !== null" class="ma-0 mt-2 mp-0" color="button" dark block @click="showRequestNewPasswordByMail = true">{{ $t("Create new password") }}</v-btn>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-row class="ma-2">
            <v-col class="ma-0 pa-0 pr-1" cols="6">
              <v-btn block @click="cancel()">{{ $t("Cancel") }}</v-btn>
            </v-col>
            <v-col class="ma-0 pa-0 pl-1" cols="6">
              <v-btn v-if="user === null" :disabled="!(valid && !!roles.length)" block @click="saveNewUser()">{{ $t("Add") }}</v-btn>
              <v-btn v-else :disabled="!valid" block @click="saveExistingUser()">{{ $t("Save") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-dialog v-model="showRequestNewPasswordByMail" persistent max-width="60%">
      <v-card>
        <v-card-title>
          <p class="ma-0 mp-0">{{ $t("Are you sure you want to send a new automatically generated password to user by e-mail?") }}</p>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-row class="ma-2">
            <v-col class="ma-0 pa-0 pr-1" cols="6">
              <v-btn block @click="showRequestNewPasswordByMail = false">{{ $t("No") }}</v-btn>
            </v-col>
            <v-col class="ma-0 pa-0 pl-1" cols="6">
              <v-btn block @click="getNewPasswordByMail()">{{ $t("Yes") }}</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showGotNewPasswordByMail" persistent max-width="60%">
      <v-card>
        <v-card-title>
          <p class="ma-0 mp-0">{{ $t("An email has been set to to ") }} {{ email }}</p>
        </v-card-title>
        <v-card-actions class="justify-end">
          <v-btn block @click="showGotNewPasswordByMail = false">{{ $t("Ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
const Axios = require("axios");
Axios.defaults.withCredentials = true;
import DataSvc from "@/services/DataService.js";
import Loader from "@/views/Loader.vue";

export default {
  name: "SaveUser",

  props: {
    user: {
      type: Object,
      default: null,
    },
  },

  components: {
    Loader,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      show: true, // Seems like this is needed ...
      valid: false,
      requiredRules: [(v) => !!v || this.$t("Field is required")],
      emailRules: [(v) => !!v || this.$t("Field is required"), (v) => /.+@.+\..+/.test(v) || this.$t("E-mail must be valid")],
      roleRequiredRules: [(v) => this.selectedRoles.length > 0 || this.$t("Atleast one role is required")],
      firstName: this.getFirstName(),
      lastName: this.getLastName(),
      email: this.getEmail(),
      selectedRoles: this.getSelectedRoles(),
      roles: this.getAllowedRoles(),
      showRequestNewPasswordByMail: false,
      showGotNewPasswordByMail: false,
      showLoader: false,
    };
  },

  mounted() {
    this.$refs.form.validate();
  },

  methods: {
    getFirstName() {
      if (this.user === null) return "";
      else return this.user.firstName;
    },

    getLastName() {
      if (this.user === null) return "";
      else return this.user.lastName;
    },

    getEmail() {
      if (this.user === null) return "";
      else return this.user.email;
    },

    getAllowedRoles() {
      return this.$store.getters["users/getAccessibleRoles"];
    },

    getSelectedRoles() {
      if (this.user === null) return [];
      else return this.user.role.roles;
    },

    cancel() {
      console.log("saveUser: cancel");
      this.$emit("closed");
    },

    saveExistingUser() {
      console.log("saveExistingUser: call");
      this.saveUser(this.user.id, 0, this.user.status);
    },

    saveNewUser() {
      console.log("saveNewUser: call");
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/Users/newUserId";
      Axios.post(url)
        .then((res) => {
          console.log("saveNewUser: res=", res);
          this.saveUser(res.data, 0, 0);
        })
        .catch((err) => console.error(err));
    },

    saveUser(id, pwd, status) {
      console.log("saveUser: id=" + id);
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/Users/saveUser";
      Axios.post(url, JSON.stringify({ id: id, email: this.email, firstName: this.firstName, lastName: this.lastName, target: this.$store.state.users.config.data.target, roles: this.selectedRoles, pwd: pwd, status: status }))
        .then((res) => {
          if (res.data === 1) console.log("saveUser: success", res);
          else console.warn("saveUser: failed", res);
          this.showLoader = true;
          setTimeout(this.reloadUserSettings, 4000); // TODO: Need to wait before ask for all settings... sometimes 4s is not enough!
        })
        .catch((err) => console.error(err));
    },

    reloadUserSettings() {
      console.log("saveUser: reloadUserSettings");
      DataSvc.reloadUserSettings();
      this.showLoader = false;
      this.$emit("closed");
    },

    getNewPasswordByMail() {
      console.log("getNewPasswordByMail: call");
      let url = this.$store.getters.getServerForSelectedFarm + "/Delaval/mvc/Users/newPassword";
      Axios.post(url, JSON.stringify({ target: this.$store.state.users.config.data.target, user: this.$store.state.users.selectedId }))
        .then((res) => {
          console.log("getNewPasswordByMail: res=", res);
          if (res.data === 1) this.showGotNewPasswordByMail = true;
          else console.warn("getNewPasswordByMail: failed", res);
        })
        .catch((err) => console.error(err));
      this.showRequestNewPasswordByMail = false;
    },
  },
};
</script>

<style scoped>
p {
  white-space: pre-wrap;
  word-break: break-word;
}
</style>