<template>
  <v-container fluid>
    <v-row v-if="$store.state.overviewMode">
      <v-sheet :style="{ backgroundColor: theme.alarms_bgGray }" class="overflow-hidden" max-height="600" width="100%">
        <AlarmCard v-for="(alarm, index) in getAlarms" :key="index + alarm.guid" :alarm="alarm" />
      </v-sheet>
    </v-row>
    <v-row v-else>
      <v-sheet :style="{ height: 'calc(100vh - 72px)', backgroundColor: theme.alarms_bgGray }" class="overflow-y-auto ma-2" width="100%">
        <AlarmCard v-for="(alarm, index) in getAlarms" :key="index + alarm.guid" :alarm="alarm" />
      </v-sheet>
    </v-row>
  </v-container>
</template>

<script>
import AlarmCard from "@/components/alarms/AlarmCard.vue";

export default {
  name: "Alarms",

  components: {
    AlarmCard,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
    };
  },

  computed: {
    getAlarms: {
      get() {
        if (this.$store.state.farmData.data.alarms.alarms) return this.$store.state.farmData.data.alarms.alarms.list;
        return [];
      },
    },
  },
};
</script>

<style scoped>
.v-sheet {
  word-break: break-word;
}
</style>