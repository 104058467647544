<template>
  <v-row class="pl-2 pt-3 pb-2 mb-2 pr-2 rounded-lg white" no-gutters >
    <v-col cols="12">
      <v-row no-gutters>
        <v-col class="title truncate">
        <v-icon v-if="profile.isPredefined">
          mdi-cog-outline
        </v-icon>
        <v-icon v-else>
          mdi-account-circle
        </v-icon>
          <span class="ml-1" :class="{'text-decoration-line-through': profile.isDeleted}">{{ profile.name }} <span v-if="!profile.isPredefined && profile.pristineData">({{ profile.userName + ' ' + niceDate(profile.timeWritten)}})</span></span>
        </v-col>

        <v-col :align-self="'end'" cols="auto">
          
          <template>
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon class="mr-2" v-bind="attrs" v-on="on" @click="$emit('clone', profile, index)" :disabled="!profile.pristineData && profile.isDirty()">
                  <v-icon>
                    mdi-file-plus
                  </v-icon>
                </v-btn>
              </template>
              <span>{{$t("Copy to new")}}</span>
            </v-tooltip>
          </template>

          <v-btn icon class="mr-2" v-if="profile.isDirty() && !profile.isPredefined && profile.pristineData" @click="$emit('undo', profile, index)">
            <v-icon>
              mdi-file-undo
            </v-icon>
          </v-btn>

          <v-btn icon class="mr-2" v-if="!profile.isPredefined && !profile.isDeleted && !(profile.isDirty() && profile.pristineData)" @click="$emit('delete', profile, index)">
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>
          
          <v-btn icon class="mr-2" v-if="!isExpanded" @click="$emit('expand', profile.nameKey)">
            <v-icon>
              mdi-chevron-down
            </v-icon>
          </v-btn>
          <v-btn icon class="mr-2" v-else @click="$emit('collapse', profile.nameKey)">
            <v-icon>
              mdi-chevron-up
            </v-icon>
          </v-btn>

        </v-col>
      </v-row>
    </v-col>

    <v-col>
      <v-expand-transition>
        <ProfileDetails v-if="isExpanded" :profile="profile"/>
      </v-expand-transition>
    </v-col>

  </v-row>
</template>

<script>
import ProfileDetails from "@/components/profiles/profileDetails.vue";

export default {
  name: "ProfileRow",

  components: {
    ProfileDetails
  },
  props: {
    profile: { type: Object },
    index: { type: Number },
    isExpanded: { type: Boolean },
  },
  methods: {
    niceDate(time) {
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      let nice = new Date(+time*1000 - offset).toISOString().substring(0,10)
      return nice;
    },
  },
};
</script>
<style scoped>
  .truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
</style>