<template>
  <v-container :style="{ background: isSelected ? theme.chat_bgSelected : theme.chat_bgGray }" fluid>
    <v-row class="pa-1">
      <v-col>
        <v-row>
          <p :style="{ color: isNew ? theme.chat_textBlue : theme.chat_textGray }" class="name ma-0 pa-0">{{ name }}</p>
        </v-row>
        <v-row>
          <p :style="{ color: theme.chat_textGray }" class="date ma-0 mp-0">{{ date }}</p>
        </v-row>
        <v-row>
          <span class="space" :class="{ clamp: $store.state.overviewMode }" :style="{ color: theme.chat_textBlack }" v-html="getHtml()" @click="tryFindCowInfo" />
        </v-row>
      </v-col>
      <v-col v-if="!$store.state.overviewMode" cols="auto">
        <v-row>
          <img class="pointer" :class="[isSelected ? 'rotate-open' : 'rotate-close']" :src="require('@/assets/chat/plus.png')" width="25" @click="edit()" />
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-divider />
    </v-row>
  </v-container>
</template>

<script>
import Names from "@/services/Names.js";

export default {
  name: "ChatCard",

  props: {
    chat: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      name: this.chat.fromName,
      date: this.getTimeStamp(this.chat.time),
      id: this.chat.id,
      isNew: false,
      isSelected: false,
    };
  },

  watch: {
    "$store.state.chat.selectedId": function (currentId, previousId) {
      if (this.id == currentId) this.isSelected = true;
      else this.isSelected = false;
    },
  },

  methods: {
    tryFindCowInfo(e) {
      //console.log("tryFindCowInfo", e.target);
      if (e.target.matches(".cow-info-link")) {
        console.log("tryFindCowInfo: cow=" + e.target.id);
        this.$router.push("/cow/" + e.target.id + "/info");
      }
    },

    getHtml() {
      this.isNew = this.$store.state.overviewMode && this.chat.time > this.$store.state.farmData.data.messages.lastReadTime;
      //console.info("getHtml text=" + this.chat.text);
      let text = this.chat.text.replace(/\n/g, "<br>");
      let parts = [];
      this.$store.state.chat.emojiList.forEach((emo) => {
        let index = 0;
        text.split(emo.code).forEach((hit) => parts.push({ start: (index += hit.length + emo.code.length), emo }));
        parts.pop();
      });
      let html = "";
      let offset = 0;
      parts.sort((o1, o2) => o1.start - o2.start);
      parts.forEach((p) => {
        html += text.substring(offset, p.start - p.emo.code.length) + (p.emo ? "<img class='image' src='" + p.emo.src + "'  />" : "");
        offset = p.start;
      });
      html += text.substring(offset);
      // Replace any possible cow number with a link
      this.$store.state.farmData.data.animals.forEach((animal) => {
        if (html.includes(animal.animalNr)) {
          let linkColor = this.theme.chat_textBlack;
          html = html.replace(new RegExp(animal.animalNr, "g"), "<span style='color: " + linkColor + "; text-decoration:underline; cursor:pointer;' class='cow-info-link' id='" + animal.animalNr + "'>" + animal.animalNr + "</span>");
        }
      });
      //console.log("getHtml html=" + html);
      return html;
    },

    edit() {
      if (this.$store.state.chat.selectedId == this.id) {
        this.$store.commit(Names.setSelectedChatId, null);
      } else {
        console.info("edit id=" + this.id);
        this.$store.commit(Names.setSelectedChatId, this.id);
      }
    },

    getTimeStamp(time_ms) {
      let date = new Date(time_ms);
      return date.toLocaleString("sv-SE", {
        timeStyle: "short",
        dateStyle: "short",
      });
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
.name {
  font-weight: bold;
  font-size: 1.2em;
}
.date {
  font-size: 0.75em;
}
.space {
  line-height: 20px;
}
.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.rotate-open {
  transform: rotate(45deg);
  transition: all 0.5s;
}
.rotate-close {
  transition: all 0.5s;
}
</style>

<style>
.image {
  height: 0.88em;
  margin-left: 2px;
  margin-right: 2px;
}
</style>


