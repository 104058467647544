import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Settings from "../views/Settings.vue";
import Version from "../views/Version.vue";
import Grazing from "../views/Grazing.vue";
import Users from "../views/Users.vue";
import Profiles from "../views/Profiles.vue";
import Overview from "../views/Overview.vue";
import CowQ from "../views/CowQ.vue";
import Chat from "../views/Chat.vue";
import Production from "../views/Production.vue";
import Alarms from "../views/Alarms.vue";
import Milkings from "../views/Milkings.vue";
import Names from "@/services/Names.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
  {
    path: "/version",
    name: "Version",
    component: Version,
  },
  {
    path: "/grazing",
    name: "Grazing",
    component: Grazing,
    beforeEnter: () => {
      window.location.href = router.app.$store.getters.getServerForSelectedFarm + "/graze.vcx?vc=" + router.app.$store.state.selectedFarm.id;
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/profiles",
    name: "Profiles",
    component: Profiles,
  },
  {
    path: "/",
    name: "Overview",
    alias: "/overview",
    component: Overview,
  },
  {
    path: "/cowq",
    name: "CowQ",
    component: CowQ,
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
  },
  {
    path: "/production",
    name: "Production",
    component: Production,
  },
  {
    path: "/alarms",
    name: "Alarms",
    component: Alarms,
  },
  {
    path: "/cow/:animalNr/:component",
    name: "milkings",
    component: Milkings,
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  router.app.$store.commit(Names.setOverviewMode, to.name == "Overview");
  next();
});

// Avoid error log when selecting same menu item again
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated" && !err.message.includes("Avoided redundant navigation to current location")) {
      console.log(err);
    }
  });
};

export default router;
