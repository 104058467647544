const storePrefix = "myfarmvue.";

const userId = "userId";
const triggerLogin = "triggerLogin";
const authenticationMessage = "authenticationMessage";
const selectedFarm = "selectedFarm";
const setFarmChat = "setFarmChat";
const setFarmAlarm = "setFarmAlarm";
const lastAccessedByVc = "lastAccessedByVc";
const isLoadingInitialData = "isLoadingInitialData";
const loadingFarm = "loadingFarm";
const setServers = "setServers";
const setLoginServers = "setLoginServers";
const updateLoginServer = "updateLoginServer";
const setFarms = "setFarms";
const setFarmData = "setFarmData";
const setSelectedChatId = "setSelectedChatId";
const resetChatLastReadTime = "resetChatLastReadTime";
const setOverviewMode = "setOverviewMode";
const setChoosenProfile = "setChoosenProfile";
const setCow = "setCow";
const setOrientation = "setOrientation";
const setTranslationMessages = "setTranslationMessages";

const users = {
  setConfig: "users/setConfig",
  setSelectedId: "users/setSelectedId",
};

const settings = {
  setConfig: "settings/setConfig",
  profile_FirstName: "settings/profile_FirstName",
  profile_LastName: "settings/profile_LastName",
  profile_Email: "settings/profile_Email",
  profile_Language: "settings/profile_Language",
  profile_Unit: "settings/profile_Unit",
  password_Hashed: "settings/password_Hashed",
  notifications_SendToMe: "settings/notifications_SendToMe",
  notifications_Vibration: "settings/notifications_Vibration",
  notifications_QuietTime: "settings/notifications_QuietTime",
  notifications_QuietFromTime: "settings/notifications_QuietFromTime",
  notifications_QuietToTime: "settings/notifications_QuietToTime",
  farms_Alarm: "settings/farms_Alarm",
  farms_Notification: "settings/farms_Notification",
  farms_Message: "settings/farms_Message",
  farms_Internet: "settings/farms_Internet",
  farms_AlarmQuiet: "settings/farms_AlarmQuiet",
  farms_NotificationQuiet: "settings/farms_NotificationQuiet",
  farms_MessageQuiet: "settings/farms_MessageQuiet",
  farms_InternetQuiet: "settings/farms_InternetQuiet",
  devices_Name: "settings/devices_Name",
  devices_Notification: "settings/devices_Notification",
  devices_Delete: "settings/devices_Delete",
};

export default {
  userId,
  users,
  settings,
  storePrefix,
  triggerLogin,
  authenticationMessage,
  selectedFarm,
  isLoadingInitialData,
  loadingFarm,
  setServers,
  setLoginServers,
  updateLoginServer,
  setFarms,
  setFarmData,
  setFarmChat,
  setFarmAlarm,
  lastAccessedByVc,
  setSelectedChatId,
  resetChatLastReadTime,
  setOverviewMode,
  setChoosenProfile,
  setCow,
  setOrientation,
  setTranslationMessages,
  localStorageParams: [
    { key: selectedFarm, def: "" },
    { key: settings.profile_Language, def: "en-us" },
    { key: userId, def: null },
  ],
};
