export class SerialData {
  static escapeChar = '\\';
  
  constructor(data, delimiter = "$") {
    this.data = data
    this.delimiter = delimiter;
    this.index = 0;
    this.nextEscape;
    if(this.data) {
      if((this.nextEscape = this.data.indexOf(SerialData.escapeChar)) < 0) this.nextEscape = this.data.length + 1
    } else this.d = "";
  };

  serialize() {
    let i = -1, o;
    while(++i < arguments.length) {
      o = arguments[i];
      if(o != null) {
        this.d += o.toString().replace(/\u005c/g, "\\\\").replace(this.delimiter, SerialData.escapeChar + this.delimiter) + this.delimiter;
      } else {
        this.d += this.delimiter;
      }
    }
  };

  getString(def) {
    var rVal, i, ifrom = this.index;
    if(ifrom >= this.data.length) return null;
    i = this.data.indexOf(this.delimiter, ifrom);
    if(i > this.nextEscape) {
      rVal = this.data.substring(ifrom, this.nextEscape) + this.data.charAt(this.nextEscape + 1);
      this.index = this.nextEscape + 2;
      if((this.nextEscape = this.data.indexOf(SerialData.escapeChar, this.nextEscape + 2)) < 0)
        this.nextEscape = this.data.length + 1;
      if(this.data.charAt(this.index) == this.delimiter)
        this.index++;
      else rVal += this.getString();
    } else {
      rVal = this.data.substring(ifrom, i);
      this.index = i + 1;
    }
    return(rVal.length===0)?(typeof def==="undefined"?null:def):rVal;
  };

  getInt() {
    return parseInt(this.getString(), 10);
  };

  getHex() {
    return parseInt(this.getString(), 16);
  };

  getDateHex() {
    let d = this.getString();
    return d === null ? null : parseInt(d, 16) * 1000;
  };

  getFloat() {
    return parseFloat(this.getString());
  };

  retRest() {
    return this.data.substr(this.index);
  };

  reset() {
    this.index = 0;
  };

  hasMore() {
    if (this.data == null) return null;
    return this.index < this.data.length;
  };

  getData() {
    return this.d;
  };

  getInputString() {
    return this.data;
  };

  getPos() {
    return this.index;
  };

  setPos(pos) {
    this.index = pos;
  };

  peek() {
    let ii = this.index;
    let rv = this.getString();
    this.index = ii;
    return rv;
  };
};