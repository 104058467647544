import { SerialData } from "./SerialData";
import {Cow} from "@/services/shared/Cow.js"

export class Profile {

  constructor(farmDataProfile) {
    this.isDeleted = false;

    //Columns
    this.sortColumnIndex = farmDataProfile.profileIndex[0];
    let o = farmDataProfile.profileIndex;
    this.columns = [o[1],o[4],o[2],o[5],o[3],o[6]];

    //Limits
    this.sinceMilk = farmDataProfile.sinceMilk;                                                       //!
    this.milkPerm = farmDataProfile.milkPerm;                                                         //!
    this.daysInLactation = farmDataProfile.lactDay & 0xff;                                            //!
    this.hoursSinceLastMilking = (farmDataProfile.lactDay >> 8) & 0xff;                               //!
    this.expYield = farmDataProfile.expYield;                                                         //!
    this.samePlace = farmDataProfile.samePlace;                                                       //!
    this.daysUntilDryOff = farmDataProfile.daysUntilDryOff;                                           //!

    //Flags
    this.HighActivity = (farmDataProfile.activityAndAreaMask & 0x01) == 0x01;                         //!
    this.InWaitArea = !((farmDataProfile.activityAndAreaMask & 0x02) == 0x02);    //Swapped with below  !
    this.InRobotArea = !((farmDataProfile.activityAndAreaMask & 0x04) == 0x04);   //Swapped with above  !
    this.InOtherArea = !((farmDataProfile.activityAndAreaMask & 0x08) == 0x08);                       //!
    this.InUnknownArea = !((farmDataProfile.activityAndAreaMask & 0x10) == 0x10);                     //!
    this.waitAreaSpecialEnabled = (farmDataProfile.activityAndAreaMask & 0x20) == 0x20;
    this.waitAreaSpecialActivated = (farmDataProfile.activityAndAreaMask & 0x40) == 0x40;
    this.incompleteAndMax48h = (farmDataProfile.activityAndAreaMask & 0x380) == 0x80;                 //!
    this.trapCow = (farmDataProfile.activityAndAreaMask & 0x380) == 0x100;                            //!
    this.ExpCalvingDate = (farmDataProfile.activityAndAreaMask & 0x380) == 0x180;                     //!
    this.ExpHeatDate = (farmDataProfile.activityAndAreaMask & 0x380) == 0x200;                        //!
    this.ExpPregCheckDate = (farmDataProfile.activityAndAreaMask & 0x380) == 0x280;                   //!
    this.ExpDryOffDate = (farmDataProfile.activityAndAreaMask & 0x380) == 0x300;                      //!
    this.ToBeCulled = (farmDataProfile.activityAndAreaMask & 0x380) == 0x380;                         //!
    this.HasNotations = (farmDataProfile.activityAndAreaMask & 0x400) == 0x400;                       //!
    this.UseGroups = (farmDataProfile.activityAndAreaMask & 0x800) == 0x800;                          //!
    this.UseAreaType = (farmDataProfile.activityAndAreaMask & 0x1000) == 0x1000;                      //!
    this.UseSpecificAreas = (farmDataProfile.activityAndAreaMask & 0x2000) == 0x2000;
    this.UseAction = (farmDataProfile.activityAndAreaMask & 0x4000) == 0x4000;                        //!
    this.IncompleteAndMilkPermission = (farmDataProfile.activityAndAreaMask & 0x8000) == 0x8000;      //!

    this.action_Milk = (farmDataProfile.action & 0x01) == 0x01;                                       //!
    this.action_FeedOnly = (farmDataProfile.action & 0x02) == 0x02;                                   //!
    this.action_PassThrough = (farmDataProfile.action & 0x04) == 0x04;                                //!
    this.action_Unselected = (farmDataProfile.action & 0x08) == 0x08;                                 //!

    this.cells = farmDataProfile.cells;

    this.groups = farmDataProfile.groups || [];
    this.areas = farmDataProfile.areas || [];

    this.name = farmDataProfile.name;
    this.nameKey = farmDataProfile.nameKey;
    this.userId = farmDataProfile.userId;
    this.userName = farmDataProfile.userName;
    this.timeWritten = farmDataProfile.timeWritten;
    this.isPredefined = farmDataProfile.isPredefined;

    this.pristineData = this.toSerialData();
  }

  compAction() {
    return this.action_Milk | this.action_FeedOnly << 1 | this.action_PassThrough << 2 | this.action_Unselected << 3;
  }

  compLactDay() {
    return this.hoursSinceLastMilking << 8 | this.daysInLactation;
  }

  compProfileIndex() {
    return [this.sortColumnIndex, this.columns[0], this.columns[2], this.columns[4], this.columns[1], this.columns[3], this.columns[5]];
  }

  compActivityAndAreaMask() {
    return (this.HighActivity ?               0x01 : 0) | 
           (this.InWaitArea ?                 0 : 0x02) |
           (this.InRobotArea ?                0 : 0x04) |
           (this.InOtherArea ?                0 : 0x08) |
           (this.InUnknownArea ?              0 : 0x10) |
           (this.waitAreaSpecialEnabled ?     0x20 : 0) |
           (this.waitAreaSpecialActivated ?   0x40 : 0) |
           (this.incompleteAndMax48h ?        0x80 : 0) |
           (this.trapCow ?                    0x100 : 0) |
           (this.ExpCalvingDate ?             0x180 : 0) |
           (this.ExpHeatDate ?                0x200 : 0) |
           (this.ExpPregCheckDate ?           0x280 : 0) |
           (this.ExpDryOffDate ?              0x300 : 0) |
           (this.ToBeCulled ?                 0x380 : 0) |
           (this.HasNotations ?               0x400 : 0) |
           (this.UseGroups ?                  0x800 : 0) |
           (this.UseAreaType ?                0x1000 : 0) |
           (this.UseSpecificAreas ?           0x2000 : 0) |
           (this.UseAction ?                  0x4000 : 0) |
           (this.IncompleteAndMilkPermission ?0x8000 : 0);
  }

  toSerialData() {
    let sd = new SerialData('');
    let msd = new SerialData('','#');
    msd.serialize(this.sinceMilk, this.milkPerm, this.compLactDay(), this.expYield, this.samePlace, this.daysUntilDryOff, this.compActivityAndAreaMask(), this.compAction(), this.cells, 7, ...this.compProfileIndex())
    this.areas.length > 0 ? msd.serialize(this.areas.length, ...this.areas) : msd.serialize(0);
    this.groups.length > 0 ? msd.serialize(this.groups.length, ...this.groups) : msd.serialize(0);
    sd.serialize(this.name, this.userId, this.userName, this.timeWritten, msd.getData(''));
    return sd.getData('');
  }

  restoreToPristine() {
    let pristineProfile = new Profile(parseOwnProfiles(this.pristineData)[0]);
    console.log("pristineProfile", pristineProfile);
    Object.assign(this, pristineProfile);
  }

  getClone() {
    return new Profile(parseOwnProfiles(this.pristineData)[0]);
  }

  isDirty() {
    return this.isDeleted || this.pristineData != this.toSerialData();
  }

  filterCows(cows, groupId = -1)
  {
    let cowMap = new Map();
    let tNow = new Date().getTime();
    let includingFilter = false;

    //The adding/inclusive filters
    if (this.HighActivity) {
      includingFilter = true;
      cows.filter((c) => c.highActivity>0).forEach( (c) => cowMap.set(c.animalNr, c));
      // console.log("Filter ADD HighActivity animals", cowMap);
    }
    if (this.HasNotations) {
      includingFilter = true;
      cows.filter((c) => c.notes.hasNotes).forEach( (c) => cowMap.set(c.animalNr, c));
      // console.log("Filter ADD HasNotations animals", cowMap);
    }
    if (this.IncompleteAndMilkPermission) {
      includingFilter = true;
      cows.filter((c) => c.isIncompleteWithMilkpermission()).forEach( (c) => cowMap.set(c.animalNr, c));
      // console.log("Filter ADD IncompleteAndMilkPermission animals", cowMap);
    }
    if (this.expYield > 0) {
      includingFilter = true;
      cows.filter((c) => c.getExpectedYield(true)>this.expYield*100).forEach( (c) => cowMap.set(c.animalNr, c));
      // console.log("Filter ADD expYield (>" + this.expYield + "kg) animals", cowMap);
    }
    if (this.samePlace > 0) {
      includingFilter = true;
      cows.filter((c) => Cow.getMinutesSince(tNow, c.inAreaSince) > this.samePlace*60).forEach( (c) => cowMap.set(c.animalNr, c));
      // console.log("Filter ADD samePlace (>" + this.samePlace + "h) animals", cowMap);
    }
    if (this.daysInLactation > 0) {
      includingFilter = true;
      cows.filter((c) => Cow.getMinutesSince(tNow, c.lactationDay) < this.daysInLactation*24*60 && 
                         Cow.getMinutesSince(tNow, c.lastMilkingTime) > this.hoursSinceLastMilking*60).forEach( (c) => cowMap.set(c.animalNr, c));
      // console.log("Filter ADD daysInLactation (<" + this.daysInLactation + "d) animals", cowMap);
    }
    if (this.sinceMilk > 0) {
      includingFilter = true;
      cows.filter((c) => Cow.getMinutesSince(tNow, c.lastMilkingTime) > this.sinceMilk*60).forEach((c)=> cowMap.set(c.animalNr, c));
      // console.log("Filter ADD sinceMilk (>" + this.sinceMilk + "h) animals", cowMap);
    }
    if (this.milkPerm > 0) {
      includingFilter = true;
      cows.filter((c) => Cow.getMinutesSince(tNow, c.timeToNextMilking) > this.milkPerm*60).forEach((c)=> cowMap.set(c.animalNr, c));
      // console.log("Filter ADD milkPerm (>" + this.milkPerm + "h) animals", cowMap);
    }

    //Make array from map, no need to avoid duplicates from here as we will only remove from list after this.
    let filteredCows = includingFilter ? [...cowMap].map(([key, value]) => value)  : cows;
    // console.log("filteredCows", filteredCows);
    
    //Exluding filters
    if (this.daysUntilDryOff > 0) {
      // console.log("Filter on daysUntilDryOff (<" + this.daysUntilDryOff + "d)");
      filteredCows = filteredCows.filter((c) => -Cow.getMinutesSince(tNow, c.expectedDryOff) > this.milkPerm*60*24);
    }

    if (this.UseAction) {
      // console.log("Filter on action")
      filteredCows = filteredCows.filter((c) => (this.action_Milk && c.action == 0) || 
                                (this.action_FeedOnly && c.action == 1) || 
                                (this.action_PassThrough && c.action == 2) || 
                                (this.action_Unselected && c.action == 3));
    }
    if (this.UseGroups) {
      // console.log("Filter on UseGroups");
      filteredCows = filteredCows.filter((c) => this.groups.includes(+c.animalGroupId));
    }
    if (this.UseAreaType) {
      // console.log("Filter on area type WAIT:" + this.InWaitArea + ", ROBOT:" + this.InRobotArea + ", OTHER:" + this.InOtherArea  ) 
      filteredCows = filteredCows.filter((c) => (this.InWaitArea && c.areaType == 0) || 
                                (this.InRobotArea && c.areaType == 1) || 
                                (this.InOtherArea && c.areaType == 2) || 
                                (this.InUnknownArea && c.areaType == ''));
    }
    if (this.trapCow) {
      // console.log("Filter on trapCow");
      filteredCows = filteredCows.filter((c) => c.isTrapCow());
    }
    if (this.ExpHeatDate) {
      // console.log("Filter on ExpHeatDate");
      filteredCows = filteredCows.filter((c) => c.expectedInseminationDueDate || c.reproductionStatus==5);
    }
    if (this.ExpCalvingDate) {
      // console.log("Filter on ExpCalvingDate");
      filteredCows = filteredCows.filter((c) => c.expectedCalvingDate);
    }
    if (this.ToBeCulled) {
      // console.log("Filter on ToBeCulled");
      filteredCows = filteredCows.filter((c) => c.toBeCulled == 1);
    }
    if (this.ExpDryOffDate) {
      // console.log("Filter on ExpDryOffDate");
      filteredCows = filteredCows.filter((c) => c.expectedDryOff);
    }
    if (this.ExpPregCheckDate) {
      // console.log("Filter on ExpPregCheckDate");
      filteredCows = filteredCows.filter((c) => c.expectedPregnancyCheck);
    }
    if (this.incompleteAndMax48h) {
      // console.log("Filter on incompleteAndMax48h");
      filteredCows = filteredCows.filter((c) => c.isIncompleteLast48h());
    }

    //This is if user selected a group from top of cowQ
    if (groupId != -1) {
      // console.log("Filter on group", groupId)
      filteredCows = filteredCows.filter((c) => (c.animalGroupId == groupId));
    }

    return filteredCows;
  }
}

export function parseOwnProfiles(ownProfileData)
{
  let sd = new SerialData(ownProfileData);
  let parsed = [];
  while (sd.hasMore()) {
      let name = sd.getString();
      let userId = sd.getString();
      let nameKey = name+"-"+userId;
      let userName = sd.getString();
      let timeWritten = sd.getString();
      let moreSerialData = sd.getString();
      let msd = new SerialData(moreSerialData,"#");
      let sinceMilk = msd.getInt();
      let milkPerm = msd.getInt();
      let lactDay = msd.getInt();
      let expYield = msd.getInt();
      let samePlace = msd.getInt();
      let daysUntilDryOff = msd.getInt();
      let activityAndAreaMask = msd.getInt();
      let action = msd.getInt();
      let cells = msd.getInt();
      let profileIndexSize = msd.getInt(); //always 7 since profileIndex is always 7 elements
      let profileIndex = [msd.getInt(),msd.getInt(),msd.getInt(),msd.getInt(),msd.getInt(),msd.getInt(),msd.getInt()];
      let areas = [];
      let areasSize = msd.getInt();
      while (areasSize--) {
        areas.push(msd.getString());
      }
      let groups = [];
      let groupsSize = msd.getInt();
      while (groupsSize--) {
        groups.push(msd.getInt());
      }
      parsed.push({name,userId,nameKey,userName,timeWritten,sinceMilk,milkPerm,lactDay,expYield,samePlace,daysUntilDryOff,activityAndAreaMask,action,cells,profileIndex,groups,areas, isPredefined: false, showEmptyGroups: false});
  }
  return parsed;
}

//PROFILE CODE FROM MYFARM SERVER:
// public int [] profileIndex;
// public int [] groups;
// public String [] areas = null;
// public int sinceMilk = 0;
// public int milkPerm = 0;
// public int lactDay = 0;		// bit0-7=nr days in lactation, bit 8-, nr hours since last milking
// public int expYield = 0;
// public int samePlace = 0;
// public int daysUntilDryOff = 0;		// Except Nr days until dry off bit 0-9
// public int activityAndAreaMask = 0;	// bit 0=HighActivity, bit1=notInRobotArea, bit2=notInWaitArea, bit3=notInOtherArea, bit4=notInUnknownArea, bit5=waitAreaSpecialEnabled, bit6=waitAreaSpecialActivated
//                   // bit 7-9: 0x80=incomplete&max48h, 0x100=trapCow, 0x180=ExpCalvingDate, 0x200=ExpHeatDate, 0x280=ExpPregCheckDate, 0x300=ExpDryOffDate, 0x380=ToBeCulled
//                   // bit 10=Has notations 11=Use Groups 12=Use area type 13=Use Specific Areas 14=Use action, 15=Incomplete AND milk permission
// public int action = 0;		// In cow: 0==Milk, 1==FeedOnly, 2==PassThrough, 3==Unselected. Profile: bit0==Milking, bit1==FeedOnly, bit2==PassThrough, bit3==Unselected
// public int cells = 0;		// cells->bit0-1: 1:warning, ==2:alarm

export const ColumnIndex = {
  None: 0,
  Teat_status: 1,
  Animal_nr: 2,
  Group__no: 3,
  Anim_nr__group: 4,
  Lactation: 5,
  Days_in_milk: 6,
  DIM__lact_: 7,
  Since_milked: 8,
  Milk_perm_: 9,
  OCC__last_2: 10,
  Exp_yield: 11,
  SevenDays_milkings: 12,
  SevenDays: 13,
  Blo_MDi_OCC: 14,
  Incomplete: 15,
  Note: 16,
  Action: 17,
  Name: 18,
  Anim_nr__name: 19,
  Area: 20,
  Time_in_area: 21,
  Area__time: 22,
  Activity: 23,
  Milkings__day: 24,
  Trap_cow: 25,
  Trap_cow___: 26,
  Exp__Calving: 27,
  Last_Insemination: 28,
  Exp__Buildup: 29,
  Exp__Dry_Off: 30,
  Exp__Heat: 31,
  Exp__Insem_Due: 32,
  Exp__Preg_Check: 33,
  Last_Heat: 34,
  SCC_Date: 35,
  Breed: 36,
  Hours_Since_High_Activity: 37,
  Latest_SCC: 38,
  Is_Drying_Off: 39,
  To_Be_Culled: 40,
  Relative_Activity: 41,
  Reproduction_Status: 42,
  General: 43,
  Reproduction: 44,
  Milking: 45,
  Feeding: 46,
  Health: 47,
  CowMonitor: 48,
  Status_board: 49,
  Prod_latest: 50,
  Milk_Dest: 51,
  Days_to_dry_Off: 52,
  Exp__Yield__value: 53,
  Last_milking_Robot: 54,
}