import Names from "@/services/Names";
import { Cow } from "@/services/shared/Cow.js";
import { AnimalGroup } from "@/services/shared/AnimalGroup.js";
import { Area } from "@/services/shared/Area.js";
import { SerialData } from "@/services/shared/SerialData.js";
import { parseOwnProfiles } from "@/services/shared/Profile.js";
import { Profile } from "./shared/Profile";
import Parse from "@/services/shared/Parse.js";

const Axios = require("axios");
Axios.defaults.withCredentials = true;
let pollActive = false;
let broadcastId = null;
var Store;

function getMyUserSettings() {
  // console.log("getMyUserSettings: call");
  let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvUser/getMyUser";
  Axios.post(url, JSON.stringify(Store.state.selectedFarm.id))
    .then((res) => {
      // console.log("getMyUserSettings: res=", res);
      Store.commit(Names.settings.setConfig, res.data);
    })
    .catch((err) => console.error(err));
}

function getAllUserSettings() {
  // console.log("getAllUserSettings: call");
  let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/Users/getUsersAtVc";
  Axios.post(url, JSON.stringify(Store.state.selectedFarm.id))
    .then((res) => {
      // console.log("getAllUserSettings: res=", res);
      Store.commit(Names.users.setConfig, res.data);
    })
    .catch((err) => console.error(err));
}

//1. Get Server list
//2. Start calls to ALL servers
//3. Promis.waitall
//4. set user selected farm in Store from localstorage
function getFarmList() {
  let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvMyFarm/getAllData?v=2";
  // console.log(url);
  return Axios.post(url, JSON.stringify(""))
    .then((res) => {
      if (res.data.cows.serverMap) {
        // console.info("get farms and servers: res=", res.data);
        Store.commit(Names.setServers, res.data.cows.serverMap);
      }
      // console.info("getFarmList: res=", res.data);
      Store.commit(Names.setFarms, res.data.cows.myFarms);
      if (!Store.state.selectedFarm && !setSelectedFarm()) {
        // console.log("Setting selected farm from server data");
        let selectedFarm = res.data.cows.myFarms.find((f) => f.id == res.data.cows.vcId);
        Store.commit(Names.selectedFarm, selectedFarm);
      }
      // console.log("Inital data loaded");
      Store.commit(Names.isLoadingInitialData, false);
    })
    .catch((err) => console.error(err));
}

function setSelectedFarm() {
  if (!Store.state.selectedFarm) {
    let savedSelectedFarm = Store.state.selectedFarm;
    if (savedSelectedFarm && savedSelectedFarm.id) {
      let farmFromStore = Store.state.farms.data.farms.find((farm) => farm.id == savedSelectedFarm.id);
      if (farmFromStore) {
        // console.log("Loaded selected farm from local storage", savedSelectedFarm);
        Store.commit(Names.selectedFarm, savedSelectedFarm);
        return true;
      }
    }
  }
}

function parseCows(data, info, useImperialUnits) {
  let cows = [];
  let fields = data.constructor === Array ? data : data.split("$");
  while (fields.length >= Cow.propertyNamesInOrder.length) {
    cows.push(new Cow(fields, info, useImperialUnits));
  }
  return cows;
}

function parseGroups(data) {
  let groups = [];
  let fields = data.constructor === Array ? data : data.split("$");
  let [groupCount] = fields.splice(0, 1);
  while (groupCount--) {
    groups.push(new AnimalGroup(fields));
  }
  return groups;
}

function parseAreas(data) {
  let areas = [];
  let fields = data.constructor === Array ? data : data.split("$");
  let [areaCount] = fields.splice(0, 1);
  while (areaCount--) {
    areas.push(new Area(fields));
  }
  return areas;
}

function parseFarmCowData(allData, useImperialUnits) {
  let fields = allData.split("$");
  let groups = parseGroups(fields);
  let areas = parseAreas(fields);
  let animals = parseCows(fields, { isFarm: true, groups, areas }, useImperialUnits);
  animals.sort((e1, e2) => e1.lastMilkingTime - e2.lastMilkingTime);
  return { groups, areas, animals };
}

function parseProfiles({ profiles, ownProfiles }) {
  //Add some props and put Custom profiles in same object as predefined
  Object.entries(profiles.deLaval.profiles).forEach(([key, data]) => {
    let [name, nameKey] = key.split("\n");
    Object.assign(data, { name, nameKey, isPredefined: true });
  });
  if (ownProfiles) {
    let parsed = parseOwnProfiles(ownProfiles);
    parsed.forEach((p) => (profiles.deLaval.profiles[p.name + "\n" + p.nameKey] = p));
  }

  //Create Profile objects and put them in profiles.data
  profiles.data = [];
  Object.entries(profiles.deLaval.profiles).forEach(([key, data]) => profiles.data.push(new Profile(data)));
  profiles.data.sort((v1, v2) => {
    v1 = v1.name.toLowerCase();
    v2 = v2.name.toLowerCase();
    return v2 > v1 ? -1 : v2 < v1 ? 1 : 0;
  });
}

function parseDelproUsers(data) {
  let delProUsers = data.cows.delProUsers;
  let userCols = [];
  let users = { emails: {}, usernames: {} };
  if (delProUsers) {
    let sd = new SerialData(delProUsers, "#");
    let clen,
      ncols = sd.getInt();
    clen = ncols;
    while (--clen >= 0) userCols.push(sd.getString());
    clen = sd.getInt();
    while (--clen >= 0) {
      var i = -1,
        user = {};
      while (++i < ncols) user[userCols[i]] = sd.getString();
      users[user.UserId] = user;
      if (user.Email) users.emails[user.Email] = user;
      if (user.UserName) {
        users.usernames[user.UserName] = user;
      }
    }
  }
  data.cows.delProUsers = users;
}

function getFarmData() {
  let selectedFarm = "";
  if (!Store.state.selectedFarm) {
    console.error("getFarmData: called, but no selected farm!");
    selectedFarm = { id: "", name: "-", srv: -1, status: -1 };
  } else {
    selectedFarm = Store.state.selectedFarm;
  }
  // console.log("getFarmData: called");
  let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvMyFarm/getAllData";
  // console.log("URL", url);
  Store.commit(Names.loadingFarm, Store.state.selectedFarm);
  return Axios.post(url, JSON.stringify(selectedFarm.id))
    .then((res) => {
      // console.log("getFarmData: res1=", res);
      Store.commit(Names.loadingFarm, null);
      parseDelproUsers(res.data);
      const useImperialUnits = res.data.useImperialUnits;
      let farmData = Object.assign(res.data, parseFarmCowData(res.data.cows.serializedData, useImperialUnits));
      farmData.profiles.fieldNames = farmData.profiles.fieldNames.map((name) => name.replaceAll("£", "%"));
      if (useImperialUnits) {
        convert7DaysDataUnit(farmData);
      }
      parseProfiles(farmData);
      Store.commit(Names.setFarmData, farmData);
    })
    .catch((err) => {
      Store.commit(Names.loadingFarm, null);
      console.log(err);
    });
}

function convert7DaysDataUnit(farmData) {
  if (!farmData.sevenDays || !farmData.sevenDays.jsRobots) return;
  console.log("convert7DaysDataUnit", farmData);
  const fields = ["last24h", "lastLast24h", "lastSeven", "lastLastSeven", "perAnimalLast24h", "perAnimalLastLast24h", "perAnimalLastSeven", "perAnimalLastLastSeven", "perMilkingsLast24h", "perMilkingslLastLast24h", "perMilkingsLastSeven", "perMilkingsLastLastSeven"];
  farmData.sevenDays.jsRobots.forEach((r) => {
    r.hourYield = r.hourYield.map((y) => Parse.kg2lb(y));
    fields.forEach((f) => (r[f] = Parse.kg2lb(r[f])));
  });

  const vc = farmData.sevenDays.jsVcMilkData;
  vc.hourYield = vc.hourYield.map((y) => (y = Parse.kg2lb(y)));
  fields.forEach((f) => (vc[f] = Parse.kg2lb(vc[f])));

  return farmData;
}

function deleteFarmData() {
  // console.log("Clear old data");
  //When user logs out, clear most of the states...
  Store.commit(Names.setFarms, null);
  Store.commit(Names.setServers, null);
  Store.commit(Names.setFarmData, null);
}

function initBroadcast() {
  // console.log("initBroadcast: called");
  broadcastId = Math.floor(Math.random() * 0x1000000).toString() + new Date().getTime();
  startBroadcast("chat");
  startBroadcast("alarm");
  startBroadcast("queue");
  startBroadcast("robotState");
}

function reinitBroadcast() {
  // console.log("restartBroadcast: called");
  return Axios.post(Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvLongPoll/broadcastCancel", '"' + broadcastId + '"')
    .then((res) => {
      // console.info("restartBroadcast:  res=", res);
      initBroadcast();
    })
    .catch((err) => console.error(err));
}

function startBroadcast(service) {
  if (!Store.state.selectedFarm) {
    console.error("startBroadcast failed, no selected farm!", service);
    return;
  }
  // console.log("startBroadcast: service=" + service);
  let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvLongPoll/broadcast";
  let vcId = Store.state.selectedFarm.id;
  return Axios.post(url, '"' + broadcastId + "," + vcId + "," + service + '"')
    .then((res) => {
      // console.info("startBroadcast: service=" + service + " res=", res);
      if (pollActive == false) {
        pollActive = true;
        pollBroadcast();
      }
    })
    .catch((err) => console.error(err));
}

function pollBroadcast() {
  // console.log("pollBroadcast: call");
  let url = Store.getters.getServerForSelectedFarm + "/Delaval/mvc/SrvLongPoll/poll";
  return Axios.post(url, '"' + broadcastId + '"')
    .then((res) => {
      // console.log("pollBroadcast: res=", res);
      if (res.data !== null) {
        const [...data] = res.data;
        let timeSinceVcContact;
        const newEvent = () => ({ vcGUID: "", eventType: "", length: "", eventPayload: "" });
        let event = newEvent();
        let fields = ["vcGUID", "eventType", "length", "eventPayload"];
        let currentField = 0;
        let payloadIndex = 0;
        data.forEach((c) => {
          if (c == "$" && currentField != 3) currentField++;
          else {
            if (currentField == 3) {
              event[fields[currentField]] += c;
              payloadIndex++;
              if (payloadIndex == event.length) {
                payloadIndex = 0;
                currentField = 0;
                if (event.eventType == "alarm") Store.commit(Names.setFarmAlarm, JSON.parse(event.eventPayload));
                if (event.eventType == "chat") Store.commit(Names.setFarmChat, JSON.parse(event.eventPayload));
                if (event.eventType == "queue") {
                  const useImperialUnits = Store.getters.useImperialUnits;
                  let [cow] = parseCows(event.eventPayload, { isFarm: false, state: Store.state }, useImperialUnits);
                  if (cow) Store.commit(Names.setCow, cow);
                }
                // console.log("Poll Event handled ", JSON.stringify(event));
                event = newEvent();
              }
            } else event[fields[currentField]] += c;
          }
          if (currentField == fields.length) currentField = 0;
        });
        // Last event object is not a real event object but time since last VcContact
        if (!isNaN(event.eventType)) Store.commit(Names.lastAccessedByVc, +event.eventType);
        if (Store.state.auth.isAuthenticated) pollBroadcast();
      }
    })
    .catch((err) => {
      console.error(err);
      Store.commit("login", { state: false, message: "Event poll stopped, login session expired." });
    });
}

export default {
  initialize(store) {
    Store = store;
    // console.log("DataService: initialize");
  },

  deleteData() {
    deleteFarmData();
  },

  getInitialData() {
    // console.log("DataService: getInitialData");
    Store.commit(Names.isLoadingInitialData, true);
    deleteFarmData();
    getFarmList();
  },

  reloadUserSettings() {
    console.log("DataService: reloadUserSettings");
    getMyUserSettings();
    getAllUserSettings();
  },

  preloadMyUserSettings() {
    console.log("DataService: preloadMyUserSettings");
    getMyUserSettings();
  },

  initFarmData() {
    console.log("DataService: initFarmData");
    initBroadcast();
    getMyUserSettings();
    getAllUserSettings();
    getFarmData();
  },

  reinitFarmData() {
    console.log("DataService: reinitFarmData");
    reinitBroadcast();
    getMyUserSettings();
    getAllUserSettings();
    getFarmData();
  },
};
