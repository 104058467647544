<template>
  <v-app class="theBackground">
    <template v-if="$store.state.auth.isAuthenticated">
      <v-app-bar app fixed color="#103D82" height="56px" dark flat>
        <v-btn to="/overview" color="#103D82" text>
          <MyfarmIcon width="50" height="50" />
        </v-btn>
        {{ $vuetify.breakpoint.name }} ({{ portrait ? "P" : "L" }})
        <v-spacer></v-spacer>
        <v-btn to="/" text loading v-if="!$store.state.auth.isAuthenticated || $store.state.isLoadingInitialData || $store.state.loadingFarm"></v-btn>
        <v-btn x-large text :disabled="!!(!$store.state.auth.isAuthenticated || $store.state.isLoadingInitialData || $store.state.loadingFarm)">
          {{ $store.state.selectedFarm ? $store.state.selectedFarm.name : "Loading" }}
        </v-btn>
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </v-app-bar>
      <AppBar :drawer="drawer" />
      <v-main class="theBackground pr-2 pl-2">
        <div class="card-container">
          <Loader v-if="$store.state.isLoadingInitialData || $store.state.loadingFarm" />
          <router-view v-else />
        </div>
      </v-main>
    </template>
    <template v-else>
      <v-main class="theBackground">
        <Login v-if="languageInitiated" />
        <Loader v-else />
      </v-main>
    </template>
  </v-app>
</template>

<script>
import MyfarmIcon from "./assets/myfarm_icon.svg";
import LoginSvc from "@/services/LoginService.js";
import DataSvc from "@/services/DataService.js";
import Chat from "@/services/Chat.js";
import AppBar from "@/components/AppBar.vue";
import Login from "@/views/Login.vue";
import Loader from "@/views/Loader.vue";
import Names from "@/services/Names";

export default {
  name: "App",

  components: {
    MyfarmIcon,
    AppBar,
    Login,
    Loader,
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      drawer: false,
      languageInitiated: false,
    };
  },

  watch: {
    "$store.state.auth.login.updated": function (currentValue, previousValue) {
      console.log("Got event triggerLogin", this.$store.state.auth.login);
      LoginSvc.login(this.$store.state.auth.login);
    },

    "$store.state.auth.isAuthenticated": function (currentValue, previousValue) {
      console.log("Got event isAuthenticated", currentValue);
      if (currentValue) {
        DataSvc.getInitialData();
      } else DataSvc.deleteData();
    },

    "$store.state.isLoadingInitialData": function (currentValue, previousValue) {
      console.log("Got event isLoadingInitialData", currentValue);
      if (!currentValue) {
        DataSvc.initFarmData();
      }
    },

    "$store.state.selectedFarm": function (currentValue, previousValue) {
      if (!this.$store.state.loadingFarm) {
        console.log("Got event selectedFarm", currentValue);
        DataSvc.reinitFarmData();
      } else {
        console.log("SKIPPING Got event selectedFarm, already loading farm", currentValue);
      }
    },

    "$store.state.i18n.updated": function (currentValue, previousValue) {
      console.log("LANG: "+ this.$store.state.i18n.lang);
      console.log("TXT: ", this.$store.state.i18n.messages);
      this.$i18n.setLocaleMessage(this.$store.state.i18n.lang, this.$store.state.i18n.messages);
      this.$i18n.locale = this.$store.state.i18n.lang;
      console.log("i18n update!!!!", this.$store.state.i18n);
      setTimeout(() => this.languageInitiated = true, 10);
      // this.languageInitiated = true;
      console.log("Reload farm to get new language on cowq columns");
      if (!this.$store.state.loadingFarm) {
        if (this.$store.state.auth.isAuthenticated) DataSvc.reinitFarmData();
      } else {
        console.log("SKIPPING Got event i18n updated, already loading farm", currentValue);
      }
    },
  },

  mounted() {
    DataSvc.initialize(this.$store);
    LoginSvc.initialize(this.$store);
    Chat.initialize(this.$store);
    window.addEventListener("resize", this.resizeHandler);
  },

  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },

  methods: {
    resizeHandler(e) {
      let width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      let height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
      this.$store.commit(Names.setOrientation, height > width);
    },
  },
  computed: {
    portrait: {
      get() {
        return this.$store.state.isPortraitMode;
      },
    },
  },
};
</script>

<style>
.dynamicSmallTextPortrait {
  font-size: max(14px, 3vw);
}
.dynamicSmallTextLandscape {
  font-size: max(14px, 1.5vw);
}
.dynamicMediumTextPortrait {
  font-size: clamp(16px, calc(2.5vw), 28px);
}
.dynamicMediumTextLandscape {
  font-size: clamp(16px, calc(2.3vw), 28px);
}
.dynamicLargeTextPortrait {
  font-size: max(18px, 3.6vw);
}
.dynamicLargeTextLandscape {
  font-size: max(18px, 2.9vw);
}
.dynamicTextPortrait {
  font-size: max(14px, 6vw);
}
.dynamicTextLandscape {
  font-size: max(14px, 4vw);
}
.fixTextLarge {
  font-size: 25px;
}
.truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.pointer {
  cursor: pointer;
}
</style>

<style scoped>
.card-container {
  border-radius: 10px 10px 0 0;
  background-color: #e5e5e5;
  height: calc(100vh - 56px);
}
.theBackground {
  background-color: #103d82;
}
</style>