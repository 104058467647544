<template>
    <v-row no-gutters>
      <v-col cols="2" class="mr-1">
        <CowHeadIcon class="ml-n3" width="40" height="40" :fill="'black'" />
      </v-col>
      <v-col cols="9" :style="{'white-space': 'nowrap'}" class="ml-1">
        <p :class="getTextClass('Medium')">{{$t("24h")}}</p>
        <p class="font-weight-bold" :class="getTextClass('Large')">{{ perfectPrecision(dataSrc.perAnimalLast24h) }} {{$ucmi("kg","lb")}}</p>
        <p :class="getColorClassFromTrend(animalLast24hourTrend) + getTextClass('Medium')"><img width="16" :src="getImageFromTrend(animalLast24hourTrend)" class="mr-1" />{{ perfectPrecision(dataSrc.perAnimalLast24h - dataSrc.perAnimalLastLast24h) }}</p>
        <p :class="getTextClass('Medium')">{{$t("7d")}}</p>
        <p class="grayText font-weight-bold" :class="getTextClass('Large')">{{ perfectPrecision(dataSrc.perAnimalLastSeven) }} {{$ucmi("kg","lb")}}</p>
        <p :class="getColorClassFromTrend(animalSevenDaysTrend) + getTextClass('Medium')"><img width="16" :src="getImageFromTrend(animalSevenDaysTrend)" class="mr-1" />{{ perfectPrecision(dataSrc.perAnimalLastSeven - dataSrc.perAnimalLastLastSeven) }}</p>
      </v-col>
    </v-row>
</template>

<script>
import Parse from "@/services/shared/Parse.js";
import CowHeadIcon from "@/assets/cowhead.svg";

export default {
  name: "AnimalProduction",

  components: {
    CowHeadIcon,
  },
  props: {
    ms: {
      type: Number,
    },
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      portrait: this.$store.state.isPortraitMode,
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
  },
  methods: {
    getImageFromTrend(trendValue) {
        switch(trendValue)
        {
          case 1:
            return require('@/assets/arrows/green3.png');
          case 0:
            return require('@/assets/arrows/yellow2.png');
          case -1:
            return require('@/assets/arrows/red1.png');
        }
    },
    getColorClassFromTrend(trendValue) {
        switch(trendValue)
        {
          case 1:
            return " greenText ";
          case 0:
            return " yellowText ";
          case -1:
            return " redText ";
        }
    },
    getTextClass(size = "") {
        return this.portrait ? " dynamic" + size + "TextPortrait " : " dynamic" + size + "TextLandscape ";
    },

    perfectPrecision(value) {
      return Parse.perfectPrecision(value);
    },
  },
  computed: {
    dataSrc: {
      get() {
        if (!this.$store.state.farmData.data.sevenDays)
          return {};
        if (this.ms==-1)
          return this.$store.state.farmData.data.sevenDays.jsVcMilkData;
        return this.$store.state.farmData.data.sevenDays.jsRobots[this.ms];
      },
    },
    animalSevenDaysTrend: {
      get() {
        return Parse.trend(this.dataSrc.perAnimalLastSeven, this.dataSrc.perAnimalLastLastSeven);
      },
    },
    animalLast24hourTrend: {
      get() {
        return Parse.trend(this.dataSrc.perAnimalLast24h, this.dataSrc.perAnimalLastLast24h);
      },
    },
  },
};
</script>
<style scoped>
.greenText {
  color: var(--v-green-base)
}
.yellowText {
  color: var(--v-yellow-base)
}
.redText {
  color: var(--v-red-base)
}
.grayText {
  color: var(--v-gray-base)
}
p {
  margin-bottom: -8px;
}
</style>