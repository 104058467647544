<template>
  <v-container class="ma-2 pa-0" fluid>
    <v-row class="pointer" dense @click="select()">
      <v-col cols="4">
        <v-row dense v-for="(role, index) in user.role.names" :key="index">{{ role }}</v-row>
      </v-col>
      <v-col>
        <v-row dense>{{ name }}</v-row>
      </v-col>
    </v-row>

    <v-row v-if="isSelected" dense>
      <v-col cols="4">
        <v-row dense>{{ $t("e-mail") }}</v-row>
      </v-col>
      <v-col>
        <v-row dense>{{ user.email }}</v-row>
      </v-col>
    </v-row>

    <v-row v-if="isSelected" dense>
      <v-col cols="4">
        <v-row dense>{{ $t("Status") }}</v-row>
      </v-col>
      <v-col>
        <v-row dense>{{ status }}</v-row>
      </v-col>
    </v-row>

    <v-row v-if="isSelected" class="mb-2" dense>
      <v-col class="ma-0 pa-0 pr-1" cols="6">
        <v-btn color="button" dark block @click="deleteUser()">{{ $t("Delete") }}</v-btn>
      </v-col>
      <v-col class="ma-0 pa-0 pl-1" cols="6">
        <v-btn color="button" dark block @click="editUser()">{{ $t("Edit") }}</v-btn>
      </v-col>
    </v-row>

    <v-row dense>
      <v-divider class="mb-2" />
    </v-row>

    <SaveUser v-if="showSaveUser" :user="user" @closed="showSaveUser = false" />
    <DeleteUser v-if="showDeleteUser" :user="user" @closed="showDeleteUser = false" />
  </v-container>
</template>

<script>
import Names from "@/services/Names.js";
import SaveUser from "@/components/users/SaveUser.vue";
import DeleteUser from "@/components/users/DeleteUser.vue";

export default {
  name: "UserCard",

  components: {
    SaveUser,
    DeleteUser,
  },

  props: {
    user: {
      data: Object,
      default: null,
    },
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      isSelected: false,
      showSaveUser: false,
      showDeleteUser: false,
    };
  },

  watch: {
    "$store.state.users.selectedId": function (currentId, previousId) {
      if (this.user.id == currentId) this.isSelected = true;
      else this.isSelected = false;
    },
  },

  methods: {
    select() {
      if (this.$store.state.users.selectedId == this.user.id) {
        this.$store.commit(Names.users.setSelectedId, null);
      } else {
        console.info("selected id=" + this.user.id);
        this.$store.commit(Names.users.setSelectedId, this.user.id);
      }
    },

    getTimeStamp(time_ms) {
      let date = new Date(time_ms);
      return date.toLocaleString("sv-SE", {
        timeStyle: "short",
        dateStyle: "short",
      });
    },

    editUser() {
      console.log("editUser: call");
      this.showSaveUser = true;
    },

    deleteUser() {
      console.log("deleteUser: call");
      this.showDeleteUser = true;
    },
  },

  computed: {
    name: {
      get() {
        return this.user.firstName + " " + this.user.lastName;
      },
    },

    status: {
      get() {
        let status = "?";
        if (this.user.status === 3) {
          status = "Active";
        } else if (this.user.status === 0) {
          status = "New user";
        }
        let lastAccessed = "";
        if (this.user.lastAccessed) {
          lastAccessed = ", last visited " + this.getTimeStamp(this.user.lastAccessed);
        }
        return status + lastAccessed;
      },
    },
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>