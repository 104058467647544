<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text>
          <v-text-field :rules="nameRules" autofocus v-model="profile.name" clearable :label="$t('Profile name')" :disabled="profile.isPredefined" />
        
          <!-- Animal List Card -->
          <v-card>
            <v-card-title>{{$t("Animal list")}}</v-card-title>
            <v-card-text>
                <v-select medium :items="getColumnTitles"
                  :label="$t('Initial sort column in list')"
                  v-model="selectedSortColumn"
                  item-text="name"
                  item-value="index"
                  class="rounded-lg"
                  :disabled="profile.isPredefined"
                />
              <v-card>
                <v-card-title>
                  {{$t("Show in list")}}
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-select medium :items="getColumnTitles"
                      label="#1"
                      v-model="profile.columns[0]"
                      item-text="name"
                      item-value="index"
                      class="rounded-lg"
                      :disabled="profile.isPredefined"
                    />
                    </v-col>
                    <v-col>
                      <v-select medium :items="getColumnTitles"
                      label="#2"
                      v-model="profile.columns[1]"
                      item-text="name"
                      item-value="index"
                      class="rounded-lg"
                      :disabled="profile.isPredefined"
                    />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select medium :items="getColumnTitles"
                      label="#3"
                      v-model="profile.columns[2]"
                      item-text="name"
                      item-value="index"
                      class="rounded-lg"
                      :disabled="profile.isPredefined"
                    />
                    </v-col>
                    <v-col>
                      <v-select medium :items="getColumnTitles"
                      label="#4"
                      v-model="profile.columns[3]"
                      item-text="name"
                      item-value="index"
                      class="rounded-lg"
                      :disabled="profile.isPredefined"
                    />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-select medium :items="getColumnTitles"
                      label="#5"
                      v-model="profile.columns[4]"
                      item-text="name"
                      item-value="index"
                      class="rounded-lg"
                      :disabled="profile.isPredefined"
                    />
                    </v-col>
                    <v-col>
                      <v-select medium :items="getColumnTitles"
                      label="#6"
                      v-model="profile.columns[5]"
                      item-text="name"
                      item-value="index"
                      class="rounded-lg"
                      :disabled="profile.isPredefined"
                    />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
          <!-- Animal List Card -->
        </v-card-text>

        <v-card-text>
          <!-- Filter Exclusive Card -->
          <v-card>
            <v-card-title>{{$t("Filter. All conditions must be fulfilled")}}</v-card-title>
            <v-card-text>

              <!-- Action -->
              <v-switch v-model="profile.UseAction" inset :label="$t('Use Action')" :disabled="profile.isPredefined"/>
              <v-expand-transition>
                <v-card v-show="profile.UseAction">
                  <v-card-text>
                    <v-switch v-model="FetchCows" inset :label='text_Include_in_FetchCows_Checked' class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                    <v-switch v-model="profile.action_Milk" inset :label="$t('Milking')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                    <v-switch v-model="profile.action_FeedOnly" inset :label="$t('Feed only')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                    <v-switch v-model="profile.action_PassThrough" inset :label="$t('Pass through')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                    <v-switch v-model="profile.action_Unselected" inset :label="$t('Not specified')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <!-- Action -->

              <!-- Animal Groups -->
              <v-switch v-model="profile.UseGroups" inset :label="$t('Use Animal groups')" :disabled="profile.isPredefined"/>
              <v-expand-transition>
                <v-card v-show="profile.UseGroups">
                  <v-card-text>
                    <v-switch v-model="profile.showEmptyGroups" inset :label="$t('Show empty groups')" :disabled="profile.isPredefined"/>
                    <v-card>
                      <v-card-text>
                        <v-checkbox v-for="(group) in animalGroups" :key="group.GroupId" v-model="profile.groups" :value="+group.GroupId" inset :label="group.GroupName + ' (' + group.animalCount + ')'" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <!-- Animal Groups -->

              <!-- Areas -->
              <v-select medium :items="areaOptions"
                :label="$t('Only in certain places')"
                v-model="areaOption"
                item-text="name"
                item-value="index"
                class="rounded-lg mt-4"
                :disabled="profile.isPredefined"
              />
              <v-expand-transition>
                <v-card v-show="profile.UseAreaType">
                  <v-card-text>
                    <v-card>
                      <v-card-text>
                        <v-switch v-model="profile.InWaitArea" inset :label="$t('In wait area')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                        <v-switch v-model="profile.InRobotArea" inset :label="$t('In robot')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                        <v-switch v-model="profile.InOtherArea" inset :label="$t('In other area')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                        <v-switch v-model="profile.InUnknownArea" inset :label="$t('In unknown/gate area')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <v-expand-transition>
                <v-card v-show="profile.UseSpecificAreas">
                  <v-card-text>
                    <v-card>
                      <v-card-text>
                        <v-checkbox v-for="(area) in areas" :key="area.AreaGuid" v-model="profile.areas" :value="area.AreaGuid.substring(0,6)" inset :label="area.AreaName" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
              <!-- Areas -->

              <!-- Animal who is/has -->
              <v-select medium :items="animalIsOptions"
                :label="$t('Animals who is/has')"
                v-model="animalIsOption"
                item-text="name"
                item-value="index"
                class="rounded-lg mt-4"
                :disabled="profile.isPredefined"
              />
              <!-- Animal who is/has -->

              <!-- Exclude Animals near Dry Off  -->
              <v-select medium :items="dryOffOptions"
                :label="$t('Exclude animals near Dry Off')"
                v-model="profile.daysUntilDryOff"
                item-text="name"
                item-value="index"
                class="rounded-lg mt-4"
                :disabled="profile.isPredefined"
              />
              <!-- Exclude Animals near Dry Off -->

            </v-card-text>
          </v-card>
          <!-- Filter Exclusive Card -->
        </v-card-text>

        <v-card-text>
          <!-- Filter Inclusive Card -->
          <v-expand-transition>
            <v-card v-if="showInclusiveFilters">
              <v-card-title>{{$t('Filter. At least one condition must be fulfilled')}}</v-card-title>
              <v-card-text>
                
                <v-switch v-model="profile.HighActivity" inset :label="$t('Animals with High activity')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                
                <v-switch v-model="profile.HasNotations" inset :label="$t('Has notations')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                
                <v-switch v-model="profile.IncompleteAndMilkPermission" inset :label="$t('Is incomplete with milk permission')" class="ma-0 pa-0" :disabled="profile.isPredefined"/>
                
                <span class="mr-2">{{ExpectedYieldAbove}} </span><v-btn @click="promptExpectedYield()" :disabled="profile.isPredefined">{{this.profile.expYield == 0 ? $t("Not active") : $t("Change")}}</v-btn>

                <v-select medium :items="sameLocationOptions"
                  :label="$t('Has been in same location')"
                  v-model="profile.samePlace"
                  item-text="name"
                  item-value="minutes"
                  class="rounded-lg mt-4"
                  :disabled="profile.isPredefined"
                />

                <v-row>
                  <v-col cols="auto" class="mt-2">
                    <span class="mr-2">{{$t("Days in milk, until day")}}: {{this.profile.daysInLactation>0 ? this.profile.daysInLactation : ""}} </span><v-btn @click="promptDaysInMilk()" :disabled="profile.isPredefined">{{this.profile.daysInLactation == 0 ? $t("Not active") : $t("Change")}}</v-btn>
                  </v-col>
                  <v-col>
                    <v-select medium :items="sinceMilkingOptions" v-if="this.profile.daysInLactation>0"
                      :label="'...' + $t('AND Since milking')"
                      v-model="profile.hoursSinceLastMilking"
                      item-text="name"
                      item-value="hours"
                      class="rounded-lg "
                      :disabled="profile.isPredefined"
                    />
                  </v-col>
                </v-row>

                <v-select medium :items="sinceMilkingOptions"
                  :label="$t('Since milking')"
                  v-model="profile.sinceMilk"
                  item-text="name"
                  item-value="hours"
                  class="rounded-lg mt-4"
                  :disabled="profile.isPredefined"
                />

                <v-select medium :items="sinceMilkPermissionOptions"
                  :label="$t('Since milk permission')"
                  v-model="profile.milkPerm"
                  item-text="name"
                  item-value="hours"
                  class="rounded-lg mt-4"
                  :disabled="profile.isPredefined"
                />

                <v-select medium :items="bloodMdiCellsOptions"
                  :label="$t('Blood, Mdi or Cells above')"
                  v-model="profile.cells"
                  item-text="name"
                  item-value="index"
                  class="rounded-lg mt-4"
                  :disabled="profile.isPredefined"
                />

              </v-card-text>
            </v-card>
          </v-expand-transition>
          <!-- Filter Inclusive Card -->
        </v-card-text>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {ColumnIndex} from "@/services/shared/Profile.js";

export default {
  name: "ProfileRow",

  components: {

  },
  props: {
    profile: Object,
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      selectedGroups: [],
      areaOptions: [{name: this.$t("Not active"), index:0},{name:this.$t("Area types"), index:1},{name:this.$t("Specific areas"), index:2}],
      animalIsOptions: [{name: this.$t("Not active"), index:0},{name: this.$t('Incomplete'), index:1},{name:this.$t('Trap cow'), index:2},{name:this.$t('Expected Calving Date'), index:3},{name:this.$t('Exp. Insemination Due'), index:4},{name:this.$t('Exp. Pregnancy Check'), index:5},{name:this.$t('Exp. Dry Off'), index:6},{name:this.$t('To Be Culled'), index:7}],
      nameRules: [ name => this.profilesSorted.filter( p => p.name == name).length>1 ? 'Name must be unique' : true],
    };
  },
  methods: {
    niceDate(time) {
      const offset = new Date().getTimezoneOffset() * 60 * 1000;
      let nice = new Date(+time*1000 - offset).toISOString().substring(0,10)
      return nice;
    },
    promptExpectedYield() {
      let expYield = parseInt(window.prompt(this.$t('Enter Expected yield (1-40 kg)'), '0'));
      this.profile.expYield = isNaN(expYield) || expYield>40 || expYield < 0 ? 0 : expYield;
    },
    promptDaysInMilk() {
      let daysInMilk = parseInt(window.prompt(this.$t("Enter Lactation day (1-365)"), '0'));
      this.profile.daysInLactation = isNaN(daysInMilk) || daysInMilk>365 || daysInMilk < 0 ? 0 : daysInMilk;
    },
  },
  computed: {
    text_Include_in_FetchCows_Checked: {
      get() {
        return this.$t("Include animals with £1 checked").replace('£1', '"' + this.$t("Include in FetchCows") + '"');
      }
    },
    profilesSorted: {
      get() {
        return this.$store.state.farmData.data.profiles.data;
      }
    },
    showInclusiveFilters: {
      get() {
       return this.animalIsOption == 0;
      }
    },
      bloodMdiCellsOptions: {
      get() {
       return [{name: this.$t("Not active"), index:0},{name:this.$t("Warning level"), index:1},{name:this.$t("Alarm level"), index:2}];
      }
    },
    sinceMilkPermissionOptions: {
      get() {
        let options = [{name: this.$t("Not active"), hours:0}];
       for (let i=0;i<24;i++) {
        options.push({name:this.$t("at least") + " " + i + " " + (i>1? this.$t("hours") : this.$t("hour")), hours:i+1});
       }
       return options;
      }
    },
    sinceMilkingOptions: {
      get() {
        let options = [{name: this.$t("Not active"), hours:0}];
       for (let i=1;i<24;i++) {
        options.push({name:this.$t("at least") + " " + i + " " + (i>1? this.$t("hours") : this.$t("hour")), hours:i});
       }
       return options;
      }
    },
    sameLocationOptions: {
      get() {
        let options = [{name: this.$t("Not active"), minutes:0}];
       for (let i=1;i<24;i++) {
        options.push({name:this.$t("at least") + " " + i + " "+ (i>1? this.$t("hours") : this.$t("hour")), minutes:i*60});
       }
       return options;
      }
    },
    dryOffOptions: {
      get() {
        let options = [{name: this.$t("Not active"), index:0}];
       for (let i=1;i<30;i++) {
        options.push({name:this.$t("at least") + " " + i + " "+ (i>1? this.$t("days") : this.$t("day")), index:i});
       }
       return options;
      }
    },
    ExpectedYieldAbove: {
      get() {
       return this.profile.expYield == 0 ? this.$t("Expected yield above") : this.$t("Expected yield above") + ": " + this.profile.expYield + " kg" ;
      }
    },
    animalIsOption: {
      get() {
       return this.profile.incompleteAndMax48h ? 1 : this.profile.trapCow ? 2 : this.profile.ExpCalvingDate ? 3 : this.profile.ExpHeatDate ? 4 : this.profile.ExpPregCheckDate ? 5 : this.profile.ExpDryOffDate ? 6 : this.profile.ToBeCulled ? 7 : 0;
      },
      set(val) {
        this.profile.incompleteAndMax48h = val == 1; 
        this.profile.trapCow = val == 2;
        this.profile.ExpCalvingDate = val == 3;
        this.profile.ExpHeatDate = val == 4;
        this.profile.ExpPregCheckDate = val == 5;
        this.profile.ExpDryOffDate = val == 6;
        this.profile.ToBeCulled = val == 7;
      }
    },
    areaOption: {
      get() {
       return this.profile.UseAreaType ? 1 : this.profile.UseSpecificAreas ? 2 : 0;
      },
      set(val) {
        this.profile.UseAreaType = val == 1; 
        this.profile.UseSpecificAreas = val == 2;
      }
    },
    areas: {
      get() {
        return this.$store.state.farmData.data.areas;
      }
    },
    animalGroups: {
      get() {
        return this.profile.showEmptyGroups ? this.$store.state.farmData.data.groups : this.$store.state.farmData.data.groups.filter( g => g.animalCount > 0);
      }
    },
    FetchCows: {
      get() {
       return this.profile.waitAreaSpecialEnabled && this.profile.waitAreaSpecialActivated;
      },
      set(val) {
        this.profile.waitAreaSpecialEnabled = val;
        this.profile.waitAreaSpecialActivated = val;
      }
    },
    getColumnTitles: {
      get() {
        const indexesToIgnore = [ColumnIndex.Teat_status, ColumnIndex.Incomplete, ColumnIndex.Prod_latest, ColumnIndex.Milk_Dest];
        let columnTitles = [];
        this.$store.state.farmData.data.profiles.fieldNames.forEach((c, index) => { 
          if (!indexesToIgnore.includes(index)) {
            columnTitles.push({name: c, index});
          }
        });
        return columnTitles;
      }
    },
    selectedSortColumn: {
      get() {
       return this.profile.sortColumnIndex;
      },
      set(val) {
        this.profile.sortColumnIndex = val;
      }
    },
  },
};
</script>
<style scoped>
  .truncate {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
</style>