<template>
  <v-card class="mt-4 pr-2 pt-2 overflow-y-auto mb-0" :style="{height: 'calc(100vh - 141px)', backgroundColor: '#f3f3f3'}" flat >

    <!-- column headers -->
    <v-row no-gutters class="mt-1" :style="{backgroundColor: '#f3f3f3'}">
      <v-col cols="auto" class="ml-1 mr-7" :class="{'pb-2': titleRows==1, 'pt-2': titleRows==3}">
        <v-btn :style="{backgroundColor: theme.button}" plain  class="rounded-lg ml-2" height="50px" width="50px" icon @click="selectSort(-1)">
          <v-img :src="require('@/assets/udder_white.png')"></v-img>
        </v-btn>
      </v-col>
      
      <v-col>
        <v-row no-gutters class="pb-2" :class="{'pt-4': titleRows==1,'pt-1': titleRows==2,'pt-n2': titleRows==3,}">
          <v-col v-for="title, index in columnTitles" :key="index + '_' + selectedSortCol"  @click="selectSort(index)" class="truncate ml-n4 mr-4 pointer" :style="{zIndex: 1, color: getTitleSortColor(index)}"  md="2" sm="4" cols="6">
            {{title}}
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <template v-for="milking,index in milkings">
      <MilkingRow :key="milking.endOfMilkingTime" :milking="milking" :background="index % 2 ? '#f3f3f3':'white'"/>
    </template>

  </v-card>
</template>

<script>
import MilkingRow from "@/components/milkings/MilkingRow.vue";

export default {
  name: "MilkingList",

  components: {
    MilkingRow,
  },
  props: {
    animalAndMilkData: Object,
  },
  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      portrait: this.$store.state.isPortraitMode,
      sortDesc: true,
      selectedSortCol: 0,
      columnTitles: [this.$t("Milking time"),
                     this.$t("Duration mm:ss"),
                     this.$t("OCC"),
                     this.$uct("Yield kg (~/24h)", "Yield lb (~/24h)"),
                     this.$uct("Flow kg/m", "Flow lb/m"),
                     this.$t("Milk dest")],
    };
  },
  watch: {
    "$store.state.isPortraitMode": function (currentValue, previousValue) {
      this.portrait = currentValue;
    },
  },
  methods: {
    selectSort(columnIndex) {
      this.sortDesc = this.selectedSortCol == columnIndex ? !this.sortDesc : this.sortDesc;
      this.selectedSortCol = columnIndex;
    },
    getTitleSortColor(columnIndex) {
      if (columnIndex == this.selectedSortCol)
        return  !this.sortDesc ? this.theme.sortDescTitle : this.theme.sortAscTitle;
      return '#000';
    },
  },
  computed: {
    titleRows: {
      get() {
        let size = this.$vuetify.breakpoint.name;
        return size=='sm' ? 2 : size=='xs' ? 3 : 1;
      }
    },
    milkings: {
      get() {
        if (!this.animalAndMilkData)
        {
          console.log("NO COW")
          return [];
        }

        let sortIncompleteDesc = function(m2, m1) {
           if (m1.isIncomplete && m2.isIncomplete) {
             return m1.endOfMilkingTime > m2.endOfMilkingTime ? -1 : m1.endOfMilkingTime < m2.endOfMilkingTime ? 1 : 0;
           } else if (m1.isIncomplete || m2.isIncomplete) {
             return m1.isIncomplete ? 1 : -1;
           }
           return m1.endOfMilkingTime > m2.endOfMilkingTime ? -1 : m1.endOfMilkingTime < m2.endOfMilkingTime ? 1 : 0;
        };
        let sortIncompleteAsc = function(m2, m1) {
           if (m1.isIncomplete && m2.isIncomplete) {
             return m1.endOfMilkingTime > m2.endOfMilkingTime ? 1 : m1.endOfMilkingTime < m2.endOfMilkingTime ? -1 : 0;
           } else if (m1.isIncomplete || m2.isIncomplete) {
             return m1.isIncomplete ? 1 : -1;
           }
           return m1.endOfMilkingTime > m2.endOfMilkingTime ? 1 : m1.endOfMilkingTime < m2.endOfMilkingTime ? -1 : 0;
        };
        let sortNumbers = this.sortDesc ? function(v1, v2) { return v2-v1; } : function (v1, v2) { return v1-v2; };
        let sortText = this.sortDesc ? function(v1, v2) { v1=v1.toLowerCase(); v2=v2.toLowerCase();return v2>v1?-1:v2<v1?1:0; } : function (v1, v2) {v1=v1.toLowerCase(); v2=v2.toLowerCase();return v2<v1?-1:v2>v1?1:0; };

        switch(this.selectedSortCol)
        {
          case -1:
            if (this.sortDesc) {
              return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortIncompleteDesc(o1, o2));
            } else {
              return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortIncompleteAsc(o1, o2));
            }
          case 0:
            return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortNumbers(o1.endOfMilkingTime, o2.endOfMilkingTime));
          case 1:
            return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortNumbers(o1.secMilkingTime, o2.secMilkingTime));
          case 2:
            return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortNumbers(o1.occ, o2.occ));
          case 3:
            return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortNumbers(o1.totalYield, o2.totalYield));
          case 4:
            return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortNumbers(o1.averageFlow, o2.averageFlow));
          case 5:
            return this.animalAndMilkData.milkings.slice().sort((o1,o2) => sortText(o1.milkDestination, o2.milkDestination));
        }
        return this.animalAndMilkData.milkings.slice().reverse();
      }
    },
  },
};
</script>
<style scoped>

</style>