import Names from "@/services/Names.js";
const myfarmMainServer = location.protocol + "//myfarm.delaval.com";
const Axios = require("axios");
Axios.defaults.withCredentials = true;

function login(jwt, server) 
{
  console.log("----- LoginCognito -----");
  const url = server.url + "/Delaval/info/LoginCognito.vcx";  
  return Axios.post(url, JSON.stringify({authorization: jwt, action: "login"}))
    .then((res) => {
      console.log("Login result", res);
      server.isAuthenticated = res.data && res.data.success;
      return server;
    })
    .catch((err) => console.error(err), {success: false, server});
}

function link(jwt) 
{
  console.log("----- link -----");
  const url = myfarmMainServer + "/Delaval/info/LoginCognito.vcx";  
  Axios.post(url, JSON.stringify({authorization: jwt, action: "link"}))
    .then((res) => {
      console.log("Link result", res);
    })
    .catch((err) => console.error(err));
}

function unlink(jwt) 
{
  console.log("----- unlink -----");
  const url = myfarmMainServer + "/Delaval/info/LoginCognito.vcx";  
  Axios.post(url, JSON.stringify({action: "unlink"}))
    .then((res) => {
      console.log("Unlink result", res);
    })
    .catch((err) => console.error(err));
}

function getCookie(cname) 
{
  const name = encodeURIComponent(cname) + "=";
  const ca = document.cookie.split(';');
  for(var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

function cognitoToken() {
  const COGNITO_COOKIE_WITH_USERPOOL = "CognitoIdentityServiceProvider.47vgqqs6navi4bol8l2h6opo4o.";
  const lastAuthUser = "LastAuthUser";
  const idToken = ".idToken";
  console.log("----- Check Cognito Token -----");
  const userName = getCookie(COGNITO_COOKIE_WITH_USERPOOL + lastAuthUser);
  const jwt = getCookie(COGNITO_COOKIE_WITH_USERPOOL + userName + idToken);
  if (jwt) {
    let details = parseJwt(jwt)
    let expires = details.exp*1000;
    let now = new Date().getTime();
    if (expires>now) {
      console.log("Valid Token found", details);
      return jwt;
    }
    console.warn("Expired Cognito Token", details);
  } else {
    console.warn("No Cognito Token")
  }
  return null;
}

export default {
  getCognitoToken() {
    return cognitoToken();
  },
  link(jwt) {
    return link(jwt);
  },
  unlink(jwt) {
    return unlink(jwt);
  },
  login(jwt, server) {
    return login(jwt, server);
  }
}