export default {
  secondOrMillisecondLimit: 100000000000,
  lbPerKg: 2.204622621,
  hexDate: function(hexDate)
  {
    let secondsOrMilliseconds = parseInt(hexDate, 16);
    if (isNaN(secondsOrMilliseconds))
      return hexDate;
    return  secondsOrMilliseconds > this.secondOrMillisecondLimit ? new Date(secondsOrMilliseconds): new Date(secondsOrMilliseconds*1000);
  },
  perfectPrecision: function(value, limit = 2)
  {
    if (isNaN(value))
      return "";
    let size = Math.abs(value).toFixed(0).length;
    let precision = size>limit ? 0 : limit-size+1;
    let result = value.toFixed(precision);
    while (result.endsWith('0') && (result.includes('.') || result.includes(','))) {
      result = result.slice(0,-1);
    }
    return (result.endsWith('.') || result.endsWith(',')) ? result.slice(0,-1) : result;
  },
  trend: function(value1, value2, limit=0.01) //Return 1 if change is increase of >1% and -1 if change is decrease of >1% and returns 0 if change is smaller than 1%
  {
    const upperLimit = 1+limit;
    const lowerLimit = 1-limit;
    const change = value1/value2;

    return change > upperLimit ? 1 : change < lowerLimit ? -1 : 0;
  },
  kg2lb(kg)
  {
    return kg ? kg*this.lbPerKg : kg;
  }
}