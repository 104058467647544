<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row dense>
      <v-col cols="4">
        <v-row dense>
          <p>{{ $t("Old password") }}</p>
        </v-row>
        <v-row dense>
          <p>{{ $t("New password") }}</p>
        </v-row>
        <v-row v-if="showVerifyPwd" dense>
          <p>{{ $t("Verify password") }}</p>
        </v-row>
      </v-col>
      <v-col>
        <v-row dense class="mb-1"><v-text-field v-model="pwdCurrent" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :type="show1 ? 'text' : 'password'" :rules="currentPwdRules" outlined dense hide-details :background-color="theme.settings_bgWhite" @click:append="show1 = !show1" /></v-row>
        <v-row dense class="mb-1"><v-text-field :disabled="!showNewPwd" v-model="pwdNew" :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" :type="show2 ? 'text' : 'password'" :rules="newPwdRules" outlined dense hide-details :background-color="theme.settings_bgWhite" @click:append="show2 = !show2" /></v-row>
        <v-row v-if="showVerifyPwd" dense class="mb-1"><v-text-field v-model="pwdVerify" :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'" :type="show3 ? 'text' : 'password'" :rules="confirmPwdRules" outlined dense hide-details :background-color="theme.settings_bgWhite" @click:append="show3 = !show3" /></v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// TODO: Move outside of settings? Since its also is used at login of new users...
import Common from "@/services/Common.js";

export default {
  name: "Password",

  props: {
    reset: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      theme: this.$vuetify.theme.themes.light,
      showNewPwd: false,
      showVerifyPwd: false,
      pwdCurrent: "",
      pwdNew: "",
      pwdVerify: "",
      show1: false,
      show2: false,
      show3: false,
    };
  },

  mounted() {
    this.$store.commit(this.$mutations.settings.password_Hashed, 0);
  },

  watch: {
    pwdCurrent: "validateForm",
    pwdNew: "validateForm",
    pwdVerify: "validateForm",
    reset: "resetForm",
  },

  methods: {
    validateForm() {
      setTimeout(() => this.$emit("validate"), 0); // Need the timeout to execute showVerifyPwd and validate in correct order
    },

    resetForm() {
      console.log("Password: resetForm");
      setTimeout(() => {
        this.pwdCurrent = "";
        this.pwdNew = "";
        this.pwdVerify = "";
        this.showVerifyPwd = false;
      }, 0);
    },
  },

  computed: {
    currentPwdRules() {
      const rules = [];
      const rule = (pwd) => {
        //console.log("currentPwd pwd=" + pwd);
        let valid = false;
        if (!pwd) {
          valid = true; // Allow empty pwd, then pwd is not changed
        } else {
          if (pwd && pwd.length >= 8) {
            let pwdHashed = Common.getPwdId(this.data.id, pwd);
            let currentPwdHashed = this.$store.state.auth.id; // TODO: Need to keep in local storage (when refreshing page). Should get from cookie...
            if (pwdHashed == currentPwdHashed) valid = true;
          }
          this.showNewPwd = valid; // Or if current pwd is correct, then allow to enter new pwd
        }
        if (!valid) {
          // If not valid make sure to reset new and verify fields
          this.pwdNew = "";
          this.pwdVerify = "";
          this.showNewPwd = false;
          this.showVerifyPwd = false;
        }
        return valid;
      };
      rules.push(rule);
      return rules;
    },

    newPwdRules() {
      const rules = [];
      const rule = (pwd) => {
        //console.log("newPwd pwd=" + pwd);
        let valid = false;
        if (!pwd) {
          valid = true;
        } else {
          if (pwd && pwd.length >= 8) {
            // TODO: Check pwd format (see Angular password.js)...
            this.$emit("pwd", pwd);
            let pwdNewHashed = Common.getPwdId(this.data.id, pwd);
            this.$store.commit(this.$mutations.settings.password_Hashed, parseInt(pwdNewHashed));
            valid = true;
          }
          this.showVerifyPwd = valid;
        }
        if (!valid) {
          this.$store.commit(this.$mutations.settings.password_Hashed, 0); // Make sure to clear non valid id
        }
        return valid;
      };
      rules.push(rule);
      return rules;
    },

    confirmPwdRules() {
      const rules = [];
      const rule = (pwd) => {
        //console.log("confirmPwd pwd=" + pwd);
        let valid = false;
        if (!pwd) {
          valid = false; // Emty confirm is not allowed
        } else {
          valid = pwd === this.pwdNew; // Need to match
        }
        return valid;
      };
      rules.push(rule);
      return rules;
    },

    data: {
      get() {
        return this.$store.state.settings.config.data;
      },
    },
  },
};
</script>

<style scoped>
p {
  margin: 0px;
  padding: 6px 0px 16px 0px;
}
</style>